import produce from 'immer';
import { TEstimatorStore } from './types';
import {
  ArchieStateInitializer,
  StateCreator,
  createArchieStoreCreator,
  createHook,
} from '@awning/archie';
import { BUCKETS } from '@/src/Pagination';

type ImmerSet<T extends TEstimatorStore> = (
  config: ArchieStateInitializer<T>
) => ArchieStateInitializer<T>;
const _set: ImmerSet<TEstimatorStore> = config => (set, get, api) =>
  config(
    //@ts-ignore
    (partial, replace) => {
      const nextState = typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

export const ESTIMATOR_BASE_MODEL_PARAMS = {
  bedrooms: 1,
  bathrooms: 1,
  maxGuests: 4,
  hasPool: null,
  hasHotTub: null,
};

const stateInitializer: StateCreator<TEstimatorStore> = _set(set => ({
  listings: [],
  address: '',
  coordinates: {
    'lat': 0,
    'lng': 0,
  },
  modelParams: ESTIMATOR_BASE_MODEL_PARAMS,
  blackBox: {
    annualRevenue: null,
    averageDailyRate: null,
    occupancy: null,
  },
  range: BUCKETS?.[0],

  updateRange: (start, end) => {
    // @ts-expect-error
    set(state => {
      state.range[0] = start;
      state.range[1] = end;
    });
  },
}));

export const estimatorStoreCreator = createArchieStoreCreator({
  name: 'estimatorStore',
  stateInitializer,
});

export const useEstimatorStore = createHook(estimatorStoreCreator);
