import * as React from 'react';
import { Box, Flex, Input } from '@awning/components';
import { ReactComponent as SearchIcon } from '@awning/components/assets/icons/search.svg';
import { ReactComponent as ChevronLeft } from '@awning/components/assets/icons/chevron-left.svg';

export const SearchBoxMobileView: React.FC<
  React.PropsWithChildren<{
    onUserInput: (event: KeyboardEvent) => void;
    onCancel: Function;
    placeholder: string;
    inputValue: string;
  }>
> = ({ onUserInput, onCancel, placeholder, inputValue, children }) => {
  return (
    <Box
      sx={{
        background: 'white',
        zIndex: 9999,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Flex
        sx={{
          background: 'white',
          alignItems: 'center',
          borderBottom: { base: '1px solid', lg: 'none' },
          borderColor: 'gray.300',
          paddingY: { base: 2, lg: 0 },
          paddingX: { base: 3, lg: 0 },
          width: '100%',
        }}
      >
        <Box
          as="button"
          sx={{
            background: 'white',
            marginRight: 3,
            padding: 0,
            display: { base: 'block', lg: 'none' },
          }}
          onClick={() => {
            onCancel?.();
          }}
        >
          <Box as={ChevronLeft} sx={{ height: '16px', width: '16px' }} />
        </Box>
        <Input
          name="search"
          value={inputValue}
          onChange={onUserInput}
          onFocus={(e: any) => e?.target?.select()}
          type="text"
          placeholder={placeholder}
          sx={{
            borderRadius: 'full',
            p: 0,
            borderColor: 'gray.300',
          }}
          sxInput={{
            margin: 1,
            borderRadius: 'full',
            p: 0.5,
            px: 4,
            fontWeight: 'bold',
          }}
          sxRight={{ px: 0 }}
          right={
            <Flex
              sx={{
                alignItems: 'center',
                p: 0.5,
                borderRadius: 'full',
                overflow: 'hidden',
                // opacity: step > 1 ? 0 : 1,
                transition: 'opacity 500ms',
                marginLeft: 1,
                marginRight: 0.5,
              }}
            >
              <Flex
                sx={{
                  borderRadius: 'full',
                  color: 'gray.900',
                  bg: 'green.500',
                  border: '1px solid',
                  borderColor: 'green.500',
                  px: 1.5,
                  py: 1.5,
                }}
              >
                <Box as={SearchIcon} sx={{ width: '16px', height: '16px' }} />
              </Flex>
            </Flex>
          }
        />
      </Flex>

      {children}
    </Box>
  );
};
