import * as React from 'react';
import { Box, Button, Divider, Flex, Text, theme } from '@awning/components';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';
import { useMediaQuery } from '@/src/useMediaQuery';
import { useOnClickOutside } from '@/src/useOnClickOutside';

export const FilterComponent: React.FC<
  React.PropsWithChildren<{
    heading?: React.ReactNode;
    showUnder: boolean;
    onClear: () => void;
    onSearch: () => void;
    isOpen?: boolean;
    sxProps?: any;
    actionText?: string;
  }>
> = React.memo(
  ({
    actionText = 'See Homes',
    children,
    heading,
    isOpen,
    onClear,
    onSearch,
    showUnder,
    sxProps,
  }) => {
    const isDesktop = useMediaQuery(`(min-width:${theme.breakpoints[2]})`);
    const filterRef = React.useRef();

    // disable if on mobile since there's no click outside
    useOnClickOutside([filterRef], onSearch, [isOpen, isDesktop], !!isOpen && isDesktop);

    if (!showUnder)
      return (
        <Box sx={{ '*::-webkit-scrollbar': { display: 'none' } }} ref={filterRef}>
          {React.Children.map(children, child => {
            // @ts-expect-error
            return React.cloneElement(child, { isOpen });
          })}
        </Box>
      );

    return (
      <Box
        ref={filterRef}
        sx={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          right: 0,
          marginTop: 0,
          width: '100%',
          height: '100%',
          maxWidth: 'auto',
          maxHeight: '100%',
          zIndex: 999999999,
          background: 'white',
          [`@media (min-width: ${theme.breakpoints[2]})`]: {
            position: 'relative',
            marginTop: 4,
            borderRadius: 'xl',
            border: '1px solid',
            borderColor: '#eee',
            boxShadow: '2xl',
            maxHeight: '500px',
            minWidth: '350px',
            maxWidth: '350px',
          },
          ...sxProps,
        }}
      >
        <Flex sx={{ maxHeight: 'inherit', height: '100%', flexDirection: 'column' }}>
          <Box sx={{ display: { base: 'block', lg: 'none' } }}>
            <Box>
              <Flex
                sx={{
                  paddingY: 3,
                  paddingX: 8,
                  text: 'lg',
                  fontWeight: 'bold',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <Box
                  as={CloseIcon}
                  height="12px"
                  width="12px"
                  sx={{
                    position: 'absolute',
                    left: 8,
                    cursor: 'pointer',
                  }}
                  onClick={onSearch}
                />
                <Text>{heading}</Text>
              </Flex>
              <Divider />
            </Box>
          </Box>
          <Box sx={{ display: { base: 'none', lg: 'block' } }}>
            <Flex>
              <Text sx={{ paddingTop: 6, paddingX: 8, text: 'lg', fontWeight: 'bold' }}>
                {heading}
              </Text>
            </Flex>
          </Box>

          <Box sx={{ height: '100%', paddingX: 8, paddingBottom: 6, overflowY: 'auto' }}>
            {children}
          </Box>
          <Box sx={{ alignSelf: 'bottom' }}>
            <Divider />
            <Box sx={{ display: { base: 'block', lg: 'none' } }}>
              <Flex
                sx={{
                  paddingY: 6,
                  paddingX: 8,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  sx={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}
                  onClick={onClear}
                >
                  Clear
                </Text>
                <Box sx={{ marginLeft: 4, whiteSpace: 'nowrap' }}>
                  <Button size="sm" onPress={onSearch} variant="tertiary">
                    {actionText}
                  </Button>
                </Box>
              </Flex>
            </Box>
            <Box sx={{ display: { base: 'none', lg: 'block' } }}>
              <Flex
                sx={{
                  paddingY: 4,
                  paddingX: 8,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}
                  onClick={onClear}
                >
                  Clear
                </Box>
                <Button size="md" variant="tertiary" onPress={onSearch}>
                  {actionText}
                </Button>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  }
);
