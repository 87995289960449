import { toAbbrUSD } from '@/src/utils';
import { theme } from '@awning/components';
import type { TBedroomType, TGraphBreakDown, TTableBreakdown } from './types';

export const FORMAT: Record<
  string,
  {
    sort: number;
    id: string;
    color: string;
  }
> = {
  'studio': { 'sort': 0, 'id': 'Studio', 'color': theme.colors.yellow[600] },
  '1': { 'sort': 1, 'id': '1 bed', 'color': theme.colors.blue[400] },
  '2': { 'sort': 2, 'id': '2 bed', 'color': theme.colors.orange[400] },
  '3': { 'sort': 3, 'id': '3 bed', 'color': theme.colors.green[400] },
  '4': { 'sort': 4, 'id': '4 bed', 'color': theme.colors.pink[400] },
  '5+': { 'sort': 5, 'id': '5+ bed', 'color': theme.colors.gray[500] },
};

export const buildGraphData = (graphBreakdown: TGraphBreakDown) =>
  Object.keys(graphBreakdown)
    .sort((a, b) => FORMAT[a].sort - FORMAT[b].sort)
    .map(beds => {
      return {
        id: FORMAT[beds].id,
        sort: FORMAT[beds].sort,
        color: FORMAT[beds].color,
        // @ts-expect-error
        data: graphBreakdown[beds as any].map(([date, v]) => ({ x: date, y: v })),
      };
    });

export const buildTableData = (
  tableBreakdown: TTableBreakdown,
  col2Format: (v: number) => React.ReactNode | string = (v: number) => toAbbrUSD(v, 0)
) => {
  return Object.keys(tableBreakdown)
    .sort((a, b) => FORMAT[a].sort - FORMAT[b].sort)
    .map(d => ({
      col1: FORMAT[d].id.replace('bed', 'bedroom'),
      col2: col2Format(tableBreakdown[d as TBedroomType] as any),
    }));
};
