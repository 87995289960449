import { Box, Flex, TStyleProps } from '@awning/components';
import { Fragment } from 'react';
import { useUserStore } from '../shared/userStore';
import { useSignupModal } from '../SignupModal';

export const MaskedChart: React.FC<
  React.PropsWithChildren<{
    sx?: TStyleProps['sx'];
    size?: number;
    type: 'vertical-bar' | 'horizontal-bar' | 'line';
  }>
> = ({ sx = {}, type, children }) => {
  const isLoggedIn = useUserStore(state => state.isLoggedIn?.());
  const showSignUpModal = useSignupModal(state => state.show);

  if (isLoggedIn) return <Fragment>{children}</Fragment>;

  return (
    <Box sx={sx}>
      <Flex
        sx={{
          height: '200px',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={`${process.env.NEXT_PUBLIC_BASE_PATH}/placeholder-charts/${type}-chart.png`}
          alt="Empty placeholder chart"
          style={{
            position: 'absolute',
            width: '529px',
            left: 0,
          }}
        />
        <Box sx={{ zIndex: 1 }}>
          <a
            href="/a/signup"
            onClick={(e: any) => {
              e.preventDefault();
              showSignUpModal({ step: 1 });
            }}
          >
            Log in
          </a>{' '}
          to view the data
        </Box>
      </Flex>
    </Box>
  );
};
