import produce from 'immer';
import { TRentEstimatorStore } from './types';
import {
  ArchieStateInitializer,
  StateCreator,
  createArchieStoreCreator,
  createHook,
} from '@awning/archie';

type ImmerSet<T extends TRentEstimatorStore> = (
  config: ArchieStateInitializer<T>
) => ArchieStateInitializer<T>;
const _set: ImmerSet<TRentEstimatorStore> = config => (set, get, api) =>
  config(
    //@ts-ignore
    (partial, replace) => {
      const nextState = typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

export const RENT_ESTIMATOR_BASE_MODEL_PARAMS = {
  bedrooms: 1,
  bathrooms: 1,
  property_type: 'single_family',
};

const stateInitializer: StateCreator<TRentEstimatorStore> = _set(() => ({
  listings: [],
  address: '',
  coordinates: {
    'lat': 0,
    'lng': 0,
  },
  modelParams: RENT_ESTIMATOR_BASE_MODEL_PARAMS,
  estimateRange: [],
  estimate: -1,
}));

export const rentEstimatorStoreCreator = createArchieStoreCreator({
  name: 'rentEstimatorStore',
  stateInitializer,
});

export const useRentEstimatorStore = createHook(rentEstimatorStoreCreator);
