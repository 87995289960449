import { Box } from '@awning/components';
import { MarketInsightsPagination } from '../Pagination/MarketInsightsPagination';
import { CONTAINER_MARGIN_LEFT } from './common';
import { MarketListings } from './MarketListings';

export const TopAirbnbsTab = () => {
  return (
    <Box sx={{ marginLeft: CONTAINER_MARGIN_LEFT }}>
      <MarketListings />
      <MarketInsightsPagination />
    </Box>
  );
};
