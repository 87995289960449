import { AmenitiesFilters } from '../AmenitiesFilters';

// const keys = Object.keys(defaultEstimatorFilters).filter(k => k.startsWith('has')) as TFilterName[];
const labels = {
  hasFreeParking: 'Parking',
  petsAllowed: 'Pets allowed',
  hasWasher: 'Washer',
  hasDryer: 'Dryer',
  hasKitchen: 'Kitchen',
  hasPool: 'Pool',
  hasHotTub: 'Hot Tub',
  hasGym: 'Gym',
  //  hasBackyard: 'Backyard',
  //  hasBbq: 'BBQ',
  //  hasBeachfront: 'Beach Front',
  //  hasBikes: 'Bikes',

  //  hasPingPongTable: 'Ping Pong Table',
  //  hasPoolTable: 'Pool Table',
  //  hasWasherfront: 'Washer front'
};

export const EstimatorAmenitiesFilters = () => {
  return <AmenitiesFilters title="Amenities" labels={labels} />;
};
