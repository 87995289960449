import React from 'react';
import { createSingletonStore } from '@awning/archie';
import { Box } from '@awning/components';
import { TUserListing } from '@/src/typeUserListing';
import { navRef as estimatorNavRef } from '@/src/estimator/Navigation/Navigation';
import { navRef as rentEstimatorNavRef } from '@/src/rent-estimator/Navigation/Navigation';
import { navRef as marketInsightsNavRef } from '@/src/market-insights/Navigation/Navigation';
import { navRef as topMarketsNavRef } from '@/src/top-airbnb-markets/Navigation/Navigation';
import { navRef } from '@/src/Navigation';
import { ListingsMap } from './ListingsMap';
import {
  EstimatorMapListing,
  MapListing,
  MarketInsightsMapListing,
  RentEstimatorMapListing,
  TopMarketsMapListing,
} from './MapListingKlasses';
import { MarkerOverlayCollection } from './MarkerOverlayCollection';
import type { TEstimatorListing } from '@/src/estimator/types';
import { useEstimatorStore } from '@/src/estimator';
import { TMarketInsightsListing, useMarketInsightsStore } from '../market-insights';
import { TTopMarket, useTopMarketsStore } from '../top-airbnb-markets';
import { useUserStore } from '../shared/userStore';
import { TRentEstimatorListing, useRentEstimatorStore } from '../rent-estimator';

export enum EMapViewOptionKeys {
  PRICE = 0,
  CAP_RATE = 1,
  TEN_YEAR_RETURN = 2,
  MONTHLY_REVENUE = 3,
  ANNUAL_REVENUE = 4,
  ADR = 5,
  OCCUPANCY = 6,
}

export enum EMapViewPage {
  ESTIMATOR,
  MARKET_INSIGHTS,
  TOP_MARKETS,
  RENT_ESTIMATOR,
}

export const EMapViewOptions: Record<EMapViewPage, { [key in EMapViewOptionKeys]?: string }> = {
  [EMapViewPage.ESTIMATOR]: {
    [EMapViewOptionKeys.ANNUAL_REVENUE]: 'Annual Revenue',
    [EMapViewOptionKeys.ADR]: 'Average Daily Rate',
    [EMapViewOptionKeys.OCCUPANCY]: 'Occupancy',
  },
  [EMapViewPage.MARKET_INSIGHTS]: {
    [EMapViewOptionKeys.ANNUAL_REVENUE]: 'Annual Revenue',
    [EMapViewOptionKeys.ADR]: 'Average Daily Rate',
    [EMapViewOptionKeys.OCCUPANCY]: 'Occupancy',
  },
  [EMapViewPage.TOP_MARKETS]: {
    [EMapViewOptionKeys.ANNUAL_REVENUE]: 'ROI / yr',
    [EMapViewOptionKeys.ADR]: 'Average Daily Rate',
    [EMapViewOptionKeys.OCCUPANCY]: 'Occupancy',
  },
  [EMapViewPage.RENT_ESTIMATOR]: {},
};

export type TMapViewControls = 'FULLSCREEN' | 'SELECT_VIEW_OPTION';

export const useMapViewStore = createSingletonStore<{
  isFullView: boolean;
  option: undefined | EMapViewOptionKeys;
  setFullView: (isFullView: boolean) => void;
  toggleFullView: () => void;
  setOption: (option: EMapViewOptionKeys) => void;
  selectedMarker: number;
  setSelectedMarker: (i: number) => void;
  markerCollection: MarkerOverlayCollection;
  map: google.maps.Map | undefined;
  setMap: (map: google.maps.Map) => void;
}>((set, get) => ({
  isFullView: false,
  option: undefined,
  setFullView: isFullView => set({ isFullView }),
  toggleFullView: () => set({ isFullView: !get().isFullView }),
  setOption: option => set({ option }),
  selectedMarker: -1,
  setSelectedMarker: selectedMarker => set({ selectedMarker }),
  markerCollection: new MarkerOverlayCollection([]),
  map: undefined,
  setMap: map => set({ map }),
}));

function processListings(
  page: EMapViewPage.RENT_ESTIMATOR,
  listings: TRentEstimatorListing[],
  isLoggedIn: boolean
): MapListing[];
function processListings(
  page: EMapViewPage.ESTIMATOR,
  listings: TEstimatorListing[],
  isLoggedIn: boolean
): MapListing[];
function processListings(
  page: EMapViewPage.MARKET_INSIGHTS,
  listings: TMarketInsightsListing[],
  isLoggedIn: boolean
): MapListing[];
function processListings(
  page: EMapViewPage.TOP_MARKETS,
  listings: TTopMarket[],
  isLoggedIn: boolean
): MapListing[];
function processListings(
  page: EMapViewPage,
  listings:
    | TRentEstimatorListing[]
    | TUserListing[]
    | TMarketInsightsListing[]
    | TEstimatorListing[]
    | TTopMarket[],
  isLoggedIn: boolean
): MapListing[];

function processListings(page: any, listings: any, isLoggedIn: boolean): MapListing[] {
  if (page === EMapViewPage.MARKET_INSIGHTS) {
    return (listings as TMarketInsightsListing[]).map(
      (listing, index) =>
        new MarketInsightsMapListing(
          listing.airbnbAwningId, //
          {
            lat: listing.latitude,
            lng: listing.longitude,
          },
          listing,
          index,
          !isLoggedIn
        )
    );
  } else if (page === EMapViewPage.TOP_MARKETS) {
    return (listings as TTopMarket[]).map((listing, index) => {
      return new TopMarketsMapListing(
        listing.geoId, //
        listing.coordinates,
        listing,
        index,
        !isLoggedIn
      );
    });
  } else if (page === EMapViewPage.RENT_ESTIMATOR) {
    return (listings as TRentEstimatorListing[]).map((listing, index) => {
      return new RentEstimatorMapListing(
        listing.sourceUnitId, //
        {
          lat: listing.latitude,
          lng: listing.longitude,
        },
        listing,
        index,
        !isLoggedIn
      );
    });
  }

  return (listings as TEstimatorListing[]).map(
    (listing, index) =>
      new EstimatorMapListing(
        listing.airbnbAwningId, //
        {
          lat: listing.latitude,
          lng: listing.longitude,
        },
        listing,
        index,
        !isLoggedIn
      )
  );
}

export const MapView: React.FC<
  React.PropsWithChildren<{
    page: EMapViewPage;
    activeControls?: TMapViewControls[];
  }>
> = React.memo(({ page, activeControls, children }) => {
  const [topNavigationPadding, setTopNavigationPadding] = React.useState('163px');
  const estimatorListings = useEstimatorStore(state => state.listings);
  const marketInsightsListings = useMarketInsightsStore(state => state.listings);
  const topMarketListings = useTopMarketsStore(state => state.topMarkets);
  const rentEstimatorListings = useRentEstimatorStore(state => state.listings);
  const isLoggedIn = useUserStore(state => state.isLoggedIn?.());

  //FIXME: just create a wrapped mapview for each listing - OR better just pass the listings now that we have optimized the rendering a bit

  const _listings = React.useMemo(
    () =>
      processListings(
        page,
        page === EMapViewPage.ESTIMATOR
          ? estimatorListings
          : page === EMapViewPage.MARKET_INSIGHTS
          ? marketInsightsListings
          : page === EMapViewPage.TOP_MARKETS
          ? topMarketListings
          : page === EMapViewPage.RENT_ESTIMATOR
          ? rentEstimatorListings
          : [],
        isLoggedIn
      ),
    [page, topMarketListings, marketInsightsListings, estimatorListings]
  );

  React.useEffect(() => {
    if (page === EMapViewPage.ESTIMATOR && estimatorNavRef.current) {
      setTopNavigationPadding(`${estimatorNavRef?.current?.clientHeight}px`);
    } else if (page === EMapViewPage.MARKET_INSIGHTS && marketInsightsNavRef.current) {
      setTopNavigationPadding(`${marketInsightsNavRef?.current?.clientHeight}px`);
    } else if (page === EMapViewPage.TOP_MARKETS && topMarketsNavRef.current) {
      setTopNavigationPadding(`${topMarketsNavRef?.current?.clientHeight}px`);
    } else if (page === EMapViewPage.RENT_ESTIMATOR && rentEstimatorNavRef.current) {
      setTopNavigationPadding(`${rentEstimatorNavRef?.current?.clientHeight}px`);
    } else if (navRef.current) {
      setTopNavigationPadding(`${navRef?.current?.clientHeight}px`);
    }
  }, [page]);

  return (
    <Box
      sx={{
        height: { base: '100%', lg: `calc(100vh - ${topNavigationPadding} - 2px)` },
        maxHeight: { base: '100%', lg: `calc(100vh - ${topNavigationPadding} - 2px)` },
        top: { base: 0, lg: topNavigationPadding },
        position: 'sticky !important',
      }}
    >
      <ListingsMap
        page={page}
        activeControls={activeControls ?? ['FULLSCREEN', 'SELECT_VIEW_OPTION']}
        listings={_listings}
      />
      {children}
    </Box>
  );
});
