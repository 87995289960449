import * as React from 'react';
import { Box, Flex } from '@awning/components';
import { MaskedData } from '@/src/MaskedData/MaskedData';

export const EstimatorMarkerRef = React.createRef<HTMLElement>();

export const EstimatorMarker = () => {
  const fontFamily = `"Benton Sans", "Helvetica Neue", "Arial", sans-serif`;
  return (
    <Box
      ref={EstimatorMarkerRef}
      sx={{
        fontFamily,
        borderStyle: 'none',
        borderWidth: 0,
        position: 'absolute',
        cursor: 'pointer',
        visibility: 'hidden',
        transition: 'transform 100ms',
      }}
    >
      <Flex
        sx={{
          fontFamily,
          borderStyle: 'none',
          borderWidth: 0,
          position: 'absolute',
          cursor: 'pointer',
          height: '22px',
          minWidth: '40px',
          maxWidth: '80px',
          borderRadius: '16px',
          background: '#fff',
          padding: '2px 8px',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          gap: 1,
          fontSize: '12px',
          fontWeight: 'bold',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.6)',
          display: 'none',
        }}
        className="custom-marker"
      >
        <Flex
          className="position-index-tag"
          sx={{
            display: 'none',
            text: 'xs',
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#37764C',
            borderRadius: 'full',
            height: '16px',
            width: '16px',
          }}
        ></Flex>
        <Box className="content">
          <MaskedData />
        </Box>
      </Flex>
    </Box>
  );
};
