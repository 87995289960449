import { useEffect, useState } from 'react';
import { useScript } from '../useScript';

export function useGoogleMapsApi() {
  const [googleApi, setGoogleApi] = useState<typeof google | undefined>(undefined);

  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env
      .NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!}&libraries=places&v=beta`
  );

  useEffect(() => {
    if (status === 'ready' && !googleApi) {
      setGoogleApi((window as any).google);
    }
  }, [status, googleApi]);

  return googleApi;
}
