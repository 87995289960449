import { memo, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Box } from '@awning/components';
import { STATE_NAMES } from 'STATES';

const locationWithLongState = ({ query }: any) => {
  let splitArr = query?.q?.split('--');
  const shortState = splitArr?.[1].toUpperCase();
  const place = splitArr?.[0];

  const longStateName = STATE_NAMES[shortState as keyof typeof STATE_NAMES];
  const location = `${place.trim()}, ${longStateName}`;
  return location;
};

export const Heading = memo(() => {
  const { query } = useRouter();
  const location = useMemo(() => locationWithLongState({ query }), [query]);

  return (
    <Box
      sx={{
        marginTop: 4,
        marginBottom: 1,
        paddingX: { base: 1.5, lg: 4 },
        fontWeight: 'bold',
        fontSize: { lg: 'lg' },
      }}
    >
      {location} Airbnb Market Data
    </Box>
  );
});
