import * as React from 'react';
import { Box, Checkbox, Label } from '@awning/components';
import { useFilterStore } from '../store';
import type { TTopMarketFilterState } from '../types';
import { STATE_NAMES } from '../../../STATES';
import { FilterPillContext } from '../FilterPill';

export const StatesFilter = () => {
  const { states, update } = useFilterStore<TTopMarketFilterState>(state => ({
    states: state.filterState.states.values,
    update: state.updateFilter,
  }));

  const onUpdate = (name: string, checked: boolean) => {
    let currentValues = states ?? [];
    if (checked) {
      update('states', 'values', [...currentValues, name].sort());
    } else {
      update(
        'states',
        'values',
        currentValues.filter((v: string) => v !== name)
      );
    }
  };

  const { setTitle } = React.useContext(FilterPillContext);

  React.useEffect(() => {
    if (setTitle) {
      if (states.length === 0) setTitle('');
      if (states.length >= 4) {
        setTitle(`${states.slice(0, 4).join(', ')}...`);
      } else {
        setTitle(states.join(', '));
      }
    }
  }, [states]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ marginTop: 4, marginBottom: 2 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { base: '1fr', lg: 'repeat(3, 1fr)' },
          gap: 1,
          columnGap: 5,
        }}
      >
        {Object.entries(STATE_NAMES).map(([short, long]) => {
          const checked = states.includes(short);
          return (
            <Box key={short}>
              <Label sx={{ alignItems: 'flex-start', cursor: 'pointer' }}>
                <Box sx={{ marginTop: 1 }}>
                  <Checkbox
                    id={short}
                    name={short}
                    checked={checked}
                    value={short}
                    onChange={() => onUpdate(short, !checked)}
                  />
                </Box>
                <Box>
                  <Box sx={{ whiteSpace: 'nowrap' }}>{long}</Box>
                </Box>
              </Label>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
