import * as React from 'react';
import { SelectRangeFilter } from '../SelectRangeFilter';
import range from 'lodash/range';
import { useEstimatorFilterStore } from '../store';
import { toLocaleUS } from '@/src/utils';

const bedroomRange = range(0, 7, 1).map(value => ({ value }));

export const BedroomFilter = () => {
  const { bedrooms, update } = useEstimatorFilterStore(state => ({
    bedrooms: state.filterState.bedrooms,
    update: state.updateFilter,
  }));

  return (
    <SelectRangeFilter
      name="Bedroom"
      minValues={bedroomRange}
      maxValues={bedroomRange}
      minValue={bedrooms?.min ?? ''}
      maxValue={bedrooms?.max ?? ''}
      labelFn={toLocaleUS as any}
      emptyLabelFn={() => 'Any'}
      onChange={(t, value) => update('bedrooms', `${t}`, value)}
    />
  );
};
