import { memo } from 'react';
import { useRouter } from 'next/router';
import { Tab, Tabs } from '@awning/components';
import { CONTAINER_MARGIN_LEFT } from './common';
import { OccupancyTab } from './OccupancyTab';
import { OverviewTab } from './OverviewTab';
import { RatesTab } from './RatesTab';
import { RevenueTab } from './RevenueTab';
import { useMarketInsightsStore } from './store';
import { TopAirbnbsTab } from './TopAirbnbsTab';

export const TabGroup = memo(() => {
  const { query } = useRouter();
  const marketInsights = useMarketInsightsStore(state => state.marketInsights);

  return (
    <Tabs
      initiallySelectedTab={query?.tab ? parseInt(query?.tab as string, 10) : 0}
      tabMenuItemSx={{
        border: 0,
        color: 'black',
        paddingY: 0.5,
        paddingX: { base: 1.5, lg: 4 },
        text: 'xs',
        borderRadius: 'md',
        flex: 1,
        textAlign: 'center',
        width: '100%',
      }}
      tabMenuItemSelectedSx={{
        backgroundColor: 'black',
        color: 'white',
        boxShadow: 'md',
      }}
      tabMenuSx={{
        backgroundColor: 'gray.200',
        borderRadius: 'md',
        justifyContent: 'space-between',
        marginTop: 2,
        marginRight: { base: 0, lg: 4 },
        paddingX: 2,
        paddingY: 1,
        marginLeft: CONTAINER_MARGIN_LEFT,
        whiteSpace: 'nowrap',
      }}
      tabContainerSx={{
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Tab label="Overview">
        <OverviewTab data={marketInsights.overview} />
      </Tab>
      <Tab label="Top Airbnbs">
        <TopAirbnbsTab />
      </Tab>
      <Tab label="Rates">
        <RatesTab data={marketInsights.rates} />
      </Tab>
      <Tab label="Occupancy">
        <OccupancyTab data={marketInsights.occupancy} />
      </Tab>
      <Tab label="Revenue">
        <RevenueTab data={marketInsights.annualRevenue} />
      </Tab>
    </Tabs>
  );
});
