import { shallow } from '@awning/archie';
import { Box, Text } from '@awning/components';
import { STATE_NAMES } from 'STATES';
import { toUSD } from '@/src/utils';
import { useMarketInsightsStore } from './store';
import { MaskedData } from '../MaskedData/MaskedData';

const getLongStateName = (stateId: string) =>
  ((STATE_NAMES as any)[stateId.toUpperCase()] ?? stateId) as string;

export const RatesTabSummary = () => {
  const { q, rate } = useMarketInsightsStore(
    state => ({
      rate: state.marketInsights.rates,
      q: state.q,
    }),
    shallow
  );
  const location = q.split('--');
  const placeLongName = location?.[0];
  const shortStateName = location?.[1];

  const stateName = getLongStateName(shortStateName);

  // Example: The average airbnb average daily rate in San Francisco, CA is $140 for entire homes, $68 for private rooms, and $54 for shared rooms.
  return (
    <Box sx={{ marginTop: 4, marginBottom: 4 }}>
      <Text sx={{ display: 'block', fontWeight: 'bold', marginTop: 6, marginBottom: 4 }}>
        Rates
      </Text>
      <Box sx={{ text: 'xs', color: 'gray.500' }}>
        <p>
          <span>
            The average airbnb average daily rate in {placeLongName}, {stateName} is{' '}
            {toUSD(rate.entireHome!)} for entire homes
          </span>
          <span>
            {rate.privateRoom && rate.sharedRoom ? (
              <span>
                , <MaskedData>{toUSD(rate.privateRoom)}</MaskedData> for private rooms, and{' '}
                <MaskedData>{toUSD(rate.sharedRoom)}</MaskedData> for shared rooms.
              </span>
            ) : (
              '.'
            )}
          </span>{' '}
          <span>These average daily rate numbers exclude cleaning fees.</span>
        </p>
      </Box>
    </Box>
  );
};
