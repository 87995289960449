import { shallow } from '@awning/archie';
import { Box, Text } from '@awning/components';
import { STATE_NAMES } from 'STATES';
import { MaskedData } from '../MaskedData/MaskedData';
import { toAbbrUSD, toPercentString } from '../utils';
import { useMarketInsightsStore } from './store';

const getLongStateName = (stateId: string) =>
  ((STATE_NAMES as any)[stateId.toUpperCase()] ?? stateId) as string;

export const OverviewTabSummary = () => {
  const { q, overview } = useMarketInsightsStore(
    state => ({
      overview: state.marketInsights.overview,
      q: state.q,
    }),
    shallow
  );
  const location = q.split('--');
  const placeLongName = location?.[0];
  const shortStateName = location?.[1];

  const stateName = getLongStateName(shortStateName);
  const locationType = placeLongName.match(/[a-zA-Z]/) ? 'city' : 'zip code';

  let occupancyText = '';
  if (overview.medianOccupancy) {
    occupancyText =
      overview.medianOccupancy! > 0.6
        ? 'high'
        : overview.medianOccupancy > 0.4 && overview.medianOccupancy < 0.6
        ? 'medium'
        : 'low';
  }

  let adrText = '';
  if (overview.medianAdr) {
    adrText =
      overview.medianAdr! > 250
        ? 'high'
        : overview.medianAdr > 150 && overview.medianAdr < 250
        ? 'medium'
        : 'low';
  }

  return (
    <Box sx={{ marginTop: 4, marginBottom: 4 }}>
      <Text sx={{ display: 'block', fontWeight: 'bold', marginTop: 6, marginBottom: 4 }}>
        Overview
      </Text>
      <Box sx={{ text: 'xs', color: 'gray.500' }}>
        {overview.caption ? (
          <p>{overview.caption}</p>
        ) : (
          <p>
            {placeLongName && locationType && stateName && overview.activeRentals && (
              <span>
                {placeLongName} is a {locationType} in {stateName} with{' '}
                <MaskedData>{overview.activeRentals}</MaskedData> active airbnbs.{' '}
              </span>
            )}

            {occupancyText && adrText && (
              <span>
                The average price of an Airbnb in {placeLongName} is around
                <MaskedData>{toAbbrUSD(overview.medianHomeValuation)}</MaskedData> and the average
                annual revenue of an Airbnb in {placeLongName} is{' '}
                <MaskedData>{toAbbrUSD(overview.medianYearlyRevenue)}</MaskedData>. {placeLongName}{' '}
                has a <MaskedData>{occupancyText}</MaskedData> occupancy rate of{' '}
                <MaskedData>{toPercentString(overview.medianOccupancy, 0)}</MaskedData> and a{' '}
                <MaskedData>{adrText}</MaskedData> average daily rate of{' '}
                <MaskedData>{toAbbrUSD(overview.medianAdr)}</MaskedData>.
              </span>
            )}
          </p>
        )}
      </Box>
    </Box>
  );
};
