import * as React from 'react';
import { Box, Button, Flex, Input, theme } from '@awning/components';
import { ReactComponent as SearchIcon } from '@awning/components/assets/icons/search.svg';
import { ReactComponent as CrossIcon } from '@awning/components/assets/icons/x-circle.svg';
import { useOnClickOutside } from '@/src/useOnClickOutside';
import { Portal } from '@/src/Portal';
import { useMediaQuery } from '@/src/useMediaQuery';
import { SearchBoxMobileView } from './SearchBoxMobileView';
import { SearchBoxContext } from './SearchBoxContext';
import { TItem } from '@/src/SearchBox/types';

export abstract class SearchBoxItemsCollectionClass<T = any, U extends TItem = any> {
  constructor(public items: T) {}
  abstract getItemByIndex(index: number): U;
  abstract getCount(): number;
  abstract itemToString(item: U): string;
}

type TSearchBoxProps<T extends SearchBoxItemsCollectionClass> = React.PropsWithChildren<
  | {
      collection: T;
      onChange: (value: string) => void;
      onSelect: (item: any) => void;
      value?: string;
      placeholder: string;
    } & ({ isMultiStep: true; step: number } | { isMultiStep: false; step?: undefined })
>;

export function SearchBox<T extends SearchBoxItemsCollectionClass>({
  onChange,
  collection,
  isMultiStep = false,
  step = 1,
  onSelect,
  value,
  placeholder,
  children,
}: TSearchBoxProps<T>) {
  const ref = React.useRef<HTMLDivElement>();
  const inputRef = React.useRef<HTMLInputElement>();

  const [input, setInput] = React.useState(value);
  const [isFocused, setFocused] = React.useState(false);

  const [highlightedItemIndex, setHighlightedItemIndex] = React.useState<number>(-1);
  const childrenArray = React.useMemo(() => React.Children.toArray(children), [children]);
  const totalSteps = childrenArray.length;
  const hasNextStep = step && step < totalSteps;

  const itemToString = React.useCallback(
    (item: T) => {
      return collection.itemToString(item);
    },
    [collection]
  );
  const itemsCount = React.useMemo(() => collection.getCount(), [collection]);

  const _onSelect = React.useCallback(
    (index: number) => {
      // solve for having preselected an item when having multiple steps and setting it on load
      let i = index;
      if (isMultiStep && step > 1 && i === -1) i = 0;

      const item = collection.getItemByIndex(i);
      setInput(itemToString(item));

      if (!isMultiStep || !hasNextStep) {
        setFocused(false);
        // set the index to 0
        setHighlightedItemIndex(0);
      }
      onSelect(item);
    },
    [hasNextStep, collection, onSelect] // eslint-disable-line
  );

  const _onChange = React.useCallback(
    (value: string = '') => {
      setInput(value);
      onChange(value);
    },
    [onChange]
  );

  const onClear = React.useCallback(() => {
    _onChange('');
    setHighlightedItemIndex(-1);
    if (inputRef.current) inputRef.current.select();
  }, [inputRef, _onChange]);

  const onKeyPress = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }

      switch (e.key) {
        case 'ArrowDown': {
          setHighlightedItemIndex(
            (highlightedItemIndex + 1) % itemsCount === 0 ? 0 : highlightedItemIndex + 1
          );
          break;
        }
        case 'ArrowUp': {
          setHighlightedItemIndex(
            highlightedItemIndex - 1 === -1 ? itemsCount - 1 : highlightedItemIndex - 1
          );
          break;
        }
        case 'Escape': {
          if (isFocused && inputRef.current) {
            inputRef.current?.blur();
            setFocused(false);
          }
          break;
        }
        case 'Enter': {
          inputRef.current?.blur();
          _onSelect(highlightedItemIndex);
          break;
        }
        default:
          return;
      }
    },
    [isFocused, highlightedItemIndex, itemsCount, _onSelect]
  );

  const renderStep = (step: number) => {
    // @ts-expect-error
    const child = childrenArray.find(c => c.props.step === step);
    return child;
  };

  const isDesktop = useMediaQuery(`(min-width:${theme.breakpoints[2]})`);
  useOnClickOutside(
    [ref],
    () => {
      setFocused(false);
    },
    [isFocused, isDesktop],
    isFocused && isDesktop
  );

  React.useEffect(() => {
    if (!ref.current) return;

    const currentTarget = ref.current;

    currentTarget.addEventListener('keydown', onKeyPress);

    return () => {
      currentTarget.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]); // eslint-disable-line

  React.useEffect(() => {
    // _onChange(value);
    setInput(value);
  }, [value]);

  const borderColor = 'gray.300';
  const borderRadius = 'xl';

  return (
    <SearchBoxContext.Provider
      value={{
        onChange: _onChange,
        onSelect: _onSelect,
        onClear: onClear,
        highlightedItemIndex,
        collection,
        setHighlightedItemIndex,
        placeholder,
      }}
    >
      <Flex
        sx={{
          display: { base: 'flex', lg: 'none' },
          alignItems: 'center',
        }}
      >
        <Button
          onPress={() => setFocused(true)}
          sx={{ border: 'none', borderRadius: 'full', px: 0, py: 0, width: '20px', height: '20px' }}
          variant="secondary"
        >
          <Box as={SearchIcon} />
        </Button>
        <Portal visible={!isDesktop && isFocused}>
          <SearchBoxMobileView
            onUserInput={(e: any) => _onChange(e?.target?.value)}
            onCancel={() => setFocused(false)}
            placeholder={placeholder}
            inputValue={input!}
          >
            {renderStep(step!)}
          </SearchBoxMobileView>
        </Portal>
      </Flex>
      <Box
        ref={ref}
        onFocus={() => {
          setFocused(true);
        }}
        sx={{
          display: { base: 'none', lg: 'block' },
          backgroundColor: 'white',
          [`@media (min-width: ${theme.breakpoints[2]})`]: {
            position: 'relative',
            width: '450px',
            boxShadow: isFocused && itemsCount > 0 ? 'lg' : 'none',
          },
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Input
            onFocus={(e: any) => e?.target?.select()}
            /* @ts-expect-error */
            ref={inputRef}
            name="search"
            sx={{
              paddingY: 1.5,
              paddingLeft: 4,
              paddingRight: 0.5,
              boxShadow: 'none',
              border: '1px solid',
              borderColor,
              borderTopLeftRadius: isFocused && itemsCount > 0 ? borderRadius : '100px',
              borderTopRightRadius: isFocused && itemsCount > 0 ? borderRadius : '100px',
              borderBottomRightRadius: isFocused && itemsCount > 0 ? 0 : '100px',
              borderBottomLeftRadius: isFocused && itemsCount > 0 ? 0 : '100px',
              borderBottomColor: isFocused && itemsCount > 0 ? 'transparent' : borderColor,
              transition: 'border-radius 50ms',
            }}
            placeholder={placeholder}
            right={
              <>
                <Box
                  sx={{
                    borderRadius: 'full',
                    color: 'gray.900',
                    bg: !isFocused ? 'green.500' : 'white',
                    border: '1px solid',
                    borderColor: !isFocused ? 'green.500' : 'white',
                    transition: 'background 100ms',
                    px: 1.5,
                    py: 1.5,
                    position: 'relative',
                  }}
                >
                  <Flex
                    sx={{
                      height: '14px',
                      width: '14px',
                    }}
                  >
                    <Box sx={{ display: !isFocused ? 'flex' : 'none' }} as={SearchIcon} />

                    <Box
                      sx={{
                        cursor: 'pointer',
                        display: input !== '' && isFocused ? 'flex' : 'none',
                        scale: '1.4',
                      }}
                      as={CrossIcon}
                      onClick={onClear}
                    />
                  </Flex>
                </Box>
              </>
            }
            onChange={(e: any) => _onChange(e?.target?.value)}
            value={input}
            type="text"
          />
          <Box
            sx={{
              visibility: isFocused && itemsCount > 0 ? 'visible' : 'hidden',
              boxShadow: isFocused ? 'lg' : 'none',
              position: 'absolute',
              left: 0,
              right: 0,
              backgroundColor: 'white',
              border: '1px solid',
              // @ts-ignore
              borderTopWidth: 0,
              borderColor,
              marginTop: '-2px',
              paddingTop: '10px',
              borderBottomRightRadius: borderRadius,
              borderBottomLeftRadius: borderRadius,
              zIndex: '9999999999999',
              overflowX: 'hidden',
              overflowY: 'auto',
              maxHeight: '500px',
            }}
            onClick={(e: any) => e.stopPropagation()}
          >
            {renderStep(step)}
          </Box>
        </Box>
      </Box>
    </SearchBoxContext.Provider>
  );
}
