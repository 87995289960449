import * as React from 'react';
import { BaseLink } from '@/src/BaseLink';
import { AwningLogo, Box, Container, Flex, SmallAwningLogo, theme } from '@awning/components';
import { SearchBar } from './SearchBar';

export const navRef = React.createRef<HTMLElement>();

export const Navigation: React.FC<
  React.PropsWithChildren<{
    render?: (c: React.FC<React.PropsWithChildren<unknown>>) => JSX.Element | React.Component;
  }>
> = ({ render }) => {
  const containerStyle = {
    paddingX: 4,
    [`@media (min-width: ${theme.breakpoints[2]})`]: {
      width: '100%',
      maxWidth: '100%',
    },
  };

  const style = {
    opacity: 1,
    boxShadow: '0px 1px 1px  #D8D9DB',
    visibility: 'visible',
    height: '100%',
  };

  const renderChildren = () => {
    if (render) {
      const InnerContainer = (props: any) => (
        <Container sx={{ ...containerStyle }}>{props.children}</Container>
      );
      return (
        <Box
          sx={{
            text: 'sm',
            backgroundColor: 'white',
            transition: 'opacity 0.1s ease-in',
            boxShadow: '1px -1px 1px #D8D9DB, 0 1px 1px #D8D9DB',
          }}
        >
          {render(InnerContainer)}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        position: { base: 'relative', md: 'sticky' },
        width: '100%',
        backgroundColor: 'white',
        top: '0px !important',
        transform: 'translateZ(0px) !important',
        zIndex: 9,
      }}
      ref={navRef}
    >
      <Box
        sx={{
          text: 'sm',
          backgroundColor: 'white',
          transition: 'opacity 0.1s ease-in',
          ...style,
        }}
      >
        <Container sx={{ ...containerStyle }}>
          <Flex
            sx={{
              alignItems: 'center',
              backgroundColor: 'white',
              justifyContent: 'space-between',
              paddingY: 2,
              text: 'sm',
            }}
          >
            <Flex
              sx={{
                alignItems: 'center',
                flexBasis: '25%',
              }}
            >
              <BaseLink href="https://awning.com/">
                <Box
                  as={SmallAwningLogo}
                  textColor="black"
                  sx={{ display: { base: 'block', lg: 'none' }, fontSize: 'lg' }}
                />
                <Box
                  as={AwningLogo}
                  sx={{ display: { base: 'none', lg: 'block' }, height: '26px' }}
                />
              </BaseLink>

              <Flex
                sx={{
                  alignItems: 'center',
                  borderLeft: '1px solid',
                  borderColor: 'gray.300',
                  color: 'gray.500',
                  display: { base: 'none', lg: 'block' },
                  marginLeft: 2,
                  paddingLeft: 2,
                  whitespace: 'nowrap',
                }}
              >
                MARKET DATA
              </Flex>
            </Flex>

            <Flex sx={{ marginX: 2, alignItems: 'center' }}>
              <SearchBar />
            </Flex>

            <Flex
              sx={{
                whiteSpace: 'nowrap',
                alignItems: 'center',
                flexBasis: '33%',
                justifyContent: 'flex-end',
                fontSize: { base: 'xs', lg: 'sm' },
              }}
            >
              {/* <BaseLink href="" sx={{ fontWeight: 'normal', marginX: 4, textDecoration: 'none' }}>
                Buy an Airbnb
              </BaseLink> */}
              <BaseLink
                href="https://awning.com/vacation-rental-property-management"
                sx={{ fontWeight: 'normal', marginX: 2, textDecoration: 'none' }}
              >
                Airbnb Management
              </BaseLink>
              <BaseLink
                href="https://awning.com/vacation-rental-furnishing"
                sx={{ fontWeight: 'normal', marginX: 2, textDecoration: 'none' }}
              >
                Furnishing
              </BaseLink>
              <BaseLink
                href="/top-airbnb-markets"
                sx={{ fontWeight: 'normal', marginLeft: 2, textDecoration: 'none' }}
              >
                Deals
              </BaseLink>
            </Flex>
          </Flex>
        </Container>
      </Box>
      {renderChildren()}
    </Box>
  );
};
