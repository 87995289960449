import { createContext } from 'react';
import { SearchBoxItemsCollectionClass } from './SearchBox';

export type TSearchBoxContext<T extends SearchBoxItemsCollectionClass = any> = {
  onClear: Function;
  highlightedItemIndex: number;
  setHighlightedItemIndex: (index: number) => void;
  onChange: (value: string) => void;
  onSelect: (index: number) => void;
  collection: T;
  value?: string;
  placeholder: string;
};

function createGenericContext<T extends SearchBoxItemsCollectionClass>() {
  return createContext<TSearchBoxContext<T>>({} as TSearchBoxContext<T>);
}

export const SearchBoxContext = createGenericContext();

// export const SearchBoxContext = createContext<TSearchBoxContext<T>>({} as TSearchBoxContext<T>);
