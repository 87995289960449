import * as React from 'react';
import { InputNumber } from '@awning/components';
import { InputRangeFilter } from '../InputRangeFilter';
import { useEstimatorFilterStore } from '../store';

export const ReviewsFilter = () => {
  const { numberOfReviews, update } = useEstimatorFilterStore(state => ({
    numberOfReviews: state.filterState.numberOfReviews,
    update: state.updateFilter,
  }));

  return (
    <InputRangeFilter
      name="NumberOfReviews"
      control={InputNumber}
      right=""
      minValue={numberOfReviews.min ?? 0}
      hideLabel={true}
      showMax={false}
      limits={{
        min: 0,
      }}
      decimalScale={0}
      onChange={(t, v) => {
        update('numberOfReviews', `${t}`, v);
      }}
      helperText="From 0 to Unlimited reviews"
    />
  );
};
