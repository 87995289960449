import * as React from 'react';
import { shallow } from '@awning/archie';
import { Box, Flex } from '@awning/components';
import { ReactComponent as HomeIcon } from '@awning/components/assets/icons/home.svg';
import { useMapViewStore } from '@/src/MapView';
import { useEstimatorStore } from '@/src/estimator/store';
import { HomeMarkerOverlay } from '../Overlays/HomeMarkerOverlay';

export const HomeMarker = () => {
  const homeRef = React.useRef();
  const map = useMapViewStore(state => state.map);
  const { address, coordinates } = useEstimatorStore(
    state => ({
      address: state.address,
      coordinates: state.coordinates,
    }),
    shallow
  );

  React.useEffect(() => {
    let marker: any;
    if (homeRef.current && map) {
      const _coordinates = new google.maps.LatLng(coordinates);
      marker = new HomeMarkerOverlay(
        'Home Marker for Estimator',
        _coordinates,
        '',
        homeRef.current,
        null,
        null,
        null,
        null
      );

      marker.show(); // unset visibility hidden
      marker.setMap(map); // toggle visibility on the map
    }

    return () => {
      if (marker && map) {
        marker.setMap(null);
      }
    };
  }, [homeRef, map, address, coordinates]);

  return (
    <Flex
      ref={homeRef}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        position: 'absolute',
      }}
      className="home-marker"
    >
      {/* Need to only have 1 child otherwise it breaks */}
      <Box>
        <Flex
          sx={{
            height: '40px',
            width: '40px',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            background: 'black',
            borderRadius: 'full',
            marginBottom: 1,
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.6)',
          }}
        >
          <Box
            className="heart-icon"
            as={HomeIcon}
            height="24px"
            width="24px"
            sx={{
              display: 'block',
            }}
          />
        </Flex>
        <Box
          sx={{
            textShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.6)',
            text: 'base',
            fontWeight: 'bold',
            position: 'relative',
            left: '-50%',
            display: 'none',
          }}
          className="address"
        >
          {address}
        </Box>
      </Box>
    </Flex>
  );
};
