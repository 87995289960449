import { Flex } from '@awning/components';
import { ReactComponent as LightBulbIcon } from './bulb-icon.svg';
import { useMarketInsightsStore } from './store';

export function NotEnoughDataBanner() {
  const hasEnoughData = useMarketInsightsStore(
    state => state.marketInsights.overview.hasEnoughData
  );

  if (hasEnoughData) return null;

  return (
    <Flex
      sx={{
        marginBottom: 4,
        marginRight: 4,
        alignItems: 'center',
        backgroundColor: 'orange.100',
        borderRadius: 'md',
        text: 'xs',
        paddingY: 2,
        paddingX: 3,
        gap: 2,
      }}
    >
      <Flex as={LightBulbIcon} />
      Expect to see limited or no data due to the small number of airbnbs in this market.
    </Flex>
  );
}
