import { MarkerOverlay } from './Overlays/MarkerOverlay';

/*
 * Helper class to manage all the markers on the map
 */
export class MarkerOverlayCollection {
  public currentlySelected?: MarkerOverlay;
  public currentlyHighlighted?: MarkerOverlay;

  constructor(public overlays: MarkerOverlay[]) {}

  clear() {
    this.deselect();
    this.overlays.forEach(o => o.setMap(null));
    this.overlays = [];
  }

  getOverlayAt(index: number) {
    return this.overlays[index];
  }

  getOverlayById(id: string) {
    return this.overlays.find(o => o.id === id);
  }

  addOverlay(overlay: MarkerOverlay) {
    this.overlays.push(overlay);
  }

  removeOverlay(overlay: MarkerOverlay) {
    this.overlays = this.overlays.filter(o => o !== overlay);
  }

  setCurrentlySelected(overlay?: MarkerOverlay) {
    this.currentlySelected = overlay;
  }

  getCurrentSelected() {
    return this.currentlySelected;
  }

  getCurrentlyHighlighted() {
    return this.currentlyHighlighted;
  }

  select(overlay: MarkerOverlay) {
    const o = this.getCurrentSelected();
    if (o?.div) {
      o.select(false);
      this.setCurrentlySelected(undefined);
    }

    if (overlay.div === o?.div) return;

    overlay.select(true);
    this.setCurrentlySelected(overlay);
  }

  deselect() {
    const o = this.getCurrentSelected();
    if (o?.div) {
      o.select(false);
      this.setCurrentlySelected(undefined);
    }
  }

  highlight(overlay: MarkerOverlay) {
    if (this.currentlyHighlighted !== this.currentlySelected) {
      this.currentlyHighlighted?.highlight(false);
    }
    if (this.currentlyHighlighted === overlay) {
      overlay.highlight(false);
      this.currentlyHighlighted = undefined;
    } else {
      overlay.highlight(true);
      this.currentlyHighlighted = overlay;
    }
  }
}
