import * as React from 'react';

export function useOnClickOutside(
  ignoredElementRefs: React.MutableRefObject<any>[],
  handler: any,
  deps: any[],
  when: boolean
) {
  const listener = React.useCallback(
    (event: any) => {
      // if clicking elements inside or parent dont do anything
      if (
        !handler ||
        ignoredElementRefs.filter(ref => !ref.current || ref.current.contains(event.target))
          .length > 0
      )
        return;

      event?.stopPropagation();
      event?.stopImmediatePropagation();
      handler(event);
    },
    [ignoredElementRefs, handler]
  );

  React.useEffect(() => {
    if (when) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    } else {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    }
  }, [deps, when, listener]); // eslint-disable-line
}
