import * as React from 'react';
import { Field, Flex, Select, SelectItem } from '@awning/components';

export type SelectItemValueProps = {
  value: string | number;
  label?: string;
}[];

export type SelectRangeFilterProps = {
  /* if this is supplied then it will be used when displaying the value in the dropdown unless a `label` key exists @see SelectItemValueProps['label'] */
  labelFn?: SelectFilterProps['labelFn'];
  name: string;
  adjustMaxFn?: (minValue: any, maxValue: any) => string | number;
  onChange: (t: 'min' | 'max', value: any) => void;
  maxValues?: SelectItemValueProps;
  minValues?: SelectItemValueProps;
  maxValue?: SelectItemValueProps[number]['value'] | undefined | null;
  minValue?: SelectItemValueProps[number]['value'] | undefined | null;

  /* Defaults to `No labelName` i.e. If the label is 'Min' then it will show 'No Min'. */
  emptyLabelFn?: (label: string) => string;
};

// const isWithinRange = (v: any, range: any[]) => v >= range[0] && v <= range[range.length - 1];

export type SelectFilterProps = {
  emptyLabel: any;
  /* if this is supplied then it will be used when displaying the value in the dropdown unless a `label` key exists @see SelectFilterProps['value'] */
  labelFn?: (value: string | number) => string | React.ReactNode;
  onChange: (v: any) => void;
  selectedValue: any;
  values: SelectItemValueProps;
};

const SelectFilter = ({
  values,
  emptyLabel,
  selectedValue,
  onChange,
  labelFn = undefined,
}: SelectFilterProps) => {
  const selectItems = React.useMemo(() => {
    return values?.map(({ value, label }) => (
      <SelectItem key={value} value={value}>
        {label ? label : labelFn ? labelFn(value) : value}
      </SelectItem>
    ));
  }, [values, labelFn]);

  return (
    <Select value={selectedValue ?? ''} onChange={(e: any) => onChange(e.target.value)}>
      <SelectItem key="empty" value={''}>
        {emptyLabel}
      </SelectItem>
      {selectItems}
    </Select>
  );
};

const adjustMax = (minValue: any, maxValue: any) => {
  if (!minValue || !maxValue) return maxValue;
  if (maxValue === '') return maxValue;

  const min = typeof minValue === 'string' ? parseFloat(minValue) : minValue;
  const max = typeof maxValue === 'string' ? parseFloat(maxValue) : maxValue;

  return min > max ? min : max;
};

export const SelectRangeFilter = React.memo(
  ({
    minValues,
    maxValues,
    minValue,
    maxValue,
    name,
    onChange,
    labelFn,
    adjustMaxFn = adjustMax,
    emptyLabelFn = (label: string) => `No ${label}`,
  }: SelectRangeFilterProps) => {
    // let selectedMin = isWithinRange(minValue, minValues) ? minValue : minValues[0];
    // let selectedMax = isWithinRange(maxValue, maxValues)
    //   ? maxValue
    //   : maxValues[maxValues.length - 1];

    const showMin = (minValues?.length ?? -1) > 0;
    const showMax = (maxValues?.length ?? -1) > 0;

    const adjustedMaxValue = adjustMaxFn(minValue, maxValue);

    return (
      <Flex
        sx={{
          marginTop: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {showMin && (
          <Field
            as={SelectFilter}
            selectedValue={minValue}
            emptyLabel={emptyLabelFn('Min')}
            label="Min"
            labelFn={labelFn}
            name={`min${name}`}
            sx={{ marginBottom: 0 }}
            labelSx={{ text: 'sm' }}
            values={minValues}
            onChange={(v: any) => onChange('min', v)}
          />
        )}
        {showMin && (
          <Flex sx={{ fontWeight: 'bold', alignSelf: 'flex-end', paddingY: 4, marginX: 4 }}>
            {' - '}
          </Flex>
        )}
        {showMax && (
          <Field
            as={SelectFilter}
            selectedValue={adjustedMaxValue}
            emptyLabel={emptyLabelFn('Max')}
            label="Max"
            labelFn={labelFn}
            name={`max${name}`}
            values={maxValues}
            sx={{ marginBottom: 0 }}
            labelSx={{ text: 'sm' }}
            onChange={(v: any) => onChange('max', v)}
          />
        )}
      </Flex>
    );
  }
);
