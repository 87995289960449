import { Box, Flex } from '@awning/components';
import { useUserStore } from '@/src/shared/userStore';
import { useSignupModal } from '@/src/SignupModal';
import { ReactComponent as LockOpen } from '@awning/components/assets/icons/lock-open.svg';

export const SignupBanner = ({ sx }: any) => {
  const isLoggedIn = useUserStore(state => state.isLoggedIn?.());
  const showSignupModal = useSignupModal(state => state.show);

  if (isLoggedIn) return null;
  return (
    <Flex
      sx={{
        padding: 2,
        gap: 4,
        textDecoration: 'none !important',
        fontWeight: 'normal',
        cursor: 'pointer',
        fontSize: { base: 'sm', lg: 'lg' },
        background: '#052E16',
        color: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'md',
        ...sx,
      }}
      onClick={showSignupModal}
    >
      <div>
        <LockOpen height={24} width={24} stroke="#fff" />
      </div>
      <p>Awning is 100% free. Sign up to unlock all insights & data</p>
      <div>
        <Box
          as="a"
          href="#"
          sx={{
            whiteSpace: 'nowrap',
            color: 'blue.200',
            textDecoration: 'none',
          }}
          onClick={(e: any) => {
            e.preventDefault();
          }}
        >
          Sign up
        </Box>
      </div>
    </Flex>
  );
};
