import * as React from 'react';
import { Box, Flex, Text, Tooltip } from '@awning/components';
import { toAbbrUSD, toPercentString } from '../utils';
import { useEstimatorStore } from './store';
import { GotoMarketInsights, getAddress } from '../market-insights/GotoMarketInsights';
import { useRouter } from 'next/router';
import { MaskedData } from '../MaskedData/MaskedData';

const EstimatedRevenue = React.memo(() => {
  const { asPath, query } = useRouter();
  const blackBox = useEstimatorStore(state => state.blackBox);

  return (
    <Box
      sx={{
        my: 4,
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderColor: 'gray.200',
        paddingBottom: 4,
      }}
    >
      <Flex
        sx={{
          marginTop: 6,
          marginBottom: 2,
          marginRight: 4,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text
          sx={{
            display: 'block',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          Estimated Revenue
        </Text>
        <GotoMarketInsights address={getAddress(query, asPath)} />
      </Flex>
      <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))' }}>
        <Flex
          sx={{
            borderRight: '1px solid',
            borderColor: 'gray.200',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Flex>
            <Text
              sx={{
                display: { base: 'block', lg: 'none' },
                color: 'gray.500',
                marginBottom: 1,
                text: 'xs',
              }}
            >
              Revenue
            </Text>
            <Text
              sx={{
                display: { base: 'none', lg: 'block' },
                color: 'gray.500',
                marginBottom: 1,
                text: 'xs',
              }}
            >
              Annual Revenue
            </Text>
            <Tooltip content="Awning's estimate of the revenue the home will generate per year, based on how much similar properties nearby generate." />
          </Flex>
          <Text sx={{ fontSize: { lg: 'xl' } }}>{toAbbrUSD(blackBox.annualRevenue)}</Text>
        </Flex>
        <Flex
          sx={{
            borderRight: '1px solid',
            borderColor: 'gray.200',
            flexDirection: 'column',
            flexGrow: 1,
            paddingLeft: 4,
          }}
        >
          <Flex>
            <Text
              sx={{
                display: { base: 'block', lg: 'none' },
                color: 'gray.500',
                marginBottom: 1,
                text: 'xs',
              }}
            >
              ADR
            </Text>
            <Text
              sx={{
                display: { base: 'none', lg: 'block' },
                color: 'gray.500',
                marginBottom: 1,
                text: 'xs',
              }}
            >
              Avg. Daily Rate (ADR)
            </Text>
            <Tooltip content="Awning's estimate of the average amount the home can charge a guest per night, based on how much similar properties nearby charge." />
          </Flex>
          <MaskedData size={24}>
            <Text sx={{ fontSize: { lg: 'xl' } }}>{toAbbrUSD(blackBox.averageDailyRate)}</Text>
          </MaskedData>
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            flexGrow: 1,
            paddingLeft: 4,
          }}
        >
          <Flex>
            <Text
              sx={{
                display: { base: 'block', lg: 'none' },
                color: 'gray.500',
                marginBottom: 1,
                text: 'xs',
              }}
            >
              Occup.
            </Text>
            <Text
              sx={{
                display: { base: 'none', lg: 'block' },
                color: 'gray.500',
                marginBottom: 1,
                text: 'xs',
              }}
            >
              Occupancy
            </Text>
            <Tooltip content="Awning's estimate of the % of days during a calendar year that the home will be occupied." />
          </Flex>
          <MaskedData size={24}>
            <Text sx={{ fontSize: { lg: 'xl' } }}>{toPercentString(blackBox.occupancy)}</Text>
          </MaskedData>
        </Flex>
      </Box>
    </Box>
  );
});

export default EstimatedRevenue;
