import * as React from 'react';
import { Box, Button, Flex, Text } from '@awning/components';
import { useModal } from './useModal';
import { ReactComponent as XIcon } from '@awning/components/assets/icons/x.svg';
import { contentPaddingX, contentPaddingY, sectionBorder } from './shared';
import { CalculatorFormTRA } from './CalculatorFormTRA';
import { RevenueHighlights } from './RevenueHighlights';

export const CalculatorForm = ({ control, onReset }: any) => {
  const { Modal, isShow, hide } = useModal();

  return (
    <Modal isShow={isShow}>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          position: 'relative',
        }}
      >
        <Box sx={{ paddingBottom: '150px' }}>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              background: 'white',
              paddingBottom: 4,
              ...sectionBorder,
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                position: 'relative',
                marginTop: 4,
                ...contentPaddingX,
              }}
            >
              <Flex sx={{ alignItems: 'center' }}>
                <Text sx={{ text: 'xl', fontWeight: 'bold' }}>Monthly Cash Flow</Text>
                <Flex
                  sx={{
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    right: 4,
                  }}
                  onClick={hide}
                >
                  <Box as={XIcon} height="14px" width="14px" />
                </Flex>
              </Flex>
            </Flex>
            <RevenueHighlights />
          </Box>

          <Flex
            sx={{
              flexDirection: 'column',
              ...contentPaddingX,
              ...contentPaddingY,
            }}
          >
            <CalculatorFormTRA control={control} />
          </Flex>
        </Box>
        <Flex
          sx={{
            position: 'sticky',
            zIndex: 4,
            left: 0,
            right: 0,
            bottom: 0,
            flexDirection: 'column',
            alignItems: 'center',
            ...contentPaddingX,
            ...contentPaddingY,
            borderTop: '1px solid',
            borderColor: 'gray.200',
            width: '100%',
            background: 'white',
            whiteSpace: 'nowrap',
          }}
        >
          <Button variant="secondary" size="lg" onPress={onReset} sx={{ marginBottom: 3 }}>
            Reset to Default
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
