import { useMemo } from 'react';
import { Box, Divider, Flex, Text } from '@awning/components';
import { PropertyManagement } from '@/src/PropertyManagement';
import { NivoBarChart } from './Charts';
import type { TBarData, TOverview } from './Charts';
import { ReactComponent as HotTubIcon } from './hottub-properties.svg';
import { ReactComponent as PoolPropertiesIcon } from './pool-properties.svg';
import BuyWithAwning from './BuyWithAwning';
import { toAbbrUSD, toLocaleUS, toPercentString } from '@/src/utils';
import { CONTAINER_MARGIN_LEFT } from './common';
import { publicUrl } from '@/src/publicUrl';
import { OverviewTabSummary } from './OverviewTabSummary';
import { MaskedData } from '../MaskedData/MaskedData';
import { MaskedChart } from '../MaskedChart/MaskedChart';

const OverviewLabelValue = ({
  label,
  value,
  tooltip = undefined,
}: {
  label: string;
  value: string | React.ReactElement;
  tooltip?: string;
}) => {
  return (
    <Flex sx={{ justifyContent: 'space-between', text: 'xs' }}>
      <Box sx={{ color: 'gray.400' }}>{label}</Box>
      {label === 'ROI' ? (
        <Box sx={{ textAlign: 'right' }}>{value}</Box>
      ) : (
        <MaskedData sx={{ textAlign: 'right' }}>
          <Box sx={{ textAlign: 'right' }}>{value}</Box>
        </MaskedData>
      )}
      {tooltip && <Box>{tooltip}</Box>}
    </Flex>
  );
};

export const OverviewTab = ({ data }: { data: TOverview }) => {
  const {
    medianAdr,
    activeRentalsByListingType,
    medianYearlyRevenue,
    activeRentals,
    medianOccupancy,
    medianRoi,
    medianHomeValuation,
    listingsByBedroomCount,
    amenities: { poolIncrease, hotTubIncrease },
  } = data;

  const formattedData = useMemo(() => {
    return (
      Object.keys(listingsByBedroomCount).reduce(
        // @ts-expect-error
        (acc, v) => [
          ...acc,
          {
            sort: v === 'studio' ? 0 : Number(v[0]),
            id: v,
            // @ts-expect-error
            value: listingsByBedroomCount[v],
          },
        ],
        []
      ) as unknown as TBarData
    ).sort((a: any, b: any) => {
      return a.sort - b.sort;
    });
  }, [listingsByBedroomCount]);

  const formattedActiveRentalsByListingType = useMemo(() => {
    return [
      { id: 'Entire Home', value: activeRentalsByListingType.entireHome },
      { id: 'Private Room', value: activeRentalsByListingType.privateRoom },
      { id: 'Shared Room', value: activeRentalsByListingType.sharedRoom },
    ];
  }, [activeRentalsByListingType]);

  return (
    <>
      <Box sx={{ marginLeft: CONTAINER_MARGIN_LEFT, marginRight: 4 }}>
        <OverviewTabSummary />
        <Divider sx={{ marginBottom: 4 }} />
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: {
              base: '1fr',
              lg: '1fr 1fr',
            },
            gap: { base: 2, lg: 12 },
            alignItems: 'baseline',
          }}
        >
          <Box sx={{ display: 'grid', gridTemplateRows: '1fr', gap: 1 }}>
            <OverviewLabelValue
              label="ROI"
              value={
                <Text
                  sx={{
                    background:
                      medianRoi && medianRoi > 0
                        ? 'linear-gradient(78.6deg, #4CE689 15.81%, #20954E 85.67%)'
                        : 'linear-gradient(78.6deg, #E6624C 15.81%, #952020 85.67%)',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                  }}
                >
                  {`${medianRoi! > 0 ? '+' : '-'}${toPercentString(medianRoi, 0)}`}
                </Text>
              }
            />
            <OverviewLabelValue label="Revenue" value={toAbbrUSD(medianYearlyRevenue)} />
            <OverviewLabelValue label="Avg Airbnb price" value={toAbbrUSD(medianHomeValuation)} />
          </Box>

          <Box sx={{ display: 'grid', gridTemplateRows: '1fr', gap: 1 }}>
            <OverviewLabelValue
              label="Market size"
              value={
                activeRentals ? (
                  <Box sx={{ textAlign: 'right' }}>
                    {activeRentals < 500
                      ? 'Small'
                      : activeRentals > 500 && activeRentals < 1000
                      ? 'Medium'
                      : 'Large'}
                  </Box>
                ) : (
                  <Box sx={{ textAlign: 'right' }}>'N/A'</Box>
                )
              }
            />

            <OverviewLabelValue label="Number of Airbnbs" value={toLocaleUS(activeRentals)} />
            <OverviewLabelValue label="ADR" value={toAbbrUSD(medianAdr)} />

            <OverviewLabelValue label="Occupancy" value={toPercentString(medianOccupancy, 0)} />
          </Box>
        </Box>
        <Flex sx={{ marginTop: 3, alignItems: 'center', gap: 1, text: 'xs', color: 'gray.400' }}>
          <Box>Data provided by</Box>
          <Box as="a" href="http://airbtics.com/" rel="nofollow" sx={{ textDecoration: 'none' }}>
            <Box
              as="img"
              sx={{ marginTop: -1, height: '16px', width: '56px' }}
              src={publicUrl('airticks.png')}
            />
          </Box>
        </Flex>
      </Box>
      <Divider sx={{ marginTop: 4, height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box sx={{ marginLeft: CONTAINER_MARGIN_LEFT }}>
        <PropertyManagement />
      </Box>
      <Divider sx={{ height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box
        sx={{
          marginLeft: CONTAINER_MARGIN_LEFT,
          marginY: 6,
          marginRight: { base: 0, lg: 4 },
          height: '250px',
          overflowY: 'hidden',
        }}
      >
        <Text sx={{ text: 'sm', fontWeight: 'bold' }}>Listings by Bedroom Count</Text>
        <MaskedChart type="vertical-bar">
          <NivoBarChart layout="vertical" data={formattedData} tickValues={5} />
        </MaskedChart>
      </Box>
      <Divider sx={{ height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box sx={{ marginLeft: CONTAINER_MARGIN_LEFT, my: 5 }}>
        <Text sx={{ text: 'sm', fontWeight: 'bold' }}>Amenities</Text>

        <Flex sx={{ my: 6, alignItems: 'center', gap: 2, whiteSpace: 'nowrap' }}>
          <Box sx={{ width: '32px', height: '32px' }} as={PoolPropertiesIcon} />
          <Flex sx={{ alignItems: 'center', gap: 1, text: 'xs', color: 'gray.500' }}>
            Properties with pools make
            <MaskedData>
              <Box sx={{ text: 'sm', fontWeight: 'bold', color: 'gray.600' }}>
                +{toAbbrUSD(poolIncrease)}
              </Box>
            </MaskedData>
            more per year.
          </Flex>
        </Flex>

        <Flex sx={{ my: 6, alignItems: 'center', gap: 2, whiteSpace: 'nowrap' }}>
          <Box sx={{ width: '32px', height: '32px' }} as={HotTubIcon} />
          <Flex sx={{ alignItems: 'center', gap: 1, text: 'xs', color: 'gray.500' }}>
            Properties with hot tubs make
            <MaskedData>
              <Box sx={{ text: 'sm', fontWeight: 'bold', color: 'gray.600' }}>
                +{toAbbrUSD(hotTubIncrease)}
              </Box>
            </MaskedData>
            more per year.
          </Flex>
        </Flex>
      </Box>
      <Divider sx={{ height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box
        sx={{
          marginLeft: CONTAINER_MARGIN_LEFT,
          marginRight: { base: 0, lg: 4 },
          marginY: 6,
          height: '250px',
          overflow: 'hidden',
        }}
      >
        <Flex
          sx={{
            marginBottom: 2,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text sx={{ text: 'sm', fontWeight: 'bold' }}>
            {activeRentalsByListingType.total} active rentals
          </Text>
          <Text sx={{ text: 'xs', color: 'gray.400' }}>
            {toPercentString(activeRentalsByListingType.entireHomePercentage, 0)} are Entire Home
            Rentals
          </Text>
        </Flex>
        <Text sx={{ text: 'xs' }}>Rental Type</Text>
        <MaskedChart type="horizontal-bar">
          <NivoBarChart
            data={formattedActiveRentalsByListingType as any}
            layout="horizontal"
            tickValues={5}
          />
        </MaskedChart>
      </Box>
      <Divider sx={{ height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box sx={{ marginLeft: CONTAINER_MARGIN_LEFT, paddingBottom: 300 }}>
        <BuyWithAwning />
      </Box>
    </>
  );
};
