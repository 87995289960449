import { FC, PropsWithChildren, memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

// https://stackoverflow.com/a/68500450
export const Portal: FC<PropsWithChildren<any>> = memo(
  ({ visible = false, containerId = 'modal-root', children }) => {
    const [modalContainer, setModalContainer] = useState<any>(null);

    /**
     * Create the modal container element that we'll put the children in.
     * Also make sure the documentElement has the modal root element inserted
     * so that we do not have to manually insert it into our HTML.
     */
    useEffect(() => {
      const modalRoot = document.getElementById(containerId);
      setModalContainer(document.createElement('div'));

      if (!modalRoot) {
        const containerDiv = document.createElement('div');
        containerDiv.id = containerId;
        document.documentElement.appendChild(containerDiv);
      }
    }, [containerId]);

    /**
     * If both the modal root and container elements are present we want to
     * insert the container into the root.
     */
    useEffect(() => {
      const modalRoot = document.getElementById(containerId);

      if (!modalRoot) return;

      if (modalRoot && modalContainer) {
        modalRoot.appendChild(modalContainer);
      }

      /**
       * On cleanup we remove the container from the root element.
       */
      return function cleanup() {
        if (modalContainer && modalRoot) {
          try {
            modalRoot?.removeChild(modalContainer);
          } catch {}
        }
      };
    }, [containerId, modalContainer]);

    /**
     * To prevent the non-visible elements from taking up space on the bottom of
     * the documentElement, we want to use CSS to hide them until we need them.
     */
    useEffect(() => {
      if (modalContainer) {
        modalContainer.style.position = visible ? 'unset' : 'absolute';
        modalContainer.style.height = visible ? 'auto' : '0px';
        modalContainer.style.overflow = visible ? 'auto' : 'hidden';
        modalContainer.style.visibility = visible ? 'visible' : 'hidden';
      }
    }, [modalContainer, visible]);

    /**
     * Make sure the modal container is there before we insert any of the
     * Portal contents into the document.
     */
    if (!modalContainer) {
      return null;
    }

    /**
     * Append the children of the Portal component to the modal container.
     * The modal container already exists in the modal root.
     */
    return createPortal(children, modalContainer);
  }
);
