import * as React from 'react';
import { As, ComponentWithAs, Field, FieldProps, Flex, PropsOf } from '@awning/components';

type Value<T extends As> = null | undefined | PropsOf<T>['value'];
export type InputRangeFilterProps<T extends ComponentWithAs<'input', T> = any> = {
  name: string;
  control?: T;
  limits?: {
    min?: Value<T>;
    max?: Value<T>;
  };
  minValue?: Value<T>;
  maxValue?: Value<T>;
  showMin?: boolean;
  showMax?: boolean;
  /*
   When set it adjusts the max value automatically. If min > max then max is set to min.
   Note: When No max is set already - maxValue is undefined (i.e. set to Any) then it does nothing.

   Example:
   min = 10, max = No Max => No change
   min = 10, max = 5 Max => Max will update to 10.

   Defaults to true.
  */
  shouldAdjustMax?: boolean;
  onChange: (t: 'min' | 'max', value: Value<T>) => void;
} & Omit<PropsOf<T>, 'onChange' | 'value'> &
  Omit<FieldProps, 'label' | 'onChange' | 'value'>;

export const InputRangeFilter = ({
  name,
  control,
  limits,
  minValue,
  maxValue,
  showMin = true,
  showMax = true,
  shouldAdjustMax = true,
  onChange,
  ...props
}: InputRangeFilterProps) => {
  const adjustMax = (minValue: any, maxValue: any) => {
    if (!minValue || !maxValue) return maxValue;
    if (maxValue === '') return maxValue;

    const min = typeof minValue === 'string' ? parseFloat(minValue) : minValue;
    const max = typeof maxValue === 'string' ? parseFloat(maxValue) : maxValue;

    return min > max ? min : max;
  };

  return (
    <Flex
      sx={{
        marginTop: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {showMin && (
        <Field
          as={control}
          label="Min"
          name={`min${name}`}
          sx={{ marginBottom: 0 }}
          labelSx={{ text: 'sm' }}
          onChange={(v: any) => onChange('min', v)}
          onBlur={() => {
            if (maxValue >= minValue) return;
            if (showMax && shouldAdjustMax) onChange('max', adjustMax(minValue, maxValue));
          }}
          value={minValue}
          placeholder="No Min"
          allowEmpty={true}
          {...limits}
          {...props}
        />
      )}
      {showMin && showMax && (
        <Flex sx={{ fontWeight: 'bold', alignSelf: 'flex-end', paddingY: 4, marginX: 4 }}>
          {' - '}
        </Flex>
      )}
      {showMax && (
        <Field
          as={control}
          label="Max"
          name={`max${name}`}
          sx={{ marginBottom: 0 }}
          labelSx={{ text: 'sm' }}
          onChange={(v: any) => onChange('max', v)}
          onBlur={() => {
            if (maxValue >= minValue) return;
            if (shouldAdjustMax) onChange('max', adjustMax(minValue, maxValue));
          }}
          value={maxValue}
          placeholder="No Max"
          allowEmpty={true}
          {...limits}
          {...props}
        />
      )}
    </Flex>
  );
};
