import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { Box, Button, Divider, Flex, Select, SelectItem, Text } from '@awning/components';
import { TEstimatorSearchItem, TNoSearchBarResult } from '@/src/SearchBox/types';
import {
  RENT_ESTIMATOR_BASE_MODEL_PARAMS,
  useRentEstimatorStore,
} from '@/src/rent-estimator/store';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';
import { ReactComponent as MinusIcon } from '@awning/components/assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '@awning/components/assets/icons/plus.svg';
import {
  BaseStep,
  SearchBox,
  SearchBoxContext,
  SearchBoxItemsCollectionClass,
  SearchBoxStep,
  useAutoCompleteAndGeocoder,
} from '@/src/SearchBox';
import useNoResultsModal from '@/src/SearchBox/useNoResultsModal';

const itemToString = <T extends TEstimatorSearchItem>(item: T) => {
  if (item === null || item === undefined) return '';

  if ('isEmptyResult' in item) {
    return (item as TNoSearchBarResult).query;
  }

  return (item as google.maps.places.QueryAutocompletePrediction).description;
};

const baseState = {
  modelParams: RENT_ESTIMATOR_BASE_MODEL_PARAMS,
  step: 1,
  selectedItem: null as unknown as TEstimatorSearchItem,
};

class TEstimatorSearchBoxCollection<
  T extends TEstimatorSearchItem[] = any
> extends SearchBoxItemsCollectionClass {
  constructor(public items: T) {
    super(items);
  }

  getCount() {
    return this.items.length;
  }

  getItemByIndex(index: number) {
    return this.items[index];
  }

  itemToString(item: any): string {
    return itemToString(item);
  }
}

export const SearchBar: React.FC = () => {
  const { query, push } = useRouter();
  const NoResultsModal = useNoResultsModal();
  const { autocomplete, geocoder } = useAutoCompleteAndGeocoder();
  const modelParams = useRentEstimatorStore(state => state.modelParams);

  const [items, setItems] = React.useState<TEstimatorSearchItem[]>([]);
  const collection = React.useMemo(() => new TEstimatorSearchBoxCollection(items), [items]);

  const value = itemToString(
    query?.address
      ? ({
          description: Array.isArray(query.address) ? query.address[0] : query.address,
        } as any)
      : null
  );

  const [state, dispatch] = React.useReducer(
    (state: typeof baseState, action: { type: string; payload: Partial<typeof baseState> }) => {
      switch (action.type) {
        case 'reset':
          return baseState;
        case 'update': {
          if (action.payload.modelParams) {
            return {
              ...state,
              modelParams: {
                ...action.payload.modelParams,
              },
            };
          }
          return {
            ...state,
            ...action.payload,
          };
        }
      }
      return baseState;
    },
    {
      ...baseState,
      modelParams,
    } as typeof baseState
  );

  const onChange = async (inputValue: string, init: boolean = false) => {
    if (!autocomplete) return;

    if (inputValue === '') {
      setItems([]);
      dispatch({ type: 'reset', payload: {} });
      return;
    }

    try {
      const response: google.maps.places.AutocompleteResponse =
        await autocomplete.getPlacePredictions({
          input: inputValue,
          types: ['address'],
          componentRestrictions: { country: 'us' },
        });

      if (!response || isEmpty(response.predictions)) {
        setItems([{ isEmptyResult: true, query: inputValue }]);
        dispatch({ type: 'update', payload: { step: 1 } });
        return;
      }

      setItems(response.predictions);
      const selectedItem = response.predictions?.[0];
      if (selectedItem && init) {
        dispatch({ type: 'update', payload: { step: 2, selectedItem } });
      } else {
        dispatch({ type: 'update', payload: { step: 1, selectedItem: undefined } });
      }
    } catch {
      setItems([]);
    }
  };

  const onStep2Click = (key: string, value: any) => {
    const _v = typeof value === 'number' ? Math.max(1, value) : value;

    dispatch({
      type: 'update',
      payload: {
        modelParams: {
          ...state.modelParams,
          [key]: _v,
        },
      },
    });
  };

  const onSubmit = async (item: TEstimatorSearchItem) => {
    let selectedItem = item || state.selectedItem;
    if (!selectedItem) {
      dispatch({ type: 'update', payload: { step: 1, selectedItem } });
      return;
    }
    if (!geocoder || (selectedItem === null && !query.address)) return;

    // If the SelectedItem does not have Autocomplete results
    if ('isEmptyResult' in selectedItem) {
      NoResultsModal.show();
    } else {
      if (state.step === 1) {
        dispatch({ type: 'update', payload: { selectedItem, step: 2 } });
      } else if (state.step === 2 && selectedItem) {
        const address = selectedItem.description;

        // If a user is searching a new address, get the new lat/lng
        const geocode: google.maps.GeocoderResponse = await geocoder.geocode({
          address,
        });

        const url = new URL('/a/rent-estimator', window.location.href);

        url.searchParams.set('address', address);
        url.searchParams.set(
          'lat',
          geocode.results[0].geometry.location.lat() as unknown as string
        );
        url.searchParams.set(
          'lng',
          geocode.results[0].geometry.location.lng() as unknown as string
        );
        url.searchParams.set('modelParams', JSON.stringify(state.modelParams));
        window.location.href = url.toString();
      }
    }
  };

  React.useEffect(() => {
    onChange(value, true);
  }, [autocomplete, value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <SearchBox<TEstimatorSearchBoxCollection>
        onChange={onChange}
        onSelect={onSubmit}
        collection={collection}
        value={value}
        isMultiStep={true}
        step={state.step}
        placeholder="Type an address"
      >
        <SearchBoxStep step={1}>
          <BaseStep />
        </SearchBoxStep>
        <SearchBoxStep step={2}>
          <Step2 onClick={onStep2Click} state={state} />
        </SearchBoxStep>
      </SearchBox>
      <NoResultsModal.Modal isShow={NoResultsModal.isShow}>
        <Flex
          sx={{
            backgroundColor: 'white',
            borderRadius: 'lg',
            flexDirection: 'column',
            maxWidth: { base: '100%', sm: '850px' },
            padding: 4,
            margin: { base: 4, sm: 0 },
          }}
        >
          <Box
            as={CloseIcon}
            height="18px"
            width="18px"
            sx={{ alignSelf: 'flex-end', cursor: 'pointer' }}
            onClick={NoResultsModal.hide}
          />
          <Text sx={{ marginBottom: { base: 5, sm: 6 } }}>
            Please check the spelling, try clearing the search box, or try reformatting to match
            these examples:
          </Text>
          <Text sx={{ marginBottom: 6 }}>
            <strong>City:</strong> Los Angeles, CA
            <br />
            <strong>Zip:</strong> 75204
            <br />
          </Text>
          <Text sx={{ color: 'gray.500', text: 'sm' }}>
            Note, if a city/zip wasn't found, try checking the spelling or see if it has another
            name. Some cities and zips won't appear because they don't have enough data to provide
            reasonable insights and conclusions.
          </Text>
        </Flex>
      </NoResultsModal.Modal>
    </Box>
  );
};

const Step2: React.FC<{ state: typeof baseState; onClick: Function }> = ({ state, onClick }) => {
  const { highlightedItemIndex, onSelect } = React.useContext(SearchBoxContext);

  return (
    <Flex
      sx={{
        background: 'white',
        flexDirection: 'column',
        minHeight: 'fit-content',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          paddingY: 4,
          text: 'sm',
          fontWeight: 'bold',
          borderBottom: '1px solid',
          borderColor: 'gray.200',
        }}
      >
        Enter home details
      </Flex>
      <Box sx={{ paddingY: 4, paddingX: 6 }}>
        <Flex
          sx={{
            paddingBottom: 4,
            justifyContent: 'space-between',
            text: 'sm',
            aliginItems: 'center',
          }}
        >
          <Box sx={{ lineHeight: 2.5 }}>Bedrooms</Box>
          <Flex sx={{ alignItems: 'center', gap: 4 }}>
            <Flex
              as="button"
              sx={{
                borderRadius: 'full',
                border: '1px solid',
                borderColor: 'gray.300',
                padding: 2,
              }}
              onClick={() => onClick('bedrooms', state.modelParams.bedrooms - 1)}
            >
              <MinusIcon width="12px" />
            </Flex>
            <Box>{state.modelParams.bedrooms}</Box>

            <Flex
              as="button"
              sx={{
                borderRadius: 'full',
                border: '1px solid',
                borderColor: 'gray.300',
                padding: 2,
              }}
              onClick={() => onClick('bedrooms', state.modelParams.bedrooms + 1)}
            >
              <PlusIcon width="12px" />
            </Flex>
          </Flex>
        </Flex>
        <Divider sx={{ backgroundColor: 'gray.200' }} />
        <Flex
          sx={{
            paddingY: 4,
            justifyContent: 'space-between',
            text: 'sm',
            aliginItems: 'center',
          }}
        >
          <Box sx={{ lineHeight: 2.5 }}>Bathrooms</Box>
          <Flex sx={{ alignItems: 'center', gap: 4 }}>
            <Flex
              as="button"
              sx={{
                borderRadius: 'full',
                border: '1px solid',
                borderColor: 'gray.300',
                padding: 2,
              }}
              onClick={() => onClick('bathrooms', state.modelParams.bathrooms - 1)}
            >
              <MinusIcon width="12px" />
            </Flex>
            <Box>{state.modelParams.bathrooms}</Box>

            <Flex
              as="button"
              sx={{
                borderRadius: 'full',
                border: '1px solid',
                borderColor: 'gray.300',
                padding: 2,
              }}
              onClick={() => onClick('bathrooms', state.modelParams.bathrooms + 1)}
            >
              <PlusIcon width="12px" />
            </Flex>
          </Flex>
        </Flex>
        <Divider sx={{ backgroundColor: 'gray.200' }} />
        <Flex
          sx={{
            paddingY: 4,
            justifyContent: 'space-between',
            text: 'sm',
            aliginItems: 'center',
          }}
        >
          <Box sx={{ lineHeight: 2.5 }}>Home type</Box>
          <Box>
            <Select
              sx={{
                border: 0,
                boxShadow: 'md',
                text: {
                  base: 'base',
                  lg: 'sm',
                },
              }}
              sxSelect={{
                backgroundColor: 'white',
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 0,
                width: 'fit-content',
                fontWeight: 'bold',
              }}
              onChange={(e: any) => {
                onClick('property_type', e.target.value);
              }}
              value={state.modelParams.property_type}
            >
              {Object.entries({
                'single_family': 'Single family',
                'apartment': 'Apartment',
                'townhouse': 'Townhouse',
              }).map(([key, value]) => {
                return (
                  <SelectItem key={key} value={key}>
                    {value}
                  </SelectItem>
                );
              })}
            </Select>
          </Box>
        </Flex>

        <Divider sx={{ backgroundColor: 'gray.200' }} />
      </Box>
      <Box sx={{ padding: 6, width: '100%' }}>
        <Button
          onPress={() => {
            onSelect?.(highlightedItemIndex);
          }}
        >
          Estimate
        </Button>
      </Box>
    </Flex>
  );
};
