import * as React from 'react';
import { Box, Flex, InputCurrency, InputPercent } from '@awning/components';
import { EXPLAINERS, ExplanationTooltip } from '@/src/explainers';
import { toUSD } from '@/src/utils';
import { MonthlyPayment } from '@/src/rent-estimator/Calculator/MonthlyPayment';
import { SynchedField } from './SynchedField';
import { SynchedDownPaymentField } from './SynchedDownPaymentField';
import { FieldController } from '@/src/FieldController';
import { CalculatorSection } from './CalculatorSection';
import { useCalculatorStore } from './calculatorStore';
import { useFormState } from 'react-hook-form';
import { publicUrl } from '@/src/publicUrl';

export const CalculatorFormTRA = ({ control }: any) => {
  return (
    <>
      <CalculatorSection title="What’s the purchase price of the home?">
        <SynchedField
          name="common.purchasePrice"
          label="Purchase Price"
          as={InputCurrency}
          tooltip={<ExplanationTooltip {...EXPLAINERS.PURCHASE_PRICE} />}
        />
      </CalculatorSection>
      <RestOfForm control={control} />
    </>
  );
};

const RestOfForm = ({ control }: any) => {
  const [shouldRender, setRender] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(true);
  const formState = useFormState({ control });
  // @ts-ignore
  const hasError = formState.errors?.common?.purchasePrice;

  React.useEffect(() => {
    if (!hasError && shouldRender === false) {
      setRender(true);
    }
  }, [hasError]);

  React.useEffect(() => {
    if (shouldRender && showSpinner) {
      setTimeout(() => {
        setShowSpinner(false);
      }, 1200);
    }
  }, [shouldRender]);

  if (!shouldRender) {
    return null;
  }

  if (shouldRender && showSpinner) {
    return (
      <Flex sx={{ alignItems: 'center', justifyContent: 'center', height: '200px' }}>
        <img src={publicUrl('spinner-eclipse.svg')} style={{ height: '60px' }} />
      </Flex>
    );
  }

  return (
    <React.Fragment>
      <CalculatorSection title="Mortgage" value={<CalculatorMortageHeader />}>
        <SynchedDownPaymentField />
        <FieldController
          control={control}
          name="tra.interestRate"
          label="Interest Rate"
          as={InputPercent}
          tooltip={<ExplanationTooltip {...EXPLAINERS.INTEREST_RATE} />}
          // @ts-ignore
          decimalScale={3}
        />
        <MonthlyPayment />
      </CalculatorSection>
      <CalculatorSection title="Income" value={<CalculatorIncomeHeader />}>
        <SynchedField
          name="tra.yearOneMonthlyRent"
          label="Avg. Monthly Rent"
          as={InputCurrency}
          tooltip={<ExplanationTooltip {...EXPLAINERS.MONTHLY_RENT} />}
        />
        <FieldController
          control={control}
          name="tra.yearOneOtherMonthlyIncome"
          label="Other Monthly Income"
          as={InputCurrency}
          tooltip={<ExplanationTooltip {...EXPLAINERS.OTHER_MONTHLY_INCOME} />}
        />
        <FieldController
          control={control}
          name="tra.vacancyRate"
          label="Vacancy Rate"
          as={InputPercent}
          tooltip={<ExplanationTooltip {...EXPLAINERS.VACANCY_RATE} />}
        />
      </CalculatorSection>
      <CalculatorSection title="Expenses & Taxes" value={<CalculatorExpensesHeader />}>
        <FieldController
          control={control}
          name="common.propertyTaxRate"
          label="Property Tax Rate"
          as={InputPercent}
          right="%/year of sales price"
          tooltip={<ExplanationTooltip {...EXPLAINERS.PROPERTY_TAX_RATE} />}
          // @ts-ignore
          decimalScale={2}
        />
        <FieldController
          control={control}
          name="tra.insurance"
          label="Insurance"
          as={InputCurrency}
          right="$/yr"
          tooltip={<ExplanationTooltip {...EXPLAINERS.INSURANCE} />}
        />
        <FieldController
          control={control}
          name="tra.utilities"
          label="Utilities"
          as={InputCurrency}
          right="$/month"
          tooltip={<ExplanationTooltip {...EXPLAINERS.UTILITIES} />}
        />
        <FieldController
          control={control}
          name="tra.repairsAndMaintenance"
          label="Repairs and Maintenance"
          as={InputCurrency}
          right="$/yr"
          tooltip={<ExplanationTooltip {...EXPLAINERS.REPAIRS_AND_MAINTENANCE} />}
        />
        <FieldController
          control={control}
          name="tra.contractServices"
          label="Contract Services"
          as={InputCurrency}
          right="$/yr"
          tooltip={<ExplanationTooltip {...EXPLAINERS.CONTRACT_SERVICES} />}
        />
        <FieldController
          control={control}
          name="tra.propertyManagementRateFee"
          label="Property Management Fee"
          as={InputPercent}
          right="% of revenue/yr"
          tooltip={<ExplanationTooltip {...EXPLAINERS.PROPERTY_MANAGEMENT_FEE} />}
        />
        <FieldController
          control={control}
          name="common.hoa"
          label="HOA"
          as={InputCurrency}
          right="$/month"
          tooltip={<ExplanationTooltip {...EXPLAINERS.HOA} />}
        />
        <FieldController
          control={control}
          name="tra.otherExpenses"
          label="Other Expenses"
          as={InputCurrency}
          right="$/yr"
          tooltip={<ExplanationTooltip {...EXPLAINERS.OTHER_EXPENSES} />}
        />
      </CalculatorSection>
      <CalculatorSection title="Estimated Growth">
        <FieldController
          control={control}
          name="common.appreciation"
          label="Appreciation"
          as={InputPercent}
          tooltip={<ExplanationTooltip {...EXPLAINERS.APPRECIATION} />}
        />
        <FieldController
          control={control}
          name="tra.rentGrowth"
          label="Rent Growth"
          as={InputPercent}
          tooltip={<ExplanationTooltip {...EXPLAINERS.RENT_GROWTH} />}
        />
        <FieldController
          control={control}
          name="tra.expenseGrowth"
          label="Expense Growth"
          as={InputPercent}
          tooltip={<ExplanationTooltip {...EXPLAINERS.EXPENSE_GROWTH} />}
        />
      </CalculatorSection>
      <CalculatorSection title="Renovation Information">
        <FieldController
          control={control}
          name="common.renovationCost"
          label="Renovation Costs"
          as={InputCurrency}
          tooltip={<ExplanationTooltip {...EXPLAINERS.RENOVATION_COST} />}
        />
        <FieldController
          control={control}
          name="common.renovationValueIncrease"
          label="Renovation Value Increase"
          as={InputCurrency}
          tooltip={<ExplanationTooltip {...EXPLAINERS.RENOVATION_VALUE_INCREASE} />}
        />
      </CalculatorSection>
    </React.Fragment>
  );
};

const CalculatorIncomeHeader = () => {
  const { income = [0] } = useCalculatorStore(state => state.getActiveTabResultValues());

  return <Box>{toUSD(income[0] / 12)}</Box>;
};

const CalculatorMortageHeader = () => {
  const { debt: { debtPrincipal = [0], debtInterest = [0] } = {} } = useCalculatorStore(state =>
    state.getActiveTabResultValues()
  );

  const principal = debtPrincipal[0] / 12;
  const interest = debtInterest[0] / 12;
  return <Box>{toUSD(principal + interest)}</Box>;
};

const CalculatorExpensesHeader = () => {
  const { propertyTaxes = [0], expenses: { totalExpenses = [0] } = {} } = useCalculatorStore(
    state => state.getActiveTabResultValues()
  );

  const monthlyPropertyTaxes = propertyTaxes[0] / 12;
  const monthlyExpenses = totalExpenses[0] / 12;
  return <Box>{toUSD(monthlyExpenses + monthlyPropertyTaxes)}</Box>;
};
