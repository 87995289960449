import { MarketInsightsOverlay } from './Overlays/MarketInsightsOverlay';
import { EstimatorMarkerRef } from './Markers/EstimatorMarker';
import { EMapViewOptionKeys } from './MapView';
import { toAbbrTierUSD, toAbbrUSD, toPercentString } from '@/src/utils';
import type { TEstimatorListing } from '@/src/estimator/types';
import { TTopMarket } from '../top-airbnb-markets';
import { TopMarketMarketOverlay } from './Overlays/TopMarketMarkerOverlay';
import { TopMarketMarkerRef } from './Markers';
import { TRentEstimatorListing } from '../rent-estimator';
import { RentEstimatorMarkerOverlay } from './Overlays/RentEstimatorMarkerOverlay';
import { EstimatorMarkerOverlay } from './Overlays/EstimatorMarkerOverlay';

type Coordinates = {
  lat: number;
  lng: number;
};

type ClassInstanceType =
  | typeof MarketInsightsOverlay
  | typeof EstimatorMarkerOverlay
  | typeof RentEstimatorMarkerOverlay;

export abstract class MapListing {
  constructor(
    public id: string,
    public coordinates: Coordinates,
    public data: any,
    public positionIndex: null | number,
    public isMasked: boolean = false
  ) {}
  abstract getId(): string;
  abstract getCoordinates(): Coordinates;
  abstract getOverlayClass(): ClassInstanceType;
  abstract getMarkerRef(): React.RefObject<HTMLElement>;
  abstract getContent(option: EMapViewOptionKeys): string;
  abstract onClick(): void;

  getPositionIndex() {
    return this.positionIndex;
  }

  getData() {
    return this.data;
  }
}

export class EstimatorMapListing extends MapListing {
  public declare data: TEstimatorListing;

  getId = () => {
    return this.id;
  };

  getCoordinates = () => {
    return this.coordinates;
  };

  getOverlayClass = () => {
    return EstimatorMarkerOverlay;
  };

  getMarkerRef = () => {
    return EstimatorMarkerRef;
  };

  getContent = (selectedOption: EMapViewOptionKeys) => {
    const listing = this.data;
    const options = EMapViewOptionKeys;
    if (
      this.isMasked &&
      this.positionIndex &&
      this?.positionIndex < 3 &&
      selectedOption === options.ANNUAL_REVENUE
    ) {
      return toAbbrUSD(listing.annualBookingRevenueLtm ?? 0);
    }
    if (this.isMasked) return '';

    if (selectedOption === options.ANNUAL_REVENUE)
      return toAbbrUSD(listing.annualBookingRevenueLtm ?? 0);
    else if (selectedOption === options.ADR) return toAbbrUSD(listing.averageDailyRate);
    else if (selectedOption === options.OCCUPANCY) return toPercentString(listing.occupancyRateLtm);

    return '';
  };

  onClick = () => {};
}

export class RentEstimatorMapListing extends MapListing {
  public declare data: TRentEstimatorListing;

  getId = () => {
    return this.id;
  };

  getCoordinates = () => {
    return this.coordinates;
  };

  getOverlayClass = () => {
    return RentEstimatorMarkerOverlay;
  };

  getMarkerRef = () => {
    return EstimatorMarkerRef;
  };

  getContent = () => toAbbrTierUSD(this.data.askingRent, 1, 'k');

  onClick = () => {};
}

export class MarketInsightsMapListing extends EstimatorMapListing {
  getOverlayClass = () => {
    return MarketInsightsOverlay;
  };

  getMarkerRef = () => {
    return EstimatorMarkerRef;
  };
}

export class TopMarketsMapListing extends MapListing {
  public declare data: TTopMarket;

  constructor(
    public id: string,
    public coordinates: Coordinates,
    data: TTopMarket,
    public positionIndex: null | number,
    isMasked: boolean
  ) {
    super(id, coordinates, data, positionIndex, isMasked);
  }

  getId = () => {
    return this.id;
  };

  getCoordinates = () => {
    return this.coordinates;
  };

  getOverlayClass = () => {
    return TopMarketMarketOverlay;
  };

  getMarkerRef = () => {
    return TopMarketMarkerRef;
  };

  getContent = (selectedOption: EMapViewOptionKeys) => {
    const listing = this.data;
    const options = EMapViewOptionKeys;
    if (
      this.isMasked &&
      this.positionIndex &&
      this?.positionIndex < 3 &&
      selectedOption === options.ANNUAL_REVENUE
    ) {
      return toPercentString(listing.medianRoi ?? 0);
    }
    if (this.isMasked) return '';

    if (selectedOption === options.ANNUAL_REVENUE) return toPercentString(listing.medianRoi ?? 0);
    else if (selectedOption === options.ADR) return toAbbrUSD(listing.medianAdr);
    else if (selectedOption === options.OCCUPANCY) return toPercentString(listing.medianOccupancy);

    return '';
  };

  onClick = () => {};
}
