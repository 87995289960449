import * as React from 'react';
import { useRouter } from 'next/router';
import lodashRange from 'lodash/range';
import Link from 'next/link';
import { Box, Flex, theme } from '@awning/components';
import { ReactComponent as ChevronLeft } from '@awning/components/assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '@awning/components/assets/icons/chevron-right.svg';
import { useMediaQuery } from '@/src/useMediaQuery';
import type { TEstimatorStore } from '@/src/estimator/types';
import type { TMarketInsightsStore } from '@/src/market-insights/types';
import type { TTopMarketsStore } from '@/src/top-airbnb-markets/types';

export const LISTINGS_PER_PAGE = 20;
export const BUCKETS: any = [];
for (let i = 0; i < 500; i += LISTINGS_PER_PAGE) {
  BUCKETS.push([i, i + LISTINGS_PER_PAGE]);
}

export const Pagination = React.memo(
  ({
    listings,
    currentRange,
    onUpdateRange,
  }: {
    listings:
      | TEstimatorStore['listings']
      | TMarketInsightsStore['listings']
      | TTopMarketsStore['topMarkets'];
    currentRange: [number, number];
    onUpdateRange: (start: number, end: number) => void;
  }) => {
    const { asPath, query } = useRouter();

    const isTabletPlus = useMediaQuery(`(min-width:${theme.breakpoints[1]})`, {
      isTrueOnMount: true,
    });
    const maxPages = isTabletPlus ? 7 : 3;

    const pageNumber: number =
      BUCKETS.findIndex(
        (n: [number, number]) => n[0] === currentRange[0] && n[1] === currentRange[1]
      ) + 1;

    const getHref = (n: number) => {
      const s = new URLSearchParams(query as Record<string, string>);
      s.set('page', n.toString());
      const pathname = asPath.split('?')?.[0];
      return pathname + '?' + s.toString();
    };

    const onClick = (e: any, n: number, updateURL: boolean = true, scrollTop: boolean = true) => {
      e?.preventDefault();

      onUpdateRange(BUCKETS[n - 1][0], BUCKETS[n - 1][1]);

      const url = new URL(window.location as any);
      url.searchParams.set('page', n.toString());

      try {
        if (updateURL) window.history.pushState({}, '', url);
        if (scrollTop) {
          document.getElementById('__next')?.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      } catch {}
    };

    const totalPages = Math.ceil(listings.length / LISTINGS_PER_PAGE);

    const pageRange = React.useMemo(() => {
      if (totalPages - pageNumber < maxPages && totalPages > maxPages) {
        return lodashRange(totalPages - maxPages + 1, totalPages + 1);
      }
      if (maxPages > totalPages) {
        return lodashRange(1, totalPages + 1);
      }
      return lodashRange(pageNumber, Math.min(pageNumber + maxPages, totalPages + 1));
    }, [maxPages, pageNumber, totalPages]);

    React.useEffect(() => {
      const s = new URLSearchParams(window?.location?.search);
      const page = parseInt(s?.get('page') as string, 10);

      if (page && !isNaN(page)) {
        onClick(null, page);
      } else {
        onClick(null, 1, false, false);
      }
    }, [listings]); // eslint-disable-line

    if (totalPages === 0) return null;

    return (
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          gap: 2,
          marginTop: 4,
          marginBottom: 10,
          paddingBottom: 12,
        }}
      >
        <Box>
          Viewing page {pageNumber} of {totalPages ?? pageNumber}
        </Box>
        <Flex sx={{ gap: 4 }}>
          <Flex sx={{ visibility: pageNumber > 1 ? 'visible' : 'hidden' }}>
            <Link shallow passHref href={getHref(1)}>
              <Flex
                as="a"
                title="Go to the first page"
                sx={{
                  textDecoration: 'none',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
                onClick={(e: any) => onClick(e, 1)}
              >
                <Box as={ChevronLeft} sx={{ width: '20px', height: '20px' }} />
                <Box as={ChevronLeft} sx={{ width: '20px', height: '20px', marginLeft: -4 }} />
              </Flex>
            </Link>
            <Link shallow passHref href={getHref(pageNumber - 1)}>
              <Flex
                as="a"
                title="Go to the previous page"
                sx={{
                  textDecoration: 'none',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
                onClick={(e: any) => onClick(e, pageNumber - 1)}
              >
                <Box as={ChevronLeft} sx={{ width: '20px', height: '20px' }} />
              </Flex>
            </Link>
          </Flex>
          {pageRange.map(page => {
            const isSelected = page === pageNumber;

            return (
              <Link shallow passHref key={page} href={getHref(page)}>
                <Flex
                  as="a"
                  sx={{
                    fontWeight: 'normal',
                    border: '1px solid',
                    borderRadius: 'lg',
                    width: '40px',
                    height: '40px',
                    backgroundColor: isSelected ? 'gray.300' : 'white',
                    color: isSelected ? 'gray.400' : 'inherit',
                    borderColor: isSelected ? 'gray.300' : 'gray.900',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: isSelected ? 'not-allowed' : 'normal',
                    textDecoration: 'none',
                  }}
                  onClick={(e: any) => {
                    if (isSelected) {
                      e.preventDefault();
                      return;
                    } else {
                      onClick(e, page);
                    }
                  }}
                >
                  {page}
                </Flex>
              </Link>
            );
          })}
          <Flex sx={{ visibility: totalPages > pageNumber ? 'visible' : 'hidden' }}>
            <Link shallow passHref href={getHref(pageNumber + 1)}>
              <Flex
                as="a"
                title="Go to the next page"
                sx={{
                  textDecoration: 'none',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
                onClick={(e: any) => onClick(e, pageNumber + 1)}
              >
                <Box as={ChevronRight} sx={{ width: '20px', height: '20px' }} />
              </Flex>
            </Link>
            <Link shallow passHref href={getHref(totalPages)}>
              <Flex
                as="a"
                title="Go to the last page"
                sx={{
                  textDecoration: 'none',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
                onClick={(e: any) => onClick(e, totalPages)}
              >
                <Box as={ChevronRight} sx={{ width: '20px', height: '20px' }} />
                <Box as={ChevronRight} sx={{ width: '20px', height: '20px', marginLeft: -4 }} />
              </Flex>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);
