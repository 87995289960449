import type { MapListing } from '../MapListingKlasses';
import { MarkerOverlayCollection } from '../MarkerOverlayCollection';

export const MARKER_Z_INDEX = {
  IS_SELECTED: '12',
  IS_NUMBERED: '9',
  IS_STAR: '4',
  IS_LIKED: '3',
  IS_FEATURED: '2',
  IS_VANILLA: '1',
};

/**
 * The base marker overlay - USGSOverlay object
 */

export class MarkerOverlay {
  public overlay: google.maps.OverlayView;
  protected _hovered: boolean;
  protected _interact: boolean;
  protected _selected: boolean;

  protected _customMarkerDiv: HTMLElement;
  protected _customMarkerDivWidth: number = 0;

  constructor(
    public id: string,
    public latlng: google.maps.LatLng,
    public content: string,
    public div: HTMLElement,
    public data: any,
    public collection: null | MarkerOverlayCollection,
    public mapListingKlass: null | MapListing,
    public onActive: null | ((on: boolean, id: string, map: google.maps.Map) => void),
    public onClick?: null | (() => void),
    public index?: undefined | number
  ) {
    this.id = id;
    this.latlng = latlng;
    this.content = content;

    this.div = div.cloneNode(true) as HTMLElement;
    this.div.dataset['listingId'] = this.id;

    this.data = data;
    this.collection = collection;
    this.overlay = new google.maps.OverlayView();
    this.overlay.onRemove = this.onRemove.bind(this);
    this.overlay.onAdd = this.onAdd.bind(this);
    this.overlay.draw = this.draw.bind(this);

    this.mapListingKlass = mapListingKlass;

    this.index = index;

    this._hovered = false;
    this._selected = false;
    this._interact = false;

    this._customMarkerDiv = this.div.getElementsByClassName('custom-marker')?.[0] as HTMLElement;

    if (this._customMarkerDiv) this._customMarkerDivWidth = this._customMarkerDiv?.offsetWidth ?? 0;
    if (content) this.setContent(content);

    if (onActive) this.onActive = onActive.bind(this);
    if (onClick) this.onClick = onClick.bind(this);
    if (collection) collection.addOverlay(this);
  }

  setHighlightStyle(div: HTMLElement, isSelected: boolean) {
    if (!div) return;

    const innerDiv = div.querySelectorAll('.custom-marker')[0] as HTMLElement;
    const featuredIcon = innerDiv.querySelectorAll('.featured-icon > svg')[0] as HTMLElement;

    if (isSelected) {
      div.style.transform = 'scale(1.2)';
      div.style.zIndex = MARKER_Z_INDEX.IS_SELECTED;
      innerDiv.style.background = '#000';
      innerDiv.style.color = '#fff';
      innerDiv.style.zIndex = MARKER_Z_INDEX.IS_SELECTED;
      if (featuredIcon) featuredIcon.style.fill = 'white';
    } else {
      div.style.transform = 'scale(1)';
      div.style.zIndex = MARKER_Z_INDEX.IS_VANILLA;
      innerDiv.style.background = '#fff';
      innerDiv.style.boxShadow = '0 2px 14px -1px rgba(0, 0, 0, 0.9) !important';
      innerDiv.style.color = '#000';
      innerDiv.style.zIndex = MARKER_Z_INDEX.IS_VANILLA;
      if (featuredIcon) featuredIcon.style.fill = 'black';
    }
  }

  highlight(on: boolean) {
    this._hovered = on;
    this.setHighlightStyle(this.div, on);
  }

  select(on: boolean) {
    this._selected = on;
    this.highlight(on);
    if (this.onActive) {
      this.onActive(on, this.id, this.overlay.getMap() as google.maps.Map);
    }
  }

  _onHover(e?: any) {
    e?.stopPropagation();
    if (this._selected) return;
    this.collection?.select(this);
  }

  _onClick(e?: any) {
    e?.stopPropagation();
    if (!this._selected) return;
    this.onClick?.();
  }

  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  onAdd() {
    const panes = this.overlay.getPanes()!;
    const isTouch = 'ontouchstart' in window;

    if (isTouch) {
      this.div.addEventListener('touchstart', () => (this._interact = true));
      this.div.addEventListener('touchmove', () => (this._interact = false));
      this.div.addEventListener('touchend', () => {
        if (!this._interact) return;
        this._onHover();
      });
    } else {
      // only apply for desktop
      this.div.addEventListener('mousedown', () => (this._interact = true));
      this.div.addEventListener('mousemove', () => (this._interact = false));
      this.div.addEventListener('mouseup', () => {
        if (!this._interact) return;
        this._onClick?.();
      });
      this.div.addEventListener('mouseenter', this._onHover.bind(this));
      this.div.addEventListener('mouseleave', this._onHover.bind(this));
    }

    // https://stackoverflow.com/questions/3361823/make-custom-overlay-clickable-google-maps-api-v3
    // Update for v3: overlayLayer doesn't accept mouse events anymore.
    //                -- this.getPanes().overlayLayer.appendChild(div);
    // Add your overlay to overlayMouseTarget instead and it should receive mouse events normally.
    panes.overlayMouseTarget.appendChild(this.div!);
  }

  getPosition() {
    return this.latlng;
  }

  draw() {
    if (!this.overlay) return;

    const overlayProjection = this.overlay.getProjection();
    const point = overlayProjection.fromLatLngToDivPixel(this.latlng);

    if (this.div && point) {
      // overlay positioning
      this.div.style.left = point.x - this._customMarkerDivWidth / 2 + 'px';
      this.div.style.top = point.y + 'px';
    }
  }

  /**
   * The onRemove() method will be called automatically from the API if
   * we ever set the overlay's map property to 'null'.
   */
  onRemove() {
    if (this.div) {
      this.div.removeEventListener('touchstart', this._onHover.bind(this));
      this.div.removeEventListener('mousedown', this._onClick.bind(this));
      this.div.removeEventListener('mouseenter', this._onHover.bind(this));
      this.div.removeEventListener('mouseleave', this._onHover.bind(this));

      try {
        (this.div.parentNode as HTMLElement)?.removeChild(this.div);
        // @ts-expect-error
        this.div = null;
        // @ts-expect-error
        delete this.div;
      } catch {}
    }
  }

  /**
   *  Set the visibility to 'hidden' or 'visible'.
   */
  hide() {
    if (this.div) {
      this.div.style.visibility = 'hidden';
    }
  }

  show() {
    if (this.div) {
      this.div.style.visibility = 'visible';
    }
  }

  setContent(text: string) {
    if (!text) return;

    this.content = text;

    const textElem = this.div.getElementsByClassName('content')[0];
    if (textElem) {
      textElem.innerHTML = this.content;
    }
  }

  setMap(map: google.maps.Map | null) {
    this.overlay.setMap(map);
  }

  getMap() {
    return this.overlay.getMap();
  }

  // toggleVisibility() {
  //   if (this.div) {
  //     if (this.div.style.visibility === 'hidden') {
  //       this.show();
  //     } else {
  //       this.hide();
  //     }
  //   }
  // }

  // toggleDOM(map: google.maps.Map) {
  //   if (this.overlay.getMap()) {
  //     this.overlay.setMap(null);
  //   } else {
  //     this.overlay.setMap(map);
  //   }
  // }
}
