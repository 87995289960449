import * as React from 'react';
import { Box, Flex, Text, toLocaleUS, toUSD } from '@awning/components';
import { useMapViewStore } from '@/src/MapView';
import { shallow } from '@awning/archie';
import { ReactShimmerSVGComponent } from '@/src/generateShimmerSVG';
import { TEstimatorListing } from '@/src/estimator';
import { ListingEstimatorCardMarkerOverlay } from '@/src/MapView/Overlays/ListingEstimatorCardMarkerOverlay';
import { pluralize, toPercentString } from '@/src/utils';
import { useComparableModal } from '@/src/estimator/EstimatorListings/ComparableModal';
import { SignUpToViewCardOverlayMarker } from './SignUpToViewCardOverlayMarker';

export const ListingEstimatorCardOverlayMarker = () => {
  const listingCardElemRef = React.useRef();
  const googleListingCardOverlay = React.useRef<ListingEstimatorCardMarkerOverlay>();
  const showDetails = useComparableModal(state => state.show);

  const { map, selectedMarker, markerCollection } = useMapViewStore(
    state => ({
      map: state.map,
      selectedMarker: state.selectedMarker,
      markerCollection: state.markerCollection,
    }),
    shallow
  );

  const fontFamily = `"Benton Sans", "Helvetica Neue", "Arial", sans-serif`;

  React.useEffect(() => {
    if (googleListingCardOverlay.current) {
      // reset so the carousel empties out
      googleListingCardOverlay.current.overlay.setMap(null);
      googleListingCardOverlay.current = undefined;
    }

    if (selectedMarker === -1 || !map || markerCollection.overlays.length === 0) return;

    const overlay = markerCollection.getOverlayAt(selectedMarker);
    const coordinates = overlay?.getPosition()!;

    const listingCard = new ListingEstimatorCardMarkerOverlay(
      coordinates,
      listingCardElemRef.current!
    );
    listingCard.overlay.setMap(map);
    googleListingCardOverlay.current = listingCard;
  }, [selectedMarker]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      ref={listingCardElemRef}
      className="map-listing-card"
      sx={{
        fontFamily,
        maxHeight: '120px',
        width: '280px',
        minWidth: '280px',
        maxWidth: '280px',
        background: 'white',
        overflow: 'hidden',
        boxShadow: 'lg',
        borderRadius: 'md',
      }}
    >
      {selectedMarker !== -1 && (
        <SignUpToViewCardOverlayMarker>
          <SmallListingCard
            onClick={showDetails}
            listing={markerCollection.getOverlayAt(selectedMarker).data!}
          />
        </SignUpToViewCardOverlayMarker>
      )}
    </Box>
  );
};

const SmallListingCard = ({ onClick, listing }: { onClick: any; listing: TEstimatorListing }) => {
  const {
    airbnbAwningId,
    annualBookingRevenueLtm,
    averageDailyRate,
    bathrooms,
    bedrooms,
    listingTitle,
    maxGuests,
    occupancyRateLtm,
    pictureUrl,
  } = listing;

  return (
    <Flex
      sx={{
        padding: 2,
        gap: 2,
        textDecoration: 'none !important',
        fontWeight: 'normal',
        cursor: 'pointer',
        text: 'xs',
      }}
      onClick={onClick}
    >
      <Box>
        <LoadImage imageUrl={pictureUrl} awningId={airbnbAwningId} />
      </Box>
      <Flex sx={{ gap: 1, flexDirection: 'column', overflow: 'hidden', text: 'xs' }}>
        <Box>
          <Text sx={{ fontWeight: 'bold' }}>{toUSD(annualBookingRevenueLtm)}</Text>
          <Text sx={{ color: 'gray.400' }}>/yr</Text>
        </Box>
        <Flex sx={{ gap: 1 }}>
          <Text>{SFR(bedrooms, bathrooms)}</Text>
          <Text sx={{ color: 'gray.400' }}>&#x2022;</Text>
          <Text>
            {maxGuests} {pluralize(maxGuests, 'Guest')}
          </Text>
        </Flex>
        <Box
          sx={{
            text: 'xs',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {listingTitle}
        </Box>
        <Flex
          sx={{ marginTop: 2, alignItems: 'center', justifyContent: 'space-between', text: 'xs' }}
        >
          <Flex sx={{ gap: 1 }}>
            <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Text>{toUSD(averageDailyRate)}</Text>
            </Flex>
            <Flex sx={{ alignItems: 'center' }}>ADR</Flex>
          </Flex>
          <Flex sx={{ gap: 1 }}>
            <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Text>{toPercentString(occupancyRateLtm, 0)}</Text>
            </Flex>
            <Flex sx={{ alignItems: 'center' }}>Occup.</Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const LoadImage = ({ imageUrl, awningId }: any) => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    new Promise(resolve => {
      setIsLoading(true);
      setTimeout(resolve, 500);
    }).then(() => {
      setIsLoading(false);
    });
  }, [imageUrl]);

  return (
    <Box
      sx={{
        width: '100px',
        height: '100px',
        overflow: 'hidden',
        borderRadius: 'md',
        objectFit: 'cover',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          visibility: isLoading ? 'visible' : 'hidden',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <ReactShimmerSVGComponent width={100} height={100} />
      </Box>
      {!isLoading && (
        <Box
          as="img"
          sx={{
            width: '100px',
            height: '100px',
            objectFit: 'cover',
          }}
          src={
            imageUrl?.includes('listing-images.homejunction') ? `${imageUrl}?width=200` : imageUrl
          }
          alt={awningId}
        />
      )}
    </Box>
  );
};

const SFR = (bedroomCount: number, bathroomCount: number) => {
  return (
    <>
      <Text sx={{ marginRight: 1 }}>{`${toLocaleUS(bedroomCount)} Br`}</Text>
      <Text sx={{ color: 'gray.400', marginRight: 1 }}>&#x2022;</Text>
      <Text>{`${toLocaleUS(bathroomCount)} Ba`}</Text>
    </>
  );
};
