import {
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Box, Flex } from '@awning/components';
import { useFilterStore } from './store';
import type { TFilterStore } from './types';
import { useHover } from '@/src/useHover';
import { FilterComponent } from './FilterComponent';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';
import { Portal } from '@/src/Portal';

// Pass a setTitle method
export const FilterPillContext = createContext<{ setTitle: (v: string) => void }>({} as any);

export const FilterPill: FC<
  PropsWithChildren<{
    filter: TFilterStore['filterShown'];
    text: ReactNode;
    actionText?: string;
    onClick?: Function;
    showUnder?: boolean;
    sxComponentProps?: any;
  }>
> = memo(
  ({
    actionText,
    children,
    filter,
    onClick = null,
    showUnder = true,
    sxComponentProps = {},
    text,
  }) => {
    const [title, setTitle] = useState(text);
    const { inactiveFilters, isOpen, show, hide, executeSearch, clear, hasChanged } =
      useFilterStore<any>(state => ({
        inactiveFilters: state.defaults().INACTIVE_FILTERS,
        isOpen: state.filterShown === filter,
        show: () => state.showFilter(filter),
        hide: () => state.hideFilter(filter),
        executeSearch: state.executeSearch,
        clear: () => state.clearFilter(filter),
        hasChanged: state.hasFilterChanged(filter),
      }));

    const [hoverRef, isHovered] = useHover();

    const onSearch = useCallback(() => {
      executeSearch();
      hide();
    }, [hide, executeSearch]);

    if (inactiveFilters?.includes(filter as any)) return null;

    return (
      <FilterPillContext.Provider
        value={{
          setTitle: (v: string) => setTitle(!v ? text : v),
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <FilterComponentPortal
            isOpen={isOpen}
            onClick={() => {
              if (!isOpen) show();
              onClick?.();
            }}
            component={
              <FilterComponent
                heading={text}
                onClear={clear}
                onSearch={onSearch}
                showUnder={showUnder}
                isOpen={isOpen}
                sxProps={sxComponentProps}
                actionText={actionText}
              >
                {children}
              </FilterComponent>
            }
          >
            <Flex
              ref={hoverRef}
              sx={{
                gap: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '1.5rem',
                boxShadow: hasChanged || isOpen || isHovered ? '0 0 0 1px black;' : 'none',
                border: '1px solid',
                borderColor: 'gray.400',
                paddingY: 1,
                paddingX: 4,
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                fontSize: { base: 'xs', md: 'sm' },
                fontWeight: hasChanged ? 'bold' : 'normal',
                color: 'gray.600',
              }}
            >
              {title}
              {hasChanged && (
                <Box as={CloseIcon} sx={{ height: '10px', width: '10px', marginTop: '-1px' }} />
              )}
            </Flex>
          </FilterComponentPortal>
        </Box>
      </FilterPillContext.Provider>
    );
  }
);

// https://codefrontend.com/react-portals/
function FilterComponentPortal({
  component,
  isOpen,
  onClick,
  children,
}: {
  component: ReactNode;
  isOpen: boolean;
  onClick: Function;
  children: ReactNode;
}) {
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const handleMouseDown = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    const bounds = e.currentTarget.getBoundingClientRect();
    setPosition({
      x: bounds.x,
      y: bounds.y + bounds.height,
    });

    onClick?.();
  };

  const anchor = useMemo(() => <div onMouseDown={handleMouseDown}>{children}</div>, [children]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {anchor}
      <Portal containerId="filter-pills" visible={isOpen}>
        <Box
          sx={{
            top: position.y,
            left: position.x,
            position: 'fixed',
            zIndex: 99,
          }}
        >
          {component}
        </Box>
      </Portal>
    </>
  );
}
