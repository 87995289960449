import produce from 'immer';
import { TMarketInsightsStore } from './types';
import {
  ArchieStateInitializer,
  StateCreator,
  createArchieStoreCreator,
  createHook,
} from '@awning/archie';
import { BUCKETS } from '@/src/Pagination';
import { TMarketInsights } from './Charts';

type ImmerSet<T extends TMarketInsightsStore> = (
  config: ArchieStateInitializer<T>
) => ArchieStateInitializer<T>;
const _set: ImmerSet<TMarketInsightsStore> = config => (set, get, api) =>
  config(
    //@ts-ignore
    (partial, replace) => {
      const nextState = typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const stateInitializer: StateCreator<TMarketInsightsStore> = _set(set => ({
  q: '',
  marketInsights: {} as TMarketInsights,
  listings: [],
  range: BUCKETS?.[0],

  updateRange: (start, end) => {
    // @ts-expect-error
    set(state => {
      state.range[0] = start;
      state.range[1] = end;
    });
  },
}));

export const marketInsightsStoreCreator = createArchieStoreCreator({
  name: 'marketInsightsStore',
  stateInitializer,
});

export const useMarketInsightsStore = createHook(marketInsightsStoreCreator);
