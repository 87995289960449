import * as React from 'react';
import { Box, Text } from '@awning/components';
import { useEstimatorStore } from './store';
import { pluralize } from '../utils';

const Heading = React.memo(() => {
  const {
    address,
    modelParams: { maxGuests, bathrooms, bedrooms, hasPool, hasHotTub },
  } = useEstimatorStore(state => ({
    address: state.address,
    modelParams: state.modelParams,
  }));

  return (
    <Box sx={{ marginTop: 5 }}>
      <Text sx={{ text: 'xs', color: 'gray.400' }}>Airbnb estimate for</Text>
      <Text
        sx={{
          display: 'block',
          fontWeight: 'bold',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          color: 'gray.600',
          marginTop: 2,
        }}
      >
        {address}
      </Text>
      <Box sx={{ text: 'sm', color: 'gray.600', marginTop: 1 }}>
        <Text>
          {bedrooms} {pluralize(bedrooms, 'Bedroom')}
        </Text>
        <Text sx={{ color: 'gray.400', paddingX: 1 }}>•</Text>
        <Text>
          {bathrooms} {pluralize(bathrooms, 'Bath')}
        </Text>
        <Text sx={{ color: 'gray.400', paddingX: 1 }}>•</Text>
        <Text>
          {maxGuests} {pluralize(maxGuests, 'Guest')}
        </Text>
        {(hasPool || hasHotTub) && <Text sx={{ color: 'gray.200', paddingX: 2 }}>|</Text>}
        {hasPool && (
          <>
            <Text>Pool</Text>
          </>
        )}
        {hasPool && hasHotTub && <Text sx={{ color: 'gray.400', paddingX: 1 }}>•</Text>}
        {hasHotTub && <Text>Hot tub</Text>}
      </Box>
    </Box>
  );
});

export default Heading;
