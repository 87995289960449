import { Box, Table, Text, getCSS } from '@awning/components';
import type { TableProps } from '@awning/components';

export const Breakdown = ({
  title: { mainTitle, subTitle },
  data,
}: {
  title: { mainTitle: string; subTitle: string };
  data: TableProps['data'];
}) => {
  const columns = Object.keys(data[0]).map(d => ({ accessor: d }));

  return (
    <>
      <Box sx={{ marginBottom: 4 }}>
        <Text sx={{ text: 'sm', fontWeight: 'bold' }}>{mainTitle}</Text>
        &nbsp;
        <Text sx={{ text: 'xs', color: 'gray.500' }}>{subTitle}</Text>
      </Box>
      <Table
        getRowProps={(row: any) => {
          const isEven = row.index % 2;
          return !isEven
            ? {}
            : {
                style: getCSS({
                  sx: {
                    backgroundColor: 'gray.100',
                  },
                }),
              };
        }}
        getCellProps={cell => {
          const isLastColumn =
            (cell as any).column.id === 'col2'
              ? {
                  textAlign: 'right',
                }
              : {};

          return {
            style: getCSS({
              sx: {
                paddingY: 2,
                text: 'xs',
                color: 'gray.500',
                ...isLastColumn,
              },
            }),
          };
        }}
        disableHeaderGroups={true}
        columns={columns}
        data={data}
      />
    </>
  );
};
