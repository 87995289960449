import * as React from 'react';
import { shallow } from '@awning/archie';
import { Box, Flex } from '@awning/components';
import { SortListings } from './SortListings';
import { useTopMarketsStore } from '../store';
import { useMapViewStore } from '@/src/MapView';
import { Card, CardProps } from './Card';

export const TopMarketListings = () => {
  const { currentIndexOffSet, filteredListings } = useTopMarketsStore(
    state => ({
      currentIndexOffSet: state.range[0],
      filteredListings: state.topMarkets?.slice(state.range[0], state.range[1]),
    }),
    shallow
  );

  const markerCollection = useMapViewStore(state => state.markerCollection);

  const handleHover = React.useCallback(
    (id: string) => {
      const overlay = markerCollection.getOverlayById(id);
      const currentlyHighlighted =
        markerCollection?.getCurrentSelected() || markerCollection?.getCurrentSelected();

      if (overlay && currentlyHighlighted !== overlay) {
        markerCollection.deselect();
        markerCollection?.select(overlay);
      }
    },
    [markerCollection]
  );

  return (
    <Box
      sx={{
        lineHeight: 1.8,
        paddingBottom: '5%',
      }}
    >
      <Box sx={{ marginTop: 5 }}>
        <Flex
          sx={{
            mt: 2,
            flexDirection: { base: 'column', lg: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Flex
            sx={{
              fontWeight: 'bold',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              fontSize: {
                base: 'xs',
                lg: 'sm',
              },
            }}
          >
            <Flex>
              <SortListings />
            </Flex>
          </Flex>
        </Flex>
      </Box>

      <ListingsInner
        currentIndexOffSet={currentIndexOffSet}
        filteredListings={filteredListings}
        handleHover={handleHover}
      />
    </Box>
  );
};

const ListingsInner = React.memo(
  ({
    currentIndexOffSet,
    filteredListings,
    handleHover,
  }: {
    currentIndexOffSet: number;
    filteredListings: CardProps['listing'][];
    handleHover: CardProps['onHover'];
  }) => {
    return (
      <Flex
        sx={{
          marginTop: { base: 2, lg: 4 },
          flexWrap: 'wrap',
          gap: 4,
          marginRight: { lg: 5 },
          height: '100%',
        }}
      >
        {filteredListings.map((listing, index: number) => {
          return (
            <Card
              key={listing.geoId}
              position={currentIndexOffSet + index + 1}
              listing={listing}
              onHover={handleHover}
            />
          );
        })}
      </Flex>
    );
  }
);
