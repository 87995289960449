import * as React from 'react';
import { Box, Divider, Flex, Text } from '@awning/components';
import { MaskedData } from '../MaskedData/MaskedData';

type ValueData = {
  name: {
    short: string;
    long: string;
  };
  value: string;
}[][];

export const Estimates = React.memo(
  ({ title, groupValues }: { title: string; groupValues: ValueData }) => {
    return (
      <Box sx={{ paddingBottom: 4, whiteSpace: 'nowrap' }}>
        <Text sx={{ display: 'block', fontWeight: 'bold', marginTop: 6, marginBottom: 4 }}>
          {title}
        </Text>
        {groupValues.map((values, groupIndex) => {
          return (
            <React.Fragment key={groupIndex}>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))' }}>
                {values.map(({ name: { short, long }, value }, index) => {
                  const border =
                    index !== values.length - 1
                      ? { borderRight: '1px solid', borderColor: 'gray.200' }
                      : {};

                  return (
                    <Flex
                      key={index}
                      sx={{
                        ...border,
                        flexDirection: 'column',
                        flexGrow: 1,
                        marginRight: 4,
                      }}
                    >
                      <Text
                        sx={{
                          display: { base: 'block', lg: 'none' },
                          color: 'gray.500',
                          marginBottom: 1,
                          text: 'xs',
                        }}
                      >
                        {short}
                      </Text>
                      <Text
                        sx={{
                          display: { base: 'none', lg: 'block' },
                          color: 'gray.500',
                          marginBottom: 1,
                          text: 'xs',
                        }}
                      >
                        {long}
                      </Text>
                      <Text sx={{ fontSize: { lg: 'lg' } }}>
                        {index === 0 ? value : <MaskedData>{value}</MaskedData>}
                      </Text>
                    </Flex>
                  );
                })}
              </Box>
              {groupIndex !== groupValues.length - 1 && (
                <Divider sx={{ marginY: 6, marginRight: 4, backgroundColor: 'gray.200' }} />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    );
  }
);
