import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {
  BASE_MAP_KEY_TRANSFORMS,
  ESTIMATOR_MAP_KEY_TRANSFORMS,
  MARKET_INSIGHTS_MAP_KEY_TRANSFORMS,
  TOP_MARKET_MAP_KEY_TRANSFORMS,
} from './FilterConstants';
import { TFilterName, TFilterState } from './types';

export const removeIgnoredKeys = (
  state: TFilterState,
  keys: TFilterName[]
): Partial<TFilterState> => {
  return omit(state, keys);
};

export const skipDefaults = (state: TFilterState, defaults: TFilterState) => {
  const keys = Object.keys(state) as TFilterName[];

  const newState = keys.reduce((acc, key: any) => {
    // @ts-expect-error
    const entries = Object.entries(state[key] as any).reduce((acc, [k, v]) => {
      if (typeof v !== 'undefined' && v !== undefined && v !== null) {
        if (!isEqual(state[key as keyof typeof state], defaults[key as keyof typeof defaults])) {
          return { ...acc, [k]: v };
        }
      }
      return acc;
    }, {});

    if (Object.keys(entries).length) return { ...acc, [key]: entries };

    return acc;
  }, {});

  return newState;
};

export const removeEmptyKeys = (
  state: TFilterState,
  skipKeys: TFilterName[],
  transformerFns: Partial<typeof BASE_MAP_KEY_TRANSFORMS>
): Partial<TFilterState> => {
  const keys = Object.keys(state) as TFilterName[];

  const newState = keys.reduce((acc, key) => {
    // not necessary but nice to be explicit and exit early
    // @ts-expect-error
    if (skipKeys.includes(key)) return { ...acc, [key]: state[key] };

    // @ts-expect-error
    const entries = Object.entries(state[key] as any).reduce((acc, [k, v]) => {
      if (v !== undefined && v !== null) {
        const transformFn = transformerFns?.[key]?.fn;
        let value = v;
        if (transformFn) {
          value = transformFn(v, k); // k = 'min' | 'max'
        }
        // !value to check for NaN
        if (!value || typeof value === 'undefined' || value === null || value === undefined) {
          return acc;
        }
        return { ...acc, [k]: value };
      }
      return acc;
    }, {});

    if (Object.keys(entries).length) return { ...acc, [key]: entries };

    return acc;
  }, {});

  return newState;
};

// FIXME: generate dynamic type
// type TServerQueryFilterState = {
// }
export const toServerQueryState = (
  state: TFilterState,
  ignoredKeys: TFilterName[],
  transformerFns: Partial<
    | typeof BASE_MAP_KEY_TRANSFORMS
    | typeof ESTIMATOR_MAP_KEY_TRANSFORMS
    | typeof MARKET_INSIGHTS_MAP_KEY_TRANSFORMS
    | typeof TOP_MARKET_MAP_KEY_TRANSFORMS
  >
) => {
  let filteredState = removeIgnoredKeys(state, ignoredKeys);
  filteredState = removeEmptyKeys(filteredState as TFilterState, ['sortOrder'], transformerFns);

  let rest = Object.keys(filteredState).reduce((acc, key) => {
    if (key === 'daysOnMarket') {
      // the min / max are inverted when asking the server.
      // - The maximum days on market = min listing date
      // - The minimum days on market = max listing date

      //@ts-expect-error
      const max = filteredState[key]?.min;
      //@ts-expect-error
      const min = filteredState[key]?.max;

      const _key = transformerFns?.[key]?.name ?? key;

      let values = {};
      if (min) {
        values = { ...values, min };
      }
      if (max) {
        values = { ...values, max };
      }
      return {
        ...acc,
        [_key]: values,
      };
    }

    //@ts-expect-error
    const _key = transformerFns?.[key]?.name ?? key;
    //@ts-expect-error
    const values = filteredState[key];

    return {
      ...acc,
      [_key]: values,
    };
  }, {});

  const result = Object.entries(rest).reduce((acc, [key, val]) => {
    if (val && Object.keys(val).length > 0) {
      return { ...acc, [key]: val };
    }
    return acc;
  }, {});

  return result;
};

export const hasFilterChanged = (
  filterState: TFilterState,
  defaultFilterState: TFilterState,
  transformFns: typeof BASE_MAP_KEY_TRANSFORMS | typeof ESTIMATOR_MAP_KEY_TRANSFORMS,
  filter: TFilterName | 'moreFilters' | null,
  omitFromChangeCheck: TFilterName[] = ['sortOrder']
) => {
  if (!filter) return false;

  const current = toServerQueryState(
    omit(filterState, omitFromChangeCheck) as TFilterState,
    [],
    transformFns
  );
  const base = toServerQueryState(
    omit(defaultFilterState, omitFromChangeCheck) as TFilterState,
    [],
    transformFns
  );

  if (filter === 'moreFilters') {
    return !isEqual(current, base);
  }

  // since we now get back the server key maps instead
  const serverName = transformFns?.[filter]?.name as TFilterName;

  //@ts-expect-error
  return !isEqual(current[serverName], base[serverName]);
};

export const hasAppliedFilters = (
  a: TFilterState,
  b: TFilterState,
  omitKeys: TFilterName[] = []
) => {
  const _a = omit(a, [...omitKeys, 'moreFilters']);
  const _b = omit(b, omitKeys);

  return !isEqual(_a, _b);
};
