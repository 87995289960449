import { memo } from 'react';
import { Box, Flex, Text, toLocaleUS, toUSD } from '@awning/components';
import { TMarketInsightsListing } from '../types';
import { MaskedData } from '@/src/MaskedData/MaskedData';
import { pluralize } from '@/src/utils';

export const PropertyOverview = memo<{
  listing: TMarketInsightsListing;
  position?: number;
}>(
  ({
    position,
    listing: { bathrooms, bedrooms, annualBookingRevenueLtm, listingTitle, maxGuests },
  }) => {
    return (
      <Box sx={{ marginTop: 1 }}>
        {position ? (
          <Text sx={{ fontWeight: 'bold', text: 'lg' }}>{toUSD(annualBookingRevenueLtm)}</Text>
        ) : (
          <MaskedData>
            <Text sx={{ fontWeight: 'bold', text: 'lg' }}>{toUSD(annualBookingRevenueLtm)}</Text>
          </MaskedData>
        )}
        <Text sx={{ text: 'sm' }}>/yr</Text>
        <Flex sx={{ gap: 1, text: 'sm' }}>
          <Text>{SFR(bedrooms, bathrooms)}</Text>
          <Text sx={{ color: 'gray.400' }}>&#x2022;</Text>
          <Text>
            <MaskedData>{maxGuests}</MaskedData> {pluralize(maxGuests, 'Guest')}
          </Text>
        </Flex>
        <Box
          sx={{
            color: 'gray.500',
            text: 'xs',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {listingTitle}
        </Box>
      </Box>
    );
  }
);

const SFR = (bedroomCount: number, bathroomCount: number) => {
  return (
    <>
      <Text sx={{ marginRight: 1 }}>
        <MaskedData>{toLocaleUS(bedroomCount)}</MaskedData> {pluralize(bedroomCount, 'Bed')}
      </Text>
      <Text> &#x2022; </Text>
      <Text>
        <MaskedData>{toLocaleUS(bathroomCount)}</MaskedData> {pluralize(bathroomCount, 'Bath')}
      </Text>
    </>
  );
};
