export class ListingCardMarkerOverlay {
  position: google.maps.LatLng;
  containerDiv: HTMLDivElement;
  overlay: google.maps.OverlayView;
  content: HTMLElement;

  constructor(position: google.maps.LatLng, content: HTMLElement) {
    this.position = position;
    this.overlay = new google.maps.OverlayView();
    this.overlay.onAdd = this.onAdd.bind(this);
    this.overlay.onRemove = this.onRemove.bind(this);
    this.overlay.draw = this.draw.bind(this);
    this.content = content;

    content.classList.add('popup-bubble');

    // This zero-height div is positioned at the bottom of the bubble.
    const bubbleAnchor = document.createElement('div');

    bubbleAnchor.classList.add('popup-bubble-anchor');
    bubbleAnchor.appendChild(content);

    // This zero-height div is positioned at the bottom of the tip.
    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('popup-container');
    this.containerDiv.appendChild(bubbleAnchor);

    // Optionally stop clicks, etc., from bubbling up to the map.
    google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  /** Called when the popup is added to the map. */
  onAdd() {
    this.overlay.getPanes()!.floatPane.appendChild(this.containerDiv);
  }

  /** Called when the popup is removed from the map. */
  onRemove() {
    if (this.containerDiv.parentElement) {
      try {
        this.containerDiv.parentElement?.removeChild(this.containerDiv);
      } catch {}
    }
  }

  /** Called each frame when the popup needs to draw itself. */
  draw() {
    const divPosition = this.overlay.getProjection().fromLatLngToDivPixel(this.position)!;

    /**
     * Super simple/basic hack to get the tooltip positioning correct
     **/

    // get the position of the div inside the map container
    const containerPos = this.overlay.getProjection().fromLatLngToContainerPixel(this.position)!;
    const containerX = containerPos.x;
    const containerY = containerPos.y;

    // @ts-ignore
    const mapDiv = this.overlay.getMap().getDiv();

    // this is also based on the value we use below to actually position the overlay
    const contentDivX = this.content.clientWidth - 30;
    const contentDivY = this.content.clientHeight + 10;

    let farRight = false;
    let farLeft = false;
    let farTop = false;

    //touches far right of the map
    if (containerX > mapDiv.clientWidth - contentDivX) farRight = true;
    // touches far left of map
    if (containerX < contentDivX) farLeft = true;
    // touches the top of the map
    if (containerY < contentDivY) farTop = true;

    // Hide the popup when it is far out of view.
    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

    if (display === 'block') {
      this.containerDiv.style.position = 'absolute';

      // this.containerDiv.style.minWidth = '240px'; // fix the width - also prevents the card from shifting when panning

      // the default position if the overlay isn't in a corner somewhere
      this.containerDiv.style.left = divPosition.x - this.content.clientWidth / 2 + 30 + 'px';
      this.containerDiv.style.top = divPosition.y - this.content.clientHeight - 10 + 'px';

      if (farTop) this.containerDiv.style.top = divPosition.y + 30 + 'px';
      if (farLeft) this.containerDiv.style.left = divPosition.x + 'px';
      if (farRight)
        this.containerDiv.style.left = divPosition.x - this.content.clientWidth + 70 + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}
