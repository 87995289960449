import React from 'react';
import { Box, Flex, Text } from '@awning/components';
import { useCalculatorStore } from './calculatorStore';
import isEmpty from 'lodash/isEmpty';
import { toAbbrUSD, toPercentString } from '@/src/utils';

export const MonthlyPayment = () => {
  const { inputs, result } = useCalculatorStore(state => ({
    inputs: state.getActiveTabInputValues(),
    result: state.getActiveTabResultValues(),
  }));

  let principal = 0;
  let interest = 0;

  if (isEmpty(result)) {
    return null;
  } else {
    principal = result?.debt.debtPrincipal[0] / 12;
    interest = result?.debt.debtInterest[0] / 12;
  }

  const data = [
    { label: 'Principal', value: principal, color: 'green.700' }, // debtPrincipal[0] / 12
    { label: 'Interest', value: interest, color: 'green.500' }, // debtInterest[0] / 12
  ];

  const total = data.reduce((s, { value: v }) => (s += v), 0);

  return (
    <Box sx={{ width: '100%', marginTop: 4 }}>
      <Flex
        sx={{
          text: 'sm',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text sx={{ fontWeight: 'bold' }}>Monthly Payment</Text>
        <Box>
          <Text>30 year fixed, </Text>
          <Text>{toPercentString(inputs.interestRate / 100, 1)} Interest</Text>
        </Box>
      </Flex>

      <IndicatorBar data={data} total={total} />
    </Box>
  );
};

const IndicatorBar = ({
  data = [],
  total,
}: {
  total?: number;
  data: {
    label: string;
    value: number;
    color: string;
  }[];
}) => {
  const _total = total ? total : data.reduce((s, { value: v }) => (s += v), 0);

  const filteredData = data.filter(({ value }) => value > 0); // only show things over than 0

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Flex sx={{ my: 3 }}>
        {filteredData.map((d, index) => {
          const { value, color } = d;
          const width = `${(value / _total) * 100}%`;
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                minHeight: '10px',
                height: '10px',
                margin: 0,
                padding: 0,
                overflow: 'hidden',
                appearance: 'none',
                bg: color,
                width,
              }}
            />
          );
        })}
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {filteredData.map((d, index) => {
          const { label, color, value } = d;
          const isFirst = index === 0;
          const isLast = index === filteredData.length - 1;
          return (
            <Flex
              key={index}
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: index === filteredData.length - 1 ? 0 : '1px solid',
                borderColor: 'gray.300',
                paddingTop: isFirst ? 1 : 3,
                paddingBottom: isLast ? 1 : 3,
                text: 'sm',
              }}
            >
              <Flex
                sx={{
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: color,
                    height: '12px',
                    width: '12px',
                  }}
                />

                <Text sx={{ ml: 2, mr: 2 }}>{label}</Text>
              </Flex>
              <Text>{toAbbrUSD(value)}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
