import * as React from 'react';
import { Box, Checkbox, Flex, Label } from '@awning/components';
import { useFilterStore } from '../store';
import type { TTopMarketFilterState } from '../types';
import { FilterPillContext } from '../FilterPill';
import { capitalize } from '@/src/utils';

const MARKET_SIZES = {
  'small': 'Less than 100 Airbnbs',
  'medium': '100 to 500 Airbnbs',
  'large': 'More than 500 Airbnbs',
};

export const MarketSizeFilter = () => {
  const { marketSize, update } = useFilterStore<TTopMarketFilterState>(state => ({
    marketSize: state.filterState.marketSize.values,
    update: state.updateFilter,
  }));

  const onUpdate = (name: string, checked: boolean) => {
    let currentValues = marketSize ?? [];
    if (checked) {
      update('marketSize', 'values', [...currentValues, name].sort().reverse());
    } else {
      update(
        'marketSize',
        'values',
        currentValues.filter((v: string) => v !== name)
      );
    }
  };

  const { setTitle } = React.useContext(FilterPillContext);

  React.useEffect(() => {
    if (setTitle) {
      if (marketSize.length === 0) setTitle('');
      if (marketSize.length === 4) {
        setTitle(`${marketSize.slice(0, 4).map(capitalize).join(', ')}...`);
      } else {
        setTitle(marketSize.map(capitalize).join(', '));
      }
    }
  }, [marketSize]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ marginTop: 4, marginBottom: 2 }}>
      <Flex sx={{ gap: 3, flexDirection: 'column' }}>
        {Object.entries(MARKET_SIZES).map(([name, description]) => {
          const checked = marketSize.includes(name);
          return (
            <Box key={name}>
              <Label sx={{ alignItems: 'flex-start', cursor: 'pointer' }}>
                <Box sx={{ marginTop: 1 }}>
                  <Checkbox
                    id={name}
                    name={name}
                    checked={checked}
                    value={name}
                    onChange={() => onUpdate(name, !checked)}
                  />
                </Box>
                <Flex sx={{ flexDirection: 'column' }}>
                  <Box sx={{ whiteSpace: 'nowrap' }}>{capitalize(name)}</Box>
                  <Box sx={{ color: 'gray.400', text: 'xs' }}>{description}</Box>
                </Flex>
              </Label>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};
