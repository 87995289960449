import * as React from 'react';
import { TItem } from './types';
import { SearchBoxContext } from './SearchBoxContext';
import { SearchBoxItem } from './SearchBoxItem';

export function BaseStep() {
  const { collection, setHighlightedItemIndex, highlightedItemIndex, onSelect } =
    React.useContext(SearchBoxContext);
  const items = collection.items;
  const itemToString = collection.itemToString;

  return (
    <>
      {items.map((item: TItem, i: number) => (
        <SearchBoxItem
          key={i}
          item={item}
          itemToString={itemToString!}
          itemIndex={i}
          isHighlighted={i === highlightedItemIndex}
          onHightlight={() => setHighlightedItemIndex!(i)}
          onSelect={onSelect!}
        />
      ))}
    </>
  );
}
