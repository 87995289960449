import * as React from 'react';
import { Box, Button, Divider, Flex, LinkButton, Tag, Text, toUSD } from '@awning/components';
import { TEstimatorListing } from '@/src/estimator/types';
import { toPercentString } from '@/src/utils';
import { ReactComponent as StarIcon } from './star.svg';
import { ReactComponent as AirbnbIcon } from './airbnb.svg';
import { PropertyOverview } from './PropertyOverview';
import { MaskedData } from '@/src/MaskedData/MaskedData';

export type CardProps = {
  listing: TEstimatorListing;
  position?: number;
  onShowDetails?: (id: string) => void;
  onHover?: (id: string) => void;
  onViewAirbnb?: () => void;
};
const imageHeight = '220px';

export const maxListingCardWidth = '600px';

export const Card = React.memo(
  ({ listing, position, onShowDetails, onHover, onViewAirbnb }: CardProps) => {
    const { pictureUrl, radiusMiles, listingUrl } = listing;

    return (
      <Box
        sx={{
          color: 'gray.600',
          width: '100%',
          maxWidth: { lg: maxListingCardWidth },
        }}
      >
        <Box
          onMouseEnter={() => {
            onHover?.(listing.airbnbAwningId);
          }}
          sx={{ fontWeight: 'normal', textDecoration: 'none' }}
        >
          <Box
            sx={{
              display: { base: 'block', lg: 'grid' },
              gridTemplateColumns: 'minmax(0, auto) minmax(0, 1fr)',
              backgroundColor: 'white',
              position: 'relative',
              boxShadow: 'none',
              border: '1px solid',
              borderRadius: 'xl',
              borderColor: 'gray.200',
              overflow: 'hidden',
              transition: 'box-shadow 300ms',
              width: '100%',
              '&:hover': {
                boxShadow: 'lg',
              },
            }}
          >
            <Box
              sx={{
                height: imageHeight,
                width: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 2,
                  top: 2,
                  zIndex: 3,
                }}
              >
                <Tag sx={{ fontSize: '10px !important' }}>{radiusMiles?.toFixed(2)} mi</Tag>
              </Box>

              <Box
                as="img"
                sx={{
                  width: { base: '100%', lg: '280px' },
                  height: '220px',
                  objectFit: 'cover',
                }}
                src={pictureUrl}
                width="280px"
                height={imageHeight}
              />
              <Box
                sx={{
                  position: 'absolute',
                  left: 2,
                  bottom: 2,
                  zIndex: 3,
                }}
              >
                {onViewAirbnb ? (
                  <Button
                    size="sm"
                    sx={{
                      fontSize: '12px',
                      backgroundColor: 'white',
                      borderColor: 'gray.600',
                      py: 0.5,
                      px: 3,
                    }}
                    onPress={onViewAirbnb}
                  >
                    View on &nbsp;
                    <AirbnbIcon />
                  </Button>
                ) : (
                  <LinkButton
                    size="sm"
                    sx={{
                      fontSize: '12px',
                      backgroundColor: 'white',
                      borderColor: 'gray.600',
                      py: 0.5,
                      px: 3,
                    }}
                    href={listingUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on &nbsp;
                    <AirbnbIcon />
                  </LinkButton>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                borderTop: { base: '1px solid', lg: 0 },
                borderColor: 'gray.200',
                paddingX: 4,
                paddingY: 2,
                cursor: 'pointer',
              }}
              onClick={onShowDetails}
            >
              {position && isFinite(position) && (
                <Flex
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    text: 'sm',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: '#37764C',
                    borderRadius: 'full',
                    height: '24px',
                    width: '24px',
                    position: 'absolute',
                    right: 4,
                  }}
                >
                  {position}
                </Flex>
              )}
              <PropertyOverview
                listing={listing}
                position={position && isFinite(position) && position <= 3 ? position : undefined}
              />

              <Divider sx={{ backgroundColor: 'gray.200', marginY: 2 }} />

              <Flex sx={{ gap: 1, flexDirection: 'column' }}>
                <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', text: 'xs' }}>
                  <Flex sx={{ alignItems: 'center' }}>ADR</Flex>
                  <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                    <MaskedData>
                      <Text>{toUSD(listing.averageDailyRate)}</Text>
                    </MaskedData>
                  </Flex>
                </Flex>

                <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', text: 'xs' }}>
                  <Flex sx={{ alignItems: 'center' }}>Occupancy</Flex>
                  <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                    <MaskedData>
                      <Text>{toPercentString(listing.occupancyRateLtm, 0)}</Text>
                    </MaskedData>
                  </Flex>
                </Flex>

                <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', text: 'xs' }}>
                  <Flex sx={{ alignItems: 'center' }}>Reviews</Flex>
                  <Flex sx={{ alignItems: 'center', gap: '2px', justifyContent: 'flex-end' }}>
                    <MaskedData>
                      <Box as={StarIcon} sx={{ marginTop: '-2px' }} width="10px" height="10px" />
                      <Text>{listing.ratingStars}</Text>
                      <Text>({listing.numberOfReviews})</Text>
                    </MaskedData>
                  </Flex>
                </Flex>
              </Flex>
              <Button
                sx={{
                  fontSize: '12px',
                  color: 'black',
                  border: 0,
                  px: 0,
                  py: 0,
                  textAlign: 'left',
                  display: 'inline',
                  textDecoration: 'underline',
                  background: 'transparent',
                  fontWeight: 'bold',
                  paddingTop: 2,
                }}
                onPress={(e: any) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  onShowDetails?.(listing.airbnbAwningId);
                }}
              >
                See Details
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);
