import React, { Fragment } from 'react';
import { Button, Flex } from '@awning/components';
import { ReactComponent as LockOpen } from '@awning/components/assets/icons/lock-open.svg';
import { useUserStore } from '@/src/shared/userStore';
import { useSignupModal } from '@/src/SignupModal';

export const SignUpToViewCardOverlayMarker: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isLoggedIn = useUserStore(state => state.isLoggedIn?.());
  const showSignupModal = useSignupModal(state => state.show);

  if (isLoggedIn) return <Fragment>{children}</Fragment>;

  return (
    <Flex
      sx={{
        padding: 2,
        gap: 4,
        textDecoration: 'none !important',
        fontWeight: 'normal',
        cursor: 'pointer',
        text: 'xs',
        // background: '#052E16',
        // color: '#fff',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onClick={showSignupModal}
    >
      <div>
        <LockOpen height={24} width={24} stroke="#000" />
      </div>
      <div>
        <p>Awning is 100% free. Sign up to unlock all insights & data</p>
      </div>
      <Button
        sx={{
          background: '#198639',
          color: '#fff',
          whiteSpace: 'nowrap',
          height: '36px',
        }}
        size="sm"
      >
        Sign up
      </Button>
    </Flex>
  );
};
