import * as React from 'react';
import { Box, Button, Divider, Flex, Text, theme } from '@awning/components';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';
import { useOnClickOutside } from '@/src/useOnClickOutside';
import { useMediaQuery } from '@/src/useMediaQuery';
import type { TFilterName } from '../types';
import { useEstimatorFilterStore } from '../store';
import { RatingFilter } from './RatingFilter';
import { RadiusFilter } from './RadiusFilter';
import { ReviewsFilter } from './ReviewsFilter';
import { ADRFilter } from './ADRFilter';
import { GuestsFilter } from './GuestsFilter';
import { BedroomFilter } from './BedroomFilter';
import { EstimatorAmenitiesFilters } from './EstimatorAmenitiesFilter';

const contentPaddingX = 8;
const contentPaddingY = { base: 3, lg: 4 };
const FilterHeading: React.FC<React.PropsWithChildren<{ name?: TFilterName; heading: string }>> =
  React.memo(({ name, heading, children }) => {
    const inactiveFilters = useEstimatorFilterStore(state => state.defaults().INACTIVE_FILTERS);

    if (name && inactiveFilters.includes(name)) return null;

    return (
      <Flex
        sx={{
          backgroundColor: 'white',
          paddingX: contentPaddingX,
          paddingBottom: 5,
          flexDirection: 'column',
        }}
      >
        <Text sx={{ text: 'lg', fontWeight: 'bold' }}>{heading}</Text>
        {children}
      </Flex>
    );
  });

const FilterSection: React.FC<React.PropsWithChildren<unknown>> = React.memo(({ children }) => {
  return <Box sx={{ backgroundColor: 'white', paddingY: 4 }}>{children}</Box>;
});

export const MoreFilters = () => {
  const moreFiltersRef = React.useRef();
  const isDesktop = useMediaQuery(`(min-width:${theme.breakpoints[2]})`);
  const { hide, isOpen, clear, executeSearch } = useEstimatorFilterStore(state => ({
    isOpen: state.filterShown === 'moreFilters',
    hide: () => state.hideFilter('moreFilters'),
    clear: () => state.clearFilter('moreFilters'),
    executeSearch: state.executeSearch,
  }));

  useOnClickOutside(
    [moreFiltersRef],
    () => {
      hide();
      executeSearch();
    },
    [isOpen, isDesktop],
    isOpen && isDesktop
  );

  const style = !isDesktop ? { left: 0, width: '100%', boxShadow: 'none' } : {};

  React.useEffect(() => {
    let oldStyle: string;
    const elem = document.querySelector('#__next') as HTMLElement;

    if (isOpen && elem) {
      oldStyle = elem.style.overflow;
      elem.style.overflow = 'hidden';
    }

    return () => {
      const elem = document.querySelector('#__next') as HTMLElement;
      if (elem) elem.style.overflow = oldStyle;
    };
  }, [isOpen]);

  return (
    <Box
      sx={{
        display: isOpen ? 'block' : 'none',
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'hidden',
          opacity: 0.5,
          width: '100vw',
          height: '100vh',
          zIndex: 99,
          backgroundColor: 'white',
        }}
      />

      <Box
        sx={{
          width: '400px',
          zIndex: 9999,
          boxShadow: '2xl',
          position: 'fixed',
          height: '100vh',
          backgroundColor: 'white',
          top: 0,
          right: 0,
          bottom: 0,
          ...style,
        }}
        ref={moreFiltersRef}
      >
        <Box
          sx={{
            position: 'sticky',
            left: 0,
            right: 0,
            top: 0,
            background: 'white',
            zIndex: 1,
          }}
        >
          <Box sx={{ display: { base: 'block', lg: 'none' } }}>
            <Flex
              sx={{
                paddingY: contentPaddingY,
                paddingX: contentPaddingX,
                text: 'lg',
                fontWeight: 'bold',
                alignItems: 'center',
                justifyContent: 'space-between',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                width: '100%',
              }}
            >
              <Flex
                sx={{
                  height: '24px',
                  width: '24px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  hide();
                  executeSearch();
                }}
              >
                <Box as={CloseIcon} height="8px" width="8px" sx={{ cursor: 'pointer' }} />
              </Flex>
              <Text sx={{ textAlign: 'center' }}>All Filters</Text>
              <Text
                sx={{
                  textAlign: 'right',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
                onClick={clear}
              >
                Clear
              </Text>
            </Flex>
            <Divider />
          </Box>
          <Box sx={{ display: { base: 'none', lg: 'block' } }}>
            <Flex
              sx={{
                paddingY: contentPaddingY,
                paddingX: contentPaddingX,
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                width: '100%',
                borderBottom: '1px solid',
                borderColor: 'gray.300',
              }}
            >
              <Text sx={{ text: 'lg', fontWeight: 'bold' }}>All Filters</Text>

              <Box
                as={CloseIcon}
                height="12px"
                width="12px"
                sx={{
                  position: 'absolute',
                  right: 8,
                  marginTop: -1,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  hide();
                  executeSearch();
                }}
              />
            </Flex>
          </Box>
        </Box>

        <Flex
          sx={{
            justifyContent: 'space-between',
            paddingBottom: 4,
            flexDirection: 'column',
            backgroundColor: 'gray.200',
            gap: 2,
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <FilterSection>
            <FilterHeading heading="Bedrooms">
              <BedroomFilter />
            </FilterHeading>
            <FilterHeading heading="Rating">
              <RatingFilter />
            </FilterHeading>
            <FilterHeading heading="Max. Radius">
              <RadiusFilter />
            </FilterHeading>
            <FilterHeading heading="Min. Guest Reviews">
              <ReviewsFilter />
            </FilterHeading>
            <FilterHeading heading="ADR">
              <ADRFilter />
            </FilterHeading>
            <FilterHeading heading="Guests">
              <GuestsFilter />
            </FilterHeading>
          </FilterSection>

          <FilterSection>
            <FilterHeading heading="">
              <EstimatorAmenitiesFilters />
            </FilterHeading>
          </FilterSection>

          <Flex
            className="empty-padding"
            sx={{
              paddingBottom: 200,
            }}
          />
        </Flex>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'white',
            alignSelf: 'bottom',
          }}
        >
          <Divider />
          <Box sx={{ display: { base: 'none', lg: 'block' } }}>
            <Flex
              sx={{
                paddingY: 6,
                paddingX: 8,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text
                sx={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}
                onClick={clear}
              >
                Clear
              </Text>
              <Box sx={{ marginLeft: 4, whiteSpace: 'nowrap' }}>
                <Button size="sm" variant="tertiary" onPress={executeSearch}>
                  See homes
                </Button>
              </Box>
            </Flex>
          </Box>
          <Box sx={{ display: { base: 'block', lg: 'none' } }}>
            <Flex
              sx={{
                paddingY: 4,
                paddingX: 8,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button sx={{ width: '100%' }} variant="tertiary" onPress={executeSearch}>
                See homes
              </Button>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
