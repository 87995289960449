import * as React from 'react';
import { Box, Flex, LinkButton, Text } from '@awning/components';
import { useCalendly } from '@/src/useCalendly';
import { ReactComponent as ChevronRight } from '@awning/components/assets/icons/chevron-right.svg';
import { publicUrl } from '../publicUrl';

// import propertyManagementBackgroundPNG from '../../public/property-management-background.png';

const Star = () => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.52447 1.08156C5.67415 0.620904 6.32585 0.620902 6.47553 1.08156L7.09607 2.99139C7.29688 3.60943 7.87282 4.02786 8.52265 4.02786H10.5308C11.0151 4.02786 11.2165 4.64767 10.8247 4.93237L9.20006 6.11271C8.67433 6.49468 8.45434 7.17173 8.65516 7.78976L9.2757 9.69959C9.42537 10.1602 8.89813 10.5433 8.50628 10.2586L6.88168 9.07827C6.35595 8.6963 5.64405 8.69631 5.11832 9.07827L3.49372 10.2586C3.10187 10.5433 2.57463 10.1603 2.7243 9.69959L3.34484 7.78976C3.54566 7.17173 3.32567 6.49468 2.79994 6.11271L1.17534 4.93237C0.783482 4.64767 0.984868 4.02786 1.46923 4.02786H3.47735C4.12719 4.02786 4.70312 3.60942 4.90393 2.99139L5.52447 1.08156Z"
      stroke="#6CDA72"
    />
  </svg>
);

export const PropertyManagement = React.memo(() => {
  const openCalendly = useCalendly({
    calendlyParams: {
      // url: 'https://host.redawning.com/meetings/redawning/meeting-with-awning',
      url: 'https://host.redawning.com/meetings/redawning/meeting-with-awning',
    },
  });
  return (
    <Flex
      sx={{
        position: 'relative',
        marginY: 6,
        background: '#E8F9F2',
        borderRadius: 'md',
        marginRight: { base: 0, lg: 4 },
        flexDirection: { base: 'column', lg: 'row' },
      }}
    >
      <Box
        as="img"
        sx={{ display: { base: 'block', lg: 'none' }, objectFit: 'cover', height: '56px' }}
        src={publicUrl('property-management-background-mobile.png')}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          text: 'sm',
          paddingTop: 5,
          paddingLeft: 5,
          paddingBottom: 5,
          paddingRight: { base: 5, lg: 0 },
        }}
      >
        <Text sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Get your property managed by the best in the industry and increase your revenue by 10-30%.
        </Text>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(0,auto))',
            marginY: 4,
            text: 'xs',
          }}
        >
          <Box>
            <Flex sx={{ gap: 1, alignItems: 'center' }}>
              <Box sx={{ marginTop: -1 }}>
                <Star />
              </Box>
              <Text sx={{ fontWeight: 'bold', color: 'green.800' }}>4.8</Text>
            </Flex>
            <Text>guest rating</Text>
          </Box>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ fontWeight: 'bold', color: 'green.800', whiteSpace: 'wrap' }}>
              5-15 min
            </Text>
            <Text>guest response time</Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ fontWeight: 'bold', color: 'green.800', whiteSpace: 'wrap' }}>
              Starts at 15%
            </Text>
            <Text>of revenue</Text>
          </Flex>
        </Box>
        <Box />
        <Flex
          sx={{
            flexDirection: {
              base: 'column',
              lg: 'row',
            },
            marginTop: 2,
            alignItems: 'center',
            gap: { base: 3, lg: 8 },
          }}
        >
          <Box sx={{ width: { base: '100%', lg: 'auto' } }}>
            <LinkButton
              size="sm"
              variant="secondary"
              href="https://host.redawning.com/meetings/redawning/meeting-with-awning"
              target="_blank"
              rel="noreferrer"
              onClick={(e: any) => {
                e.preventDefault();
                openCalendly();
              }}
            >
              Schedule a call
            </LinkButton>
          </Box>
          <Flex
            as="a"
            href="https://awning.com/vacation-rental-property-management"
            target="_blank"
            rel="noreferrer"
            sx={{ alignItems: 'center' }}
          >
            Learn More
            <ChevronRight height="16px" width="16px" />
          </Flex>
        </Flex>
      </Flex>
      <Box
        as="img"
        sx={{ display: { base: 'none', lg: 'block' }, maxWidth: '170px' }}
        src={publicUrl('property-management-background.png')}
      />
    </Flex>
  );
});
