import { publicUrl } from '@/src/publicUrl';
import { dasherize } from '@/src/utils';
import { MARKER_Z_INDEX, MarkerOverlay } from './MarkerOverlay';

export class TopMarketMarketOverlay extends MarkerOverlay {
  draw() {
    super.draw();

    const defaultMarker = this._customMarkerDiv;
    const numberTag = this.div.getElementsByClassName('position-index-tag')[0] as HTMLElement;

    if (typeof this.index !== 'undefined' && !isNaN(this.index)) {
      const number = this.index + 1;
      defaultMarker.style.zIndex = MARKER_Z_INDEX.IS_NUMBERED;
      numberTag.innerHTML = `${number}`;
      numberTag.style.zIndex = MARKER_Z_INDEX.IS_NUMBERED;
      numberTag.style.display = 'inline-flex';

      // lift anything less than 10 higher up in zIndex
      if (number <= 10) {
        defaultMarker.style.zIndex = MARKER_Z_INDEX.IS_NUMBERED + 1;
        numberTag.style.zIndex = MARKER_Z_INDEX.IS_NUMBERED + 1;
      }
      if (number > 10) {
        numberTag.style.backgroundColor = '#aaa';
        numberTag.style.color = '#fff';
      }
    }
    defaultMarker.style.display = 'flex';

    const listingUrl = publicUrl(
      `/airbnb-market-data/${dasherize(this.data.city)}-${this.data.state}`
    );
    (defaultMarker as HTMLLinkElement).href = listingUrl;
  }
}
