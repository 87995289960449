import * as React from 'react';
import axios from 'axios';
import Tippy from '@tippyjs/react/headless';
import { Box, Flex, Text } from '@awning/components';
import { useUserStore } from '@/src/shared/userStore';

const dropdownLinks: Array<{ label: string; href: string; newTab?: boolean }> = [
  { label: 'Account', href: `${process.env.NEXT_PUBLIC_BASE_PATH}/account` },
];

export const ProfileDropdown: React.FC = () => {
  const { firstName, lastName } = useUserStore(state => state.user);
  const [hovered, setHovered] = React.useState(-1);

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/accounts/logout`);
    } catch (e: any) {}
  };

  return (
    <Tippy
      interactive={true}
      placement="bottom-end"
      appendTo={() => document.body}
      arrow={true}
      trigger="click"
      render={attrs => {
        return (
          <Box
            sx={{
              background: 'white',
              border: '1px solid',
              borderColor: 'gray.200',
              borderRadius: 'lg',
              boxShadow: 'lg',
              color: 'gray.700',
              minWidth: '200px',
              overflow: 'hidden',
            }}
          >
            {dropdownLinks.map(({ label, href, newTab }, index) => (
              <DropdownLink href={href} newTab={newTab} key={index}>
                <Box
                  sx={{
                    borderBottom: index < dropdownLinks.length - 1 ? '1px solid' : 'none',
                    borderColor: 'gray.200',
                    paddingLeft: '14px',
                    paddingY: 3,
                    fontWeight: 'normal',
                    backgroundColor: hovered === index ? 'gray.700' : 'white',
                    color: hovered === index ? 'white' : 'inherit',
                  }}
                  {...attrs}
                  onMouseEnter={() => setHovered(index)}
                >
                  <Text>{label}</Text>
                </Box>
              </DropdownLink>
            ))}
            <Box
              sx={{
                borderTop: '1px solid',
                borderColor: 'gray.200',
                paddingLeft: '14px',
                paddingY: 3,
                fontWeight: 'normal',
                backgroundColor: hovered === 3 ? 'gray.700' : 'white',
                color: hovered === 3 ? 'white' : 'inherit',
              }}
              {...attrs}
              onMouseEnter={() => setHovered(3)}
              onMouseLeave={() => setHovered(-1)}
              onClick={() => {
                handleLogout();
              }}
            >
              <Text>Log out</Text>
            </Box>
          </Box>
        );
      }}
    >
      <Flex
        sx={{
          borderRadius: '100%',
          backgroundColor: 'blue.500',
          color: 'white',
          display: 'inline-flex',
          width: { base: '30px', md: '40px' },
          height: { base: '30px', md: '40px' },
          alignItems: 'center',
          justifyContent: 'center',
          lineHeight: 1.5,
        }}
      >
        {firstName?.charAt(0) ?? '-'}
        {lastName?.charAt(0) ?? '-'}
      </Flex>
    </Tippy>
  );
};

type DropdownLinkProps = {
  newTab?: boolean;
  href: string;
};

const DropdownLink: React.FC<React.PropsWithChildren<DropdownLinkProps>> = ({
  newTab,
  href,
  children,
}) => {
  return (
    <>
      {newTab ? (
        <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          {children}
        </a>
      ) : (
        <a href={href} style={{ textDecoration: 'none' }}>
          {children}
        </a>
      )}
    </>
  );
};
