import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, Snackbar, Text } from '@awning/components';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';

// TODO: this is duplicated?
export const ScheduleAnalysis = React.memo(() => {
  const [openSnackbar, setOpenSnackbar] = React.useState(true);

  return (
    <AnimatePresence>
      {openSnackbar && (
        <Snackbar open={openSnackbar} sx={{ text: { base: 'xs', sm: 'sm' } }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text
              sx={{
                display: { base: 'none', sm: 'block' },
                marginX: { base: 1, sm: 2 },
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              Analyze any address and get Airbnb revenue, occupancy, and average daily rate
              estimates. Try out our{'  '}
              <a href="https://awning.com/airbnb-estimator">Airbnb Estimator</a>.
            </Text>
            <Text
              sx={{
                display: { base: 'block', sm: 'none' },
                marginX: { base: 1, sm: 2 },
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              Analyze any address using our{' '}
              <a href="https://awning.com/airbnb-estimator">Airbnb Estimator</a>.
            </Text>
            <Box
              as={CloseIcon}
              height="12px"
              width="12px"
              sx={{
                cursor: 'pointer',
                marginLeft: { base: 2, lg: 4 },
              }}
              onClick={() => setOpenSnackbar(false)}
            />
          </motion.div>
        </Snackbar>
      )}
    </AnimatePresence>
  );
});
