import * as React from 'react';
import { Box, Flex, LinkButton, Text } from '@awning/components';
import { useCalendly } from '@/src/useCalendly';
import { publicUrl } from '@/src/publicUrl';

const Star = () => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.52447 1.08156C5.67415 0.620904 6.32585 0.620902 6.47553 1.08156L7.09607 2.99139C7.29688 3.60943 7.87282 4.02786 8.52265 4.02786H10.5308C11.0151 4.02786 11.2165 4.64767 10.8247 4.93237L9.20006 6.11271C8.67433 6.49468 8.45434 7.17173 8.65516 7.78976L9.2757 9.69959C9.42537 10.1602 8.89813 10.5433 8.50628 10.2586L6.88168 9.07827C6.35595 8.6963 5.64405 8.69631 5.11832 9.07827L3.49372 10.2586C3.10187 10.5433 2.57463 10.1603 2.7243 9.69959L3.34484 7.78976C3.54566 7.17173 3.32567 6.49468 2.79994 6.11271L1.17534 4.93237C0.783482 4.64767 0.984868 4.02786 1.46923 4.02786H3.47735C4.12719 4.02786 4.70312 3.60942 4.90393 2.99139L5.52447 1.08156Z"
      stroke="#1E3A8A"
    />
  </svg>
);

export const RentEstimatorPropertyManagement = React.memo(() => {
  const openCalendly = useCalendly({
    calendlyParams: {
      url: 'https://calendly.com/janiceforawningbrokerage/long-term-rental-estimate',
    },
  });
  return (
    <Flex
      sx={{
        position: 'relative',
        marginY: 6,
        paddingY: 4,
        background: '#EFF6FF',
        borderRadius: 'md',
        marginRight: { base: 0, lg: 4 },
        flexDirection: { base: 'column', md: 'row' },
      }}
    >
      <Box
        as="img"
        sx={{
          marginLeft: 8,
          display: { base: 'none', md: 'block' },
          maxWidth: '96px',
          height: '96px',
        }}
        src={publicUrl('awning-rent-estimator-property-management.png')}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          text: 'sm',
          paddingLeft: 5,
          paddingRight: { base: 5, lg: 0 },
        }}
      >
        <Box
          as="img"
          sx={{
            display: { base: 'block', md: 'none' },
            height: '65px',
          }}
          src={publicUrl('awning-rent-estimator-property-management-mobile.png')}
        />
        <Box>
          <Text sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            Get your property managed by the best in the industry and save money by comparing
            options.{' '}
          </Text>
          <Box
            as="a"
            href="https://awning.com/rental-property-management"
            target="_blank"
            rel="noreferrer"
            sx={{
              textDecoration: 'none',
              color: 'blue.600',
              alignItems: 'center',
            }}
          >
            Learn More
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplate: {
              base: `
'a b c'
'd d d'
`,
              md: `'a b c d'`,
            },
            gap: 4,
            marginTop: 4,
            text: 'xs',
          }}
        >
          <Box>
            <Flex sx={{ gap: 1, alignItems: 'center' }}>
              <Text sx={{ fontWeight: 'bold', color: 'blue.900' }}>Hundreds</Text>
            </Flex>
            <Text sx={{ color: '#404040' }}>of available options</Text>
          </Box>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ fontWeight: 'bold', color: 'blue.900', whiteSpace: 'wrap' }}>Instant</Text>
            <Text sx={{ color: '#404040' }}>match with top managers</Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text sx={{ fontWeight: 'bold', color: 'blue.900', whiteSpace: 'wrap' }}>
              Starts at 5%
            </Text>
            <Text sx={{ color: '#404040' }}>of revenue</Text>
          </Flex>
          <Box
            sx={{
              gridArea: 'd',
              textAlign: 'right',
              marginRight: 4,
              width: { base: '100%', lg: 'auto' },
            }}
          >
            <LinkButton
              size="sm"
              variant="secondary"
              href="https://calendly.com/janiceforawningbrokerage/long-term-rental-estimate"
              target="_blank"
              rel="noreferrer"
              onClick={(e: any) => {
                e.preventDefault();
                openCalendly();
              }}
            >
              Schedule call
            </LinkButton>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
});
