import { MarkerOverlay } from './MarkerOverlay';

export class HomeMarkerOverlay extends MarkerOverlay {
  draw() {
    super.draw();

    const d = this.div.getElementsByClassName('address')[0] as HTMLDivElement;
    if (d && !this._hovered) {
      d.style.display = 'none';
    }

    this.div.style.zIndex = '9999999999999';
  }

  _onHover(e?: any): void {
    super._onHover(e);

    const d = this.div.getElementsByClassName('address')[0] as HTMLDivElement;
    d.style.display = 'block';
  }
}
