import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import { useRouter } from 'next/router';
import { Box, Flex, Text } from '@awning/components';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';

import {
  TLocationResult,
  TMarketInsightsSearchItem,
  TNoSearchBarResult,
} from '@/src/SearchBox/types';
import { STATE_NAMES } from 'STATES';
import {
  BaseStep,
  SearchBox,
  SearchBoxItemsCollectionClass,
  SearchBoxStep,
  useAutoCompleteAndGeocoder,
} from '@/src/SearchBox';
import useNoResultsModal from '@/src/SearchBox/useNoResultsModal';
import { dasherize } from '@/src/utils';

const itemToString = <T extends TMarketInsightsSearchItem>(item: T | null | undefined) => {
  if (item === null || item === undefined) return '';

  if ('isEmptyResult' in item) {
    return (item as TNoSearchBarResult).query;
  }

  if (item.hasOwnProperty('fieldMap')) {
    if ((item as TLocationResult).fieldMap === 'zip_code') {
      // If it's a zip_code, don't show the state
      return `${(item as TLocationResult).data}`;
    } else if ((item as TLocationResult).fieldMap === 'state') {
      return STATE_NAMES[`${(item as TLocationResult).state}` as keyof typeof STATE_NAMES];
    } else {
      return `${(item as TLocationResult).data}, ${(item as TLocationResult).state}`;
    }
  }
  return '';
};

class TMarketInsightsSearchBoxCollection<
  T extends TMarketInsightsSearchItem[] = any
> extends SearchBoxItemsCollectionClass {
  constructor(public items: T) {
    super(items);
  }

  getCount() {
    return this.items.length;
  }

  getItemByIndex(index: number) {
    return this.items[index];
  }

  itemToString(item: any): string {
    return itemToString(item);
  }
}

export const SearchBar = () => {
  const { query, push } = useRouter();
  const NoResultsModal = useNoResultsModal();
  const { autocomplete, geocoder } = useAutoCompleteAndGeocoder();

  const [items, setItems] = React.useState<TMarketInsightsSearchItem[]>([]);
  const collection = React.useMemo(() => new TMarketInsightsSearchBoxCollection(items), [items]);

  const value = (query?.q as string).replace(/--/g, ', ') ?? '';

  const onChange = async (inputValue: string) => {
    if (!autocomplete) return;

    if (inputValue === '') {
      setItems([]);
      return;
    }

    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/autocomplete`, {
        params: { inputValue, marketInsights: 1 },
      });

      if (!response || isEmpty(response?.data?.locations)) {
        setItems([{ isEmptyResult: true, query: inputValue }]);
        return;
      }

      setItems(response.data.locations);
    } catch {
      setItems([]);
    }
  };

  const onSubmit = async (selectedItem: TMarketInsightsSearchItem) => {
    if (!geocoder || !selectedItem) return;

    // If the SelectedItem does not have Autocomplete results
    if ('isEmptyResult' in selectedItem) {
      NoResultsModal.show();
    } else {
      const place = dasherize(selectedItem.data);
      const state = selectedItem.state.toUpperCase();
      push(
        {
          query: {
            q: `${selectedItem.data}--${state}`,
          },
        },
        `${place}-${state}`
      );
      setItems([]);
    }
  };

  React.useEffect(() => {
    if (autocomplete) onChange(value);
  }, [autocomplete, value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <SearchBox<TMarketInsightsSearchBoxCollection>
        onChange={onChange}
        onSelect={onSubmit}
        collection={collection}
        value={value}
        isMultiStep={false}
        placeholder="Type an address"
      >
        <SearchBoxStep step={1}>
          <BaseStep />
        </SearchBoxStep>
      </SearchBox>
      <NoResultsModal.Modal isShow={NoResultsModal.isShow}>
        <Flex
          sx={{
            backgroundColor: 'white',
            borderRadius: 'lg',
            flexDirection: 'column',
            maxWidth: { base: '100%', sm: '850px' },
            padding: 4,
            margin: { base: 4, sm: 0 },
          }}
        >
          <Box
            as={CloseIcon}
            height="18px"
            width="18px"
            sx={{ alignSelf: 'flex-end', cursor: 'pointer' }}
            onClick={NoResultsModal.hide}
          />
          <Text sx={{ marginBottom: { base: 5, sm: 6 } }}>
            Please check the spelling, try clearing the search box, or try reformatting to match
            these examples:
          </Text>
          <Text sx={{ marginBottom: 6 }}>
            <strong>City:</strong> Los Angeles, CA
            <br />
            <strong>Zip:</strong> 75204
            <br />
          </Text>
          <Text sx={{ color: 'gray.500', text: 'sm' }}>
            Note, if a city/zip wasn't found, try checking the spelling or see if it has another
            name. Some cities and zips won't appear because they don't have enough data to provide
            reasonable insights and conclusions.
          </Text>
        </Flex>
      </NoResultsModal.Modal>
    </Box>
  );
};
