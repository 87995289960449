import { memo, useEffect, useMemo } from 'react';
import { Box, Button, Flex, Text, toUSD } from '@awning/components';
import { useRentEstimatorStore } from './store';
import { useModalStore } from './Calculator/useModal';
import { CalculatorForm } from './Calculator/CalculatorForm';
import { useCalculatorStore } from './Calculator/calculatorStore';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { TListing } from '../typeUserListing';
import { ReactComponent as CalculatorIcon } from '@/public/calculator-icon.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup
  .object({
    'common': yup.object({
      'purchasePrice': yup
        .number()
        .min(10000, 'Purchase price must be greater than $10,000')
        .required('Enter a purchase price to see your returns'),
    }),
  })
  .required();

const listingDefaults = {
  financials: {
    unitCount: 1,
    term: 30,
    marginalTaxRate: 0,
    buildingPercentagePropertyValue: 0,
  } as unknown as TListing['financials'],
};

const EstimatedRevenue = memo(() => {
  const [estimate, estimateRange, modelParams] = useRentEstimatorStore(state => [
    state.estimate,
    state.estimateRange,
    state.modelParams,
  ]);
  const calculate = useCalculatorStore(state => state.calculateTRA);

  const numBedrooms = modelParams.bedrooms;
  const utilities = modelParams.property_type.toLowerCase() === 'apartment' ? 165 : 0;

  const initialValues = useMemo(
    () => ({
      common: {
        appreciation: 3,
        hoa: 0,
        purchasePrice: 0,
        propertyTaxRate: 1.5,
        renovationCost: 0,
        renovationValueIncrease: 0,
      },
      tra: {
        buildingPercentagePropertyValue: 0,
        contractServices: 0,
        downPayment: 30,
        expenseGrowth: 3,
        insurance: numBedrooms * 800,
        interestRate: 7.5,
        marginalTaxRate: 0,
        otherExpenses: 0,
        propertyManagementRateFee: 8,
        rentGrowth: 3,
        repairsAndMaintenance: 0.6 * estimate,
        utilities,
        vacancyRate: 3,
        yearOneMonthlyRent: estimate,
        yearOneOtherMonthlyIncome: 0,
      },
    }),
    []
  );

  const { show } = useModalStore();
  const methods = useForm<any>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const onReset = () => methods.reset(initialValues);
  const values = useWatch({ control: methods.control });

  useEffect(() => {
    calculate({ ...initialValues, ...(values as any) }, listingDefaults as TListing);
  }, [calculate, values]); //eslint-disable-line

  useEffect(() => {
    calculate({ ...initialValues, ...(values as any) }, listingDefaults as TListing);
    methods.trigger();
  }, [initialValues]); // eslint-disable-line

  return (
    <Box
      sx={{
        my: 4,
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderColor: 'gray.200',
        paddingBottom: 4,
        paddingTop: 2,
      }}
    >
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', paddingRight: 4 }}>
        <Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              borderRight: '1px solid',
              borderColor: 'gray.200',
              paddingRight: 5,
              paddingTop: 2,
              gap: 2,
            }}
          >
            <Flex
              sx={{
                text: 'xs',
                fontWeight: 'bold',
                backgroundColor: '#BBF7D0',
                borderRadius: '4px',
                padding: '4px 8px',
              }}
            >
              Your rental estimate
            </Flex>
            <Flex sx={{ alignItems: 'flex-end', gap: 0.5 }}>
              <Text sx={{ text: 'lg', fontWeight: 'bold' }}>{toUSD(estimate)}</Text>
              <Text sx={{ text: 'sm' }}>/mo</Text>
            </Flex>
          </Flex>
          <Flex sx={{ flexDirection: 'column', paddingLeft: 5, paddingTop: 2, gap: 2 }}>
            <Flex sx={{ text: 'xs', color: 'gray.500', paddingY: '4px' }}>Estimate range</Flex>
            <Flex sx={{ gap: 1, fontWeight: 'bold' }}>
              <Text>{toUSD(estimateRange[0])}</Text>
              <Text>-</Text>
              <Text>{toUSD(estimateRange[1])}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <Button
            onPress={show}
            sx={{
              background: '#14532D',
              borderColor: '#14532D',
            }}
            size="sm"
          >
            <CalculatorIcon />
            <Text sx={{ color: 'white' }}>Calculate your returns</Text>
          </Button>
          <FormProvider {...methods}>
            <CalculatorForm control={methods.control} onReset={onReset} />
          </FormProvider>
        </Flex>
      </Flex>
    </Box>
  );
});

export default EstimatedRevenue;
