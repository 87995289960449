import * as React from 'react';
import Heading from './Heading';
import EstimatedRevenue from './EstimatedRevenue';
import { RentEstimatorPropertyManagement } from './RentEstimatorPropertyManagement';
import { EMapViewPage, EstimatorMarker, HomeMarker, MapView, useMapViewStore } from '@/src/MapView';
import { MapAndListingsContainerView } from './MapAndListingsContainer';
import { useRentEstimatorStore } from './store';
import { ListingsTable } from './ListingsTable';
import { ListingRentEstimatorCardOverlayMarker } from '@/src/MapView/Markers/ListingRentEstimatorCardOverlayMarker';

export const RentEstimator = () => {
  return (
    <>
      <MapAndListingsContainerView
        maxWidth="60%"
        renderListings={() => {
          return (
            <>
              <Heading />
              <EstimatedRevenue />
              <RentEstimatorPropertyManagement />
              <ListingsTable />
            </>
          );
        }}
        renderMapView={() => {
          return (
            <MapView page={EMapViewPage.RENT_ESTIMATOR} activeControls={[]}>
              <HomeMarker />
              <EstimatorMarker />
              <ListingRentEstimatorCardOverlayMarker />
              <SearchAreaCircle />
            </MapView>
          );
        }}
      />
    </>
  );
};

const SearchAreaCircle = () => {
  const map = useMapViewStore(state => state.map);
  const { listings, coordinates } = useRentEstimatorStore(state => ({
    listings: state.listings,
    coordinates: state.coordinates,
  }));

  const ref = React.useRef<undefined | google.maps.Circle>();

  React.useEffect(() => {
    if (ref.current) {
      ref.current.setMap(null);
    }
    if (map) {
      const radiusMiles = Math.max(...listings.map(l => l.radiusMiles));
      if (!ref.current) {
        ref.current = new google.maps.Circle({
          strokeColor: '#0066CC',
          strokeWeight: 2,
          fillColor: '#E6F2FF',
          fillOpacity: 0.25,
          center: new google.maps.LatLng(coordinates),
          radius: radiusMiles * 1609.34, // to meters
        });
      } else {
        ref.current.setCenter(new google.maps.LatLng(coordinates));
        ref.current.setRadius(radiusMiles * 1609.34);
      }
      ref.current.setMap(map);
    }
  }, [coordinates, listings, map]);
  return <div />;
};
