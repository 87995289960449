import { useMemo, useState } from 'react';
import { Box, Flex } from '@awning/components';
import { ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import { format, parseISO } from 'date-fns';
import { AxisTickProps } from '@nivo/axes';
import { toAbbrUSD } from '@/src/utils';
import { LineLegends } from './LineLegends';

export const NivoLineChart = ({
  data,
  tickYValues = 6,
  xFormat = value => format(parseISO(value as string), 'MMM yy'),
  yFormat = value => toAbbrUSD(value as number),
  minGraphHeight,
}: // tickXValues = 6,
{
  data: any;
  tickYValues?: any;
  xFormat?: (value: number | string) => number | string;
  yFormat?: (value: number | string) => number | string;
  minGraphHeight: number;
}) => {
  const [hiddenIds, setHiddenIds] = useState<{ [key: string]: boolean }>({});

  const hideId = (id: string) => {
    setHiddenIds({
      ...hiddenIds,
      [id]: !hiddenIds[id],
    });
  };

  const filteredData = useMemo(
    () =>
      data.reduce((acc: any, v: any) => {
        const item = { ...v };

        if (hiddenIds[v.id]) {
          item.data = [];
        }
        return [...acc, item];
      }, []),
    [data, hiddenIds]
  );

  const tickXValues = useMemo(() => {
    const data = filteredData?.[0]?.data?.filter((_: any, i: number) => i % 2 === 0);
    return data?.map(({ x }: any) => x);
  }, [filteredData]);

  return (
    <Box>
      <Box sx={{ height: `${minGraphHeight}px` }}>
        <ResponsiveLine
          data={filteredData}
          margin={{ top: 40, right: 20, bottom: 35, left: 40 }}
          colors={{ datum: 'color' }}
          curve="monotoneX"
          xScale={{ type: 'point' }}
          xFormat={xFormat as any}
          yFormat={yFormat as any}
          gridYValues={tickYValues}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickValues: tickXValues,
            renderTick: p => CustomTick(p, 'bottom', xFormat),
          }}
          axisLeft={{
            tickValues: tickYValues,
            renderTick: p => CustomTick(p, 'left', yFormat),
          }}
          enableGridX={false}
          enablePoints={false}
          pointLabelYOffset={-12}
          useMesh={true}
          crosshairType="cross"
          enableSlices="x"
          sliceTooltip={({ slice }: SliceTooltipProps) => {
            return (
              <Box
                sx={{
                  text: 'xs',
                  padding: 1.5,
                  border: '1px solid',
                  backgroundColor: 'white',
                  borderRadius: 'md',
                  boxShadow: 'md',
                  marginTop: 4,
                  mb: 1,
                  zIndex: 9999999999999,
                }}
              >
                {slice.points.map(point => (
                  <Box
                    key={point.id}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      color: point.serieColor,
                      gap: 4,
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ whiteSpace: 'nowrap', textAlign: 'center', fontWeight: 'bold' }}>
                      {point.serieId}
                    </Box>
                    <Flex
                      sx={{
                        alignItems: 'center',
                        text: 'xs',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {point.data.xFormatted}:&nbsp;&nbsp;
                      <Box sx={{ fontWeight: 'bold', text: 'sm' }}>{point.data.yFormatted}</Box>
                    </Flex>
                  </Box>
                ))}
              </Box>
            );
          }}
        />
      </Box>
      <LineLegends data={data} hiddenIds={hiddenIds} onClick={hideId} />
    </Box>
  );
};

const CustomTick = (
  tick: AxisTickProps<string>,
  leftOrBottom: 'left' | 'bottom',
  formatValue = (v: any) => v
) => {
  const transform =
    leftOrBottom === 'left'
      ? `translate(${tick.x - 20},${tick.y})`
      : `translate(${tick.x},${tick.y + 20})`;

  return (
    <g transform={transform}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          // ...theme.axis.ticks.text,
          fill: '#6A6D77',
          fontSize: 10,
        }}
      >
        {formatValue(tick.value)}
      </text>
    </g>
  );
};
