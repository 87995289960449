import React, { MutableRefObject, useEffect } from 'react';
import { Box, Flex, Select, SelectItem } from '@awning/components';
import { shallow } from '@awning/archie';
import isEqual from 'lodash/isEqual';
import { useGoogleMapsApi } from '@/src/GoogleMap';
import {
  EMapViewOptionKeys,
  EMapViewOptions,
  EMapViewPage,
  TMapViewControls,
  useMapViewStore,
} from './MapView';
import { MapListing } from './MapListingKlasses';
import { useRouter } from 'next/router';
import { MarkerOverlayCollection } from './MarkerOverlayCollection';

import { useComparableModal as useEstimatorComparableModal } from '@/src/estimator/EstimatorListings/ComparableModal';
import { useComparableModal as useMarketInsightsComparableModal } from '@/src/market-insights/MarketListings/ComparableModal';
import { useUserStore } from '@/src/shared/userStore';
import { useSignupModal } from '../SignupModal';

export interface GCoordinates {
  lat: number;
  lng: number;
}

const DEFAULT_ZOOM_LEVEL = 15;
export const ListingsMap: React.FC<
  React.PropsWithChildren<{
    page: EMapViewPage;
    listings: MapListing[];
    activeControls: TMapViewControls[];
  }>
> = ({ page, listings = [], activeControls, children }) => {
  const {
    map,
    setMap,
    isFullView,
    toggleFullView,
    option,
    setOption,
    setSelectedMarker,
    markerCollection,
  } = useMapViewStore(
    state => ({
      map: state.map,
      setMap: state.setMap,
      isFullView: state.isFullView,
      toggleFullView: state.toggleFullView,
      option: state.option,
      setOption: state.setOption,
      setSelectedMarker: state.setSelectedMarker,
      markerCollection: state.markerCollection,
    }),
    shallow
  );

  const isLoggedIn = useUserStore(state => state.isLoggedIn?.());
  const firstLoad = React.useRef(true);
  const touchMap = React.useRef(false);
  const boundsChanged = React.useRef(false);
  const showEstimatorModal = useEstimatorComparableModal(state => state.show);
  const showMarketInsights = useMarketInsightsComparableModal(state => state.show);
  const showSignupModal = useSignupModal(state => state.show);

  const { query } = useRouter();
  // Reset the first load flag so we can fitBounds when a user changes the search
  useEffect(() => {
    if (!query?.geo) {
      firstLoad.current = true;
    }
  }, [query]);

  const defaultSelectedOption = Object.keys(EMapViewOptions[page]).map(Number)?.[0];
  const enabledControls = React.useMemo(() => ['ZOOM', ...activeControls], [activeControls]);
  const googleApi = useGoogleMapsApi();
  const ref = React.useRef(null);

  useEffect(() => {
    if (ref.current && googleApi && !map) {
      const center = listings?.[0]?.getCoordinates();

      setMap(
        new googleApi.maps.Map(ref.current, {
          center,
          zoom: DEFAULT_ZOOM_LEVEL,
          gestureHandling: 'greedy',
          zoomControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          clickableIcons: false,
          mapId: '1bf5295b744a394a',
        })
      );
    }
  }, [googleApi, map, ref]); //eslint-disable-line

  React.useEffect(() => {
    if (
      markerCollection &&
      markerCollection.overlays.length > 0 &&
      Object.values(EMapViewOptionKeys).includes(option!)
    ) {
      const overlays = markerCollection.overlays;
      overlays.forEach((_, i) => {
        const o = overlays[i];
        if (o.mapListingKlass) {
          o.setContent(o.mapListingKlass.getContent(option ?? defaultSelectedOption));
        }
        o.draw();
      });
    }
    // we only want this to run when the selectedOption changes!
  }, [option]); // eslint-disable-line

  // const placesService = React.useRef();
  React.useEffect(() => {
    const map = ref.current! as HTMLDivElement;
    const initLoad = () => {
      firstLoad.current = false;
      touchMap.current = true;
    };
    const dLoad = () => (touchMap.current = false);
    const popEvent = () => {
      firstLoad.current = true;
      touchMap.current = false;
    };
    const isTouch = 'ontouchstart' in window;

    if (isTouch) {
      map.addEventListener('touchstart', initLoad);
    } else {
      map.addEventListener('mousedown', initLoad);
      map.addEventListener('mouseup', dLoad);
    }

    map.addEventListener('wheel', initLoad);
    window.addEventListener('popstate', popEvent);

    return () => {
      map.removeEventListener('wheel', initLoad);
      map.removeEventListener('touchstart', initLoad);
      map.removeEventListener('mousedown', initLoad);
      map.removeEventListener('mouseup', dLoad);
      window.removeEventListener('popstate', popEvent);
    };
  }, []);

  const listeners = React.useRef<google.maps.MapsEventListener[]>([]);
  const addListeners = React.useCallback(
    (map: google.maps.Map, query: any, _: MarkerOverlayCollection) => {
      if (!map) return;

      listeners.current.forEach(l => google.maps.event.removeListener(l));

      const onBoundsChanged = () => {
        if (!firstLoad.current) boundsChanged.current = true;
      };
      listeners.current.push(map.addListener('bounds_changed', onBoundsChanged));

      const onZoomChanged = () => {
        if (firstLoad.current && query?.zoomLevel) {
          const zoomLevel = parseInt(query?.zoomLevel as string, 10);
          firstLoad.current = false;
          map.setZoom(zoomLevel);
        }
      };
      listeners.current.push(map.addListener('zoom_changed', onZoomChanged));
    },
    []
  );

  /*
   * Create the overlays on the map
   */
  useEffect(() => {
    if (map) {
      const bounds = new google.maps.LatLngBounds();

      if (markerCollection && markerCollection.overlays?.length > 0) {
        markerCollection.clear();
      }

      listings.forEach((l, index) => {
        const textContent = l.getContent(option ?? defaultSelectedOption) ?? '';
        const coordinates = new google.maps.LatLng(l.getCoordinates());
        bounds.extend(coordinates);

        const klass = l.getOverlayClass();
        const markerRef = l.getMarkerRef();
        const data = l.getData();

        let onClick = () => {};
        if (!l.isMasked) {
          // this is shit. Having to import the onClick show handler here.
          onClick = l.onClick;

          if (page === EMapViewPage.ESTIMATOR) {
            onClick = showEstimatorModal;
          }
          if (page === EMapViewPage.MARKET_INSIGHTS) {
            onClick = showMarketInsights;
          }
        }

        if (!markerRef.current) {
          throw new Error(
            "Missing a ref for the marker. Either you haven't added it to the DOM or some race condition has happened"
          );
        }

        new klass(
          l.getId(),
          coordinates,
          textContent,
          markerRef.current,
          data,
          markerCollection,
          l,
          (active: boolean) => {
            //, id?: string, map?: google.maps.Map) => {
            // force unset to force redraw
            setSelectedMarker(-1);
            if (active) {
              // then set the current index
              setSelectedMarker(index);
            }
          },
          onClick,
          l.positionIndex ?? undefined
        );
      });

      markerCollection.overlays.forEach(o => {
        o.show(); // unset visibility hidden
        o.setMap(map); // toggle visibility on the map
      });

      // zoom the map out so we can see all the properties
      if (firstLoad.current) {
        if (query?.geo) {
          const geoBounds = JSON.parse(query.geo as string);
          const newBounds = new google.maps.LatLngBounds(
            { lat: geoBounds.bottomRight.lat, lng: geoBounds.topLeft.lng },
            { lat: geoBounds.topLeft.lat, lng: geoBounds.bottomRight.lng }
          );
          map.fitBounds(newBounds);
        } else {
          map.fitBounds(bounds);
        }
      }

      addListeners(map, query, markerCollection);
    }
  }, [map, listings]); // eslint-disable-line

  /*
   * Create the controls on the map
   */
  const fullscreenRef = React.useRef();
  const optionsRef = React.useRef();
  const zoomControlRef = React.useRef();

  useEffect(() => {
    const addControlToMap = (
      map: undefined | google.maps.Map,
      position: google.maps.ControlPosition,
      controlName: 'ZOOM' | TMapViewControls,
      control: React.MutableRefObject<HTMLElement | undefined>
    ) => {
      if (!map || !control.current || !enabledControls.includes(controlName as TMapViewControls))
        return;

      // If the control already exists on the map then don't add it.
      // Very hacky but after trial and error i just settled on this.
      const elems = Array.from(map.controls[position]?.getArray() ?? []);
      if (
        elems.length > 0 &&
        control.current &&
        elems.find((c: HTMLElement) =>
          isEqual(Array.from(c.classList) as string[], Array.from(control.current?.classList ?? []))
        )
      ) {
        return;
      } else {
        map.controls[position].push(control.current as any);
      }
    };

    if (map) {
      addControlToMap(map, google.maps.ControlPosition.TOP_LEFT, 'FULLSCREEN', fullscreenRef);
      addControlToMap(
        map,
        google.maps.ControlPosition.TOP_CENTER,
        'SELECT_VIEW_OPTION',
        optionsRef
      );
      addControlToMap(map, google.maps.ControlPosition.TOP_RIGHT, 'ZOOM', zoomControlRef);
    }
  }, [map, enabledControls]);

  // have to force the font family for specific tags on google maps otherwise it overrides them with Roboto
  const fontFamily = `"Benton Sans", "Helvetica Neue", "Arial", sans-serif`;

  return (
    <>
      <Flex
        ref={ref}
        sx={{
          width: { base: '100vw', sm: '100%' },
          height: { base: '100%', sm: '100%' },
        }}
      />

      {children}

      <Flex
        ref={fullscreenRef}
        onClick={toggleFullView}
        sx={{
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'gray.100',
          borderRadius: 'lg',
          boxShadow: 'lg',
          cursor: 'pointer',
          marginTop: 4,
          marginLeft: 4,
          alignItems: 'center',
          justifyContent: 'center',
          height: '32px',
          width: '32px',
        }}
        title="Click to maximize the map"
      >
        <Flex
          sx={{
            fontSize: '10px',
            marginTop: '-2px',
          }}
        >
          {!isFullView ? `❮` : `❯`}
        </Flex>
      </Flex>

      <ZoomControl map={map!} controlRef={zoomControlRef} />

      <Box
        ref={optionsRef}
        sx={{
          marginTop: 4,
          height: '32px',
          boxShadow: 'lg',
          borderRadius: 'lg',
          cursor: 'pointer',
          fontFamily,
        }}
      >
        <Select
          sx={{
            border: 0,
            boxShadow: 'md',
            text: {
              base: 'base',
              lg: 'sm',
            },
          }}
          sxSelect={{
            backgroundColor: 'white',
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            width: 'fit-content',
            fontWeight: 'bold',
          }}
          onChange={(e: any) => {
            if (isLoggedIn) {
              setOption(parseInt(e?.target?.value, 10));
            } else {
              showSignupModal({ step: 1 });
            }
          }}
          value={option}
        >
          {Object.entries(EMapViewOptions[page]).map(([key, value]) => {
            return (
              <SelectItem key={key} value={key}>
                {value}
              </SelectItem>
            );
          })}
        </Select>
      </Box>
    </>
  );
};

const ZoomControl = React.memo(
  ({ controlRef, map }: { controlRef: MutableRefObject<any>; map: google.maps.Map }) => {
    const onZoom = (inc: boolean) => {
      if (!map) return;
      const zoom = map.getZoom() ?? 0;
      if (inc) map.setZoom(zoom + 1);
      else map.setZoom(zoom - 1);
    };

    return (
      <Flex
        ref={controlRef}
        sx={{
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'gray.100',
          borderRadius: 'lg',
          boxShadow: 'lg',
          cursor: 'pointer',
          marginTop: 4,
          marginRight: 4,
          textAlign: 'center',
          width: '32px',
          height: '64px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            fontSize: '24px',
            paddingLeft: '5px',
            paddingRight: '5px',
            color: '#000',
          }}
          onClick={() => onZoom(true)}
          title="Click to zoom in"
        >
          +
        </Flex>
        <Flex
          onClick={() => onZoom(false)}
          sx={{
            fontSize: '30px',
            paddingLeft: '5px',
            paddingRight: '5px',
            color: '#000',
          }}
          title="Click to zoom out"
        >
          -
        </Flex>
      </Flex>
    );
  }
);
