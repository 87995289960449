import * as React from 'react';
import { Flex } from '@awning/components';
import { FilterPill } from '../FilterPill';
import { StatesFilter } from './StatesFilter';
import { MarketSizeFilter } from './MarketSizeFilter';
import { AirbnbPriceFilter } from './AirbnbPriceFilter';

export const TopMarketFilters = () => {
  return (
    <Flex
      sx={{
        width: { base: '100vw', md: '100%' },
        flexWrap: 'nowrap',
        gap: { base: 2, md: 3 },
        paddingY: 2,
        '::-webkit-scrollbar': { display: 'none' },
        '::-webkit-scrollbar *': { display: 'none' },
        '*::-webkit-scrollbar': { display: 'none' },
        'WebkitOverflowScrolling': 'touch',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        paddingRight: 6,
      }}
    >
      <Flex
        sx={{
          marginTop: 1,
          text: 'xs',
          fontWeight: 'bold',
        }}
      >
        Filters
      </Flex>
      <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
        <FilterPill
          filter="states"
          text="State"
          actionText="See markets"
          sxComponentProps={{
            maxWidth: { lg: 'unset !important' },
          }}
        >
          <StatesFilter />
        </FilterPill>
        <FilterPill filter="marketSize" text="Market size" actionText="See markets">
          <MarketSizeFilter />
        </FilterPill>
        <FilterPill filter="avgAirbnbPrice" text="Avg. Airbnb Price" actionText="See markets">
          <AirbnbPriceFilter />
        </FilterPill>
      </Flex>
    </Flex>
  );
};
