import * as React from 'react';
import { useTopMarketsStore } from '../top-airbnb-markets';
import { Pagination } from './Pagination';

export const TopMarketsPagination = React.memo(() => {
  const { currentRange, updateRange, listings } = useTopMarketsStore(state => ({
    currentRange: state.range,
    updateRange: state.updateRange,
    listings: state.topMarkets,
  }));

  return <Pagination listings={listings} currentRange={currentRange} onUpdateRange={updateRange} />;
});
