import * as React from 'react';
import { Box, Flex, Text } from '@awning/components';

export const CalculatorSection: React.FC<
  React.PropsWithChildren<{
    title: string;
    value?: any;
  }>
> = ({ title, value, children }) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.300',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          text: 'lg',
          paddingX: 4,
          paddingY: 3,
          cursor: 'pointer',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'space-between',
            width: '100%',
            marginRight: 4,
          }}
        >
          <Text>{title}</Text>
          <Text sx={{ fontWeight: 'bold' }}>{value}</Text>
        </Flex>
      </Flex>
      <Flex
        sx={{
          borderTop: '1px solid',
          borderColor: 'gray.300',
          paddingX: 4,
          paddingY: 6,
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        {children}
      </Flex>
    </Box>
  );
};
