import * as React from 'react';
import { Flex, Text, Tooltip } from '@awning/components';
import { EXPLAINERS, ExplanationTooltip } from '@/src/explainers';
import { toPercentString, toRoundedNumberString, toUSD } from '@/src/utils';
import { useCalculatorStore } from './calculatorStore';
import { contentPaddingX } from './shared';

export const RevenueHighlights = () => {
  const result = useCalculatorStore(state => state.getActiveTabResultValues());
  const { capRate, irr } = result;
  const monthly = result.netCashFlow ? result.netCashFlow[0] / 12 : 0;

  return (
    <Flex sx={{ flexDirection: 'column', ...contentPaddingX, marginTop: 5 }}>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Text sx={{ text: 'base', color: 'gray.600' }}>Monthly cash flow</Text>
        <Flex sx={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
          <Text sx={{ color: monthly === 0 ? 'gray.700' : monthly < 0.0 ? 'red.600' : '#20954E' }}>
            {toUSD(monthly)}
          </Text>
        </Flex>
      </Flex>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Text sx={{ text: 'base', color: 'gray.600' }}>
          <Tooltip placement="bottom" content={<ExplanationTooltip {...EXPLAINERS.CAP_RATE} />}>
            Cap Rate
          </Tooltip>
        </Text>
        <Flex sx={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
          <Text sx={{ color: monthly === 0 ? 'gray.700' : monthly < 0.0 ? 'red.600' : '#20954E' }}>
            {toPercentString(capRate, 1)}
          </Text>
        </Flex>
      </Flex>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Text sx={{ text: 'base', color: 'gray.600' }}>
          <Tooltip
            placement="bottom"
            content={<ExplanationTooltip {...EXPLAINERS.TEN_YEAR_RETURN} />}
          >
            10-year return
          </Tooltip>
        </Text>
        <Flex sx={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
          <Text sx={{ color: monthly === 0 ? 'gray.700' : monthly < 0.0 ? 'red.600' : '#20954E' }}>
            {toRoundedNumberString(irr, 1)}%
          </Text>
          <Text sx={{ text: 'sm' }}>&nbsp;/yr</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
