import * as React from 'react';
import { SelectRangeFilter } from '../SelectRangeFilter';
import range from 'lodash/range';
import { useEstimatorFilterStore } from '../store';
import { toLocaleUS } from '@/src/utils';

const maxGuestsRange = range(1, 17, 1).map(value => ({ value }));

export const GuestsFilter = () => {
  const { maxGuests, update } = useEstimatorFilterStore(state => ({
    maxGuests: state.filterState.maxGuests,
    update: state.updateFilter,
  }));

  return (
    <SelectRangeFilter
      name="MaxGuests"
      minValues={maxGuestsRange}
      maxValues={maxGuestsRange}
      minValue={maxGuests.min ?? ''}
      maxValue={maxGuests.max ?? ''}
      labelFn={toLocaleUS as any}
      emptyLabelFn={() => 'Any'}
      onChange={(t, value) => update('maxGuests', `${t}`, value)}
    />
  );
};
