import { MARKER_Z_INDEX, MarkerOverlay } from './MarkerOverlay';

export class EstimatorMarkerOverlay extends MarkerOverlay {
  draw() {
    super.draw();

    const defaultMarker = this._customMarkerDiv;
    const numberTag = this.div.getElementsByClassName('position-index-tag')[0] as HTMLElement;

    if (typeof this.index !== 'undefined' && !isNaN(this.index) && this.index < 5) {
      defaultMarker.style.zIndex = MARKER_Z_INDEX.IS_NUMBERED;
      numberTag.innerHTML = `${this.index + 1}`;
      numberTag.style.zIndex = MARKER_Z_INDEX.IS_NUMBERED;
      numberTag.style.display = 'inline-flex';
    }
    defaultMarker.style.display = 'flex';
  }
}
