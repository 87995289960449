import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Flex, LinkButton } from '@awning/components';
import { publicUrl } from '@/src/publicUrl';
import useModal from '@/src/useModal';
import { useOnClickOutside } from '@/src/useOnClickOutside';
import { ReactComponent as ChevronRight } from '@awning/components/assets/icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';
import { ReactComponent as NotEnoughDataIcon } from './not-enough-data.svg';

// only for the estimator and the search page
export const getAddress = (query: any, asPath: any) => {
  let address = [];
  if (asPath.includes('estimator')) {
    // 1220 Arden Hills Lane, Sacramento, CA, USA
    const a = query.address.split(',').map((v: string) => v.trim());
    address = [a[1], a[2]];
  } else if (asPath.includes('search')) {
    address = [query.q, query.queryState];
  } else {
    address = query.split('--');
  }
  return address;
};

export const GotoMarketInsights = ({
  fallback = false,
  address,
}: {
  fallback?: boolean;
  address: [string, string];
}) => {
  const [linkPath, setLinkPath] = useState('');

  const getHasAirbnbData = async (address: string[]) => {
    if (address.filter(Boolean).length !== 2) return;

    const [place, stateAndZip] = address;
    const state = stateAndZip.split(' ')?.[0];
    const geoId = [place, state].join('--');

    try {
      const resp = await axios.get(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/get-has-airbnb-data`, {
        params: { geoId },
      });
      if (resp?.data?.exists) setLinkPath([place, state].join('-').replace(/\s/, '-'));
    } catch (e) {
      console.log(e);
      setLinkPath('');
    }
  };

  useEffect(() => {
    getHasAirbnbData(address);
  }, [address]);

  const { Modal, isShow, show, hide } = useModal('market-data-message');

  const ref = useRef<HTMLDivElement | undefined>(undefined);
  useOnClickOutside([ref], hide, [], !!isShow);

  return (
    <Box sx={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
      <Flex sx={{ alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            paddingY: 1,
            paddingX: 2,
            borderRadius: '2px',
            backgroundColor: '#E8F0FE',
            color: '#185ABC',
            fontSize: '8px',
          }}
        >
          NEW
        </Box>
        <Flex
          as="a"
          href={linkPath ? publicUrl(`/airbnb-market-data/${linkPath}`) : '#'}
          alt="Market Data"
          onClick={(e: any) => {
            if (linkPath) {
              return true;
            }
            e.preventDefault();
            show();
          }}
          sx={{
            fontSize: '12px',
            alignItems: 'center',
            gap: 1,
          }}
        >
          Market Data
          <Box as={ChevronRight} sx={{ height: '16px', width: '16px' }} />
        </Flex>
      </Flex>
      <Modal>
        <Flex
          ref={ref}
          sx={{
            position: 'relative',
            backgroundColor: 'white',
            boxShadow: 'lg',
            borderRadius: 'md',
            flexDirection: 'column',
            minWidth: '200px',
            maxWidth: '400px',
          }}
        >
          <Box
            sx={{
              paddingX: 6,
              paddingY: 5,
              borderBottom: '1px solid',
              borderColor: 'gray.300',
              textAlign: 'center',
            }}
          >
            <Box sx={{ fontWeight: 'bold', text: 'sm' }}>Airbnb Market Data</Box>
            <Box
              as={CloseIcon}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: 6,
                top: 5,
                height: '16px',
                width: '16px',
              }}
              onClick={hide}
            />
          </Box>
          <Box
            sx={{
              paddingX: 6,
              paddingY: 5,
            }}
          >
            <Flex
              sx={{ gap: 4, textAlign: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <Box as={NotEnoughDataIcon} />
              <Flex sx={{ gap: 2, flexDirection: 'column' }}>
                <p>
                  Market data cannot be shown because the search area is not a zip code or city or
                  is unavailable.
                </p>
                <p>
                  Try searching for a specific market you are interested using the button below.
                </p>
              </Flex>

              <LinkButton
                href="https://awning.com/airbnb-market-data"
                target="_blank"
                rel="noreferrer"
                sx={{ marginY: 2, width: '100%' }}
              >
                Airbnb Market Data
              </LinkButton>
            </Flex>
          </Box>
        </Flex>
      </Modal>
    </Box>
  );
};
