import { Box, Divider, Text } from '@awning/components';
import { toAbbrUSD } from '../utils';
import { Breakdown } from './Breakdown';
import { CONTAINER_MARGIN_LEFT } from './common';
import { Estimates } from './Estimates';
import { NivoLineChart, buildGraphData, buildTableData } from './Charts';
import type { TRates } from './Charts/types';
import { useMarketInsightsStore } from './store';
import { RatesTabSummary } from './RatesTabSummary';
import { NotEnoughDataBanner } from './NotEnoughDataBanner';
import { MaskedData } from '../MaskedData/MaskedData';
import { MaskedChart } from '../MaskedChart/MaskedChart';

export const RatesTab = ({ data }: { data: TRates }) => {
  const q = useMarketInsightsStore(state => state.q);
  const { entireHome, privateRoom, sharedRoom, tableBreakdown, graphBreakdown } = data;
  const graphData = buildGraphData(graphBreakdown);
  const tableData = buildTableData(tableBreakdown, (v: number) => (
    <MaskedData>{toAbbrUSD(v)}</MaskedData>
  ));

  const location = q.replace(/--/g, ', ');
  return (
    <>
      <Box
        sx={{
          marginY: 6,
          marginLeft: CONTAINER_MARGIN_LEFT,
        }}
      >
        <RatesTabSummary />
        <Estimates
          title={`Airbnb Average Daily Rates in ${location}`}
          groupValues={[
            [
              { name: { short: 'Entire Home', long: 'Entire Home' }, value: toAbbrUSD(entireHome) },
              {
                name: { short: 'Private Room', long: 'Private Room' },
                value: toAbbrUSD(privateRoom),
              },
              { name: { short: 'Shared Room', long: 'Shared Room' }, value: toAbbrUSD(sharedRoom) },
            ],
          ]}
        />
      </Box>
      <Divider sx={{ height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box
        sx={{
          marginLeft: CONTAINER_MARGIN_LEFT,
          marginY: 6,
          overflowY: 'hidden',
        }}
      >
        <NotEnoughDataBanner />
        <Text sx={{ text: 'sm', fontWeight: 'bold' }}>Monthly ADR Breakdown by Bedroom</Text>
        <MaskedChart type="line">
          <NivoLineChart data={graphData} minGraphHeight={250} />
        </MaskedChart>
      </Box>
      <Divider sx={{ height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box
        sx={{
          marginY: 6,
          marginLeft: CONTAINER_MARGIN_LEFT,
          marginRight: { base: 0, lg: 4 },
        }}
      >
        <Breakdown
          title={{
            mainTitle: 'ADR Breakdown by Bedroom',
            subTitle: '(last 12 months)',
          }}
          data={tableData}
        />
      </Box>
    </>
  );
};
