import * as React from 'react';
import { useEstimatorStore } from '@/src/estimator';
import { Pagination } from './Pagination';

export const EstimatorPagination = React.memo(() => {
  const { currentRange, updateRange, listings } = useEstimatorStore(state => ({
    currentRange: state.range,
    updateRange: state.updateRange,
    listings: state.listings,
  }));
  return <Pagination listings={listings} currentRange={currentRange} onUpdateRange={updateRange} />;
});
