import * as React from 'react';
import Image from 'next/future/image';
import { Box, Flex } from '@awning/components';
import { useMapViewStore } from '@/src/MapView';
import { shallow } from '@awning/archie';
import { toAbbrUSD, toPercentString } from '@/src/utils';
import { ListingTopMarketCardMarkerOverlay } from '@/src/MapView/Overlays/ListingTopMarketCardMarkerOverlay';
import { TTopMarket } from '@/src/top-airbnb-markets';
import { publicUrl } from '@/src/publicUrl';
import { SignUpToViewCardOverlayMarker } from './SignUpToViewCardOverlayMarker';

export const ListingTopMarketCardOverlayMarker = () => {
  const listingCardElemRef = React.useRef();
  const googleListingCardOverlay = React.useRef<ListingTopMarketCardMarkerOverlay>();

  const { map, selectedMarker, markerCollection } = useMapViewStore(
    state => ({
      map: state.map,
      selectedMarker: state.selectedMarker,
      markerCollection: state.markerCollection,
    }),
    shallow
  );

  const fontFamily = `"Benton Sans", "Helvetica Neue", "Arial", sans-serif`;

  React.useEffect(() => {
    if (googleListingCardOverlay.current) {
      // reset so the carousel empties out
      googleListingCardOverlay.current.overlay.setMap(null);
      googleListingCardOverlay.current = undefined;
    }

    if (selectedMarker === -1 || !map || markerCollection.overlays.length === 0) return;

    const overlay = markerCollection.getOverlayAt(selectedMarker);
    const coordinates = overlay?.getPosition()!;

    const listingCard = new ListingTopMarketCardMarkerOverlay(
      coordinates,
      listingCardElemRef.current!
    );
    listingCard.overlay.setMap(map);
    googleListingCardOverlay.current = listingCard;
  }, [selectedMarker]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      ref={listingCardElemRef}
      className="map-listing-card"
      sx={{
        fontFamily,
        maxHeight: '120px',
        width: '280px',
        minWidth: '280px',
        maxWidth: '280px',
        background: 'white',
        overflow: 'hidden',
        boxShadow: 'lg',
        borderRadius: 'md',
      }}
    >
      {selectedMarker !== -1 && (
        <SignUpToViewCardOverlayMarker>
          <SmallListingCard listing={markerCollection.getOverlayAt(selectedMarker).data!} />
        </SignUpToViewCardOverlayMarker>
      )}
    </Box>
  );
};

const SmallListingCard = ({ listing }: { listing: TTopMarket }) => {
  const { medianRoi, medianYearlyRevenue, medianHomeValuation, city, state } = listing;

  const pictureUrl = listing.imageUrl
    ? listing.imageUrl
    : `${process.env.NEXT_PUBLIC_BASE_PATH}/no-image-fallback.png`;
  const listingUrl = publicUrl(`airbnb-market-data/${city}-${state}`);

  return (
    <Flex
      as="a"
      href={listingUrl}
      target="_blank"
      rel="noreferrer"
      sx={{
        padding: 2,
        gap: 2,
        textDecoration: 'none !important',
        fontWeight: 'normal',
        text: 'xs',
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          flexDirection: 'column',
          height: '100px',
          width: '100px',
          borderRadius: 'md',
          overflow: 'hidden',
          flexShrink: 0,
        }}
      >
        <Box
          as={Image}
          sx={{
            height: '100px',
            width: '100px',
            objectFit: 'cover',
          }}
          src={pictureUrl}
          height="100"
          width="100"
          quality={60}
        />
        {listing.imageCitation && (
          <Box
            className="citation"
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: '#2E2D2D',
              color: '#fff',
              zIndex: 9,
              whiteSpace: 'nowrap',
              opacity: '0.5',
              fontSize: '10px',
              paddingX: 2,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {listing.imageCitation}
          </Box>
        )}
      </Flex>
      <Flex sx={{ width: '100%', gap: 1, flexDirection: 'column', overflow: 'hidden', text: 'xs' }}>
        <Flex sx={{ fontWeight: 'bold' }}>
          {city}, {state}
        </Flex>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ color: 'gray.500' }}>ROI *</Box>
          <Box
            sx={{
              background: 'linear-gradient(78.6deg, #4CE689 15.81%, #20954E 85.67%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              textAlign: 'right',
            }}
          >
            +{toPercentString(medianRoi, 0)}
          </Box>
        </Flex>

        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ color: 'gray.500' }}>Revenue</Box>
          <Box sx={{ textAlign: 'right' }}>{toAbbrUSD(medianYearlyRevenue)}</Box>
        </Flex>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ color: 'gray.500' }}>Avg. Airbnb price</Box>
          <Box sx={{ textAlign: 'right' }}>{toAbbrUSD(medianHomeValuation)}</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
