import { ReactElement } from 'react';
import { useMapViewStore } from '@/src/MapView';
import { shallow } from '@awning/archie';
import { Box, Flex } from '@awning/components';
import { ReactComponent as MapIcon } from '@awning/components/assets/icons/map.svg';
import { ReactComponent as ListIcon } from '@awning/components/assets/icons/list.svg';

export const ShowHideMapButton = ({
  showMapLabel = 'Map',
  hideMapLabel = 'List',
  ShowMapIcon = MapIcon,
  HideMapIcon = ListIcon,
}: {
  showMapLabel?: string;
  hideMapLabel?: string;
  ShowMapIcon?: ReactElement;
  HideMapIcon?: ReactElement;
}) => {
  const { isFullView, toggleFullView } = useMapViewStore(
    state => ({
      isFullView: state.isFullView,
      toggleFullView: state.toggleFullView,
    }),
    shallow
  );

  return (
    <Flex
      sx={{
        display: {
          base: 'block',
          lg: 'none',
        },
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        text: 'sm',
        cursor: 'pointer !important',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Flex
        sx={{
          paddingY: 2,
          paddingX: 3,
          boxShadow: 'md',
          borderRadius: 'full',
          background: 'black',
          overflow: 'hidden',
          color: '#fff',
        }}
        onClick={toggleFullView}
      >
        <Box as={!isFullView ? ShowMapIcon : HideMapIcon} height="24px" width="24px" />
        <Box sx={{ marginLeft: 1 }}>{!isFullView ? showMapLabel : hideMapLabel}</Box>
      </Flex>
    </Flex>
  );
};
