import { memo } from 'react';
import { Box } from '@awning/components';
import { getLongStateName, isMultipleStatesSelected, isSingleStateSelected } from './utils';
import { TTopMarketFilterState, useFilterStore } from '@/src/Filters';

export const Heading = memo(() => {
  let title = 'Top Airbnb Markets';
  let subtitle = 'Awning’s ranking of the top Airbnb markets in the United States.';

  const statesSelected = useFilterStore<TTopMarketFilterState>(
    state => state?.filterState?.states?.values
  );

  if (isMultipleStatesSelected(statesSelected)) {
    const getTitle = (states: any[]) => {
      if (states.length === 0) return '';
      if (states.length >= 4) {
        return `${states.slice(0, 4).map(getLongStateName).join(', ')}...`;
      } else {
        return states.map(getLongStateName).join(', ');
      }
    };

    const allYourStatesAreBelongToUs = getTitle(statesSelected);
    title = `Top Airbnb Markets in ${allYourStatesAreBelongToUs}`;
    subtitle = `Awning's ranking of the best places for Airbnb investing.`;
  }

  if (isSingleStateSelected(statesSelected)) {
    const shortStateName = statesSelected?.[0].toUpperCase();
    const longStateName = getLongStateName(shortStateName);
    title = `Top Airbnb Markets in ${longStateName}`;
    subtitle = `Awning's ranking of the best places for Airbnb investing in ${longStateName}.`;
  }

  return (
    <Box sx={{ marginTop: 4, marginBottom: 1 }}>
      <Box sx={{ fontWeight: 'bold', fontSize: { lg: 'lg' } }}>{title}</Box>
      <Box sx={{ text: 'sm', marginTop: 2 }}>{subtitle}</Box>
    </Box>
  );
});
