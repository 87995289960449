import * as React from 'react';
import { BaseLink } from '@/src/BaseLink';
import { AwningLogo, Box, Flex, Text } from '@awning/components';
import { ReactComponent as AverageMonthlyRentFormula } from './assets/AverageMonthlyRent.svg';
import { ReactComponent as CapRateFormula } from './assets/CapRate.svg';
import { ReactComponent as CashOnCashReturn } from './assets/CashOnCashReturn.svg';
import { ReactComponent as DepreciationDeduction } from './assets/DepreciationDeduction.svg';
import { ReactComponent as ExpenseRatio } from './assets/ExpenseRatio.svg';
import { ReactComponent as LTVRatio } from './assets/LTVRatio.svg';
import { ReactComponent as NetOperatingIncome } from './assets/NetOperatingIncome.svg';
import { ReactComponent as RenovationValueIncreaseFormula } from './assets/RenovationValueIncrease.svg';
import { ReactComponent as TenYearReturnFormula } from './assets/TenYearReturn.svg';
import { ReactComponent as TotalCashInvested } from './assets/TotalCashInvested.svg';
import { ReactComponent as TotalDeductions } from './assets/TotalDeductions.svg';
import { ReactComponent as VacancyRateFormula } from './assets/VacancyRate.svg';
import { ReactComponent as YearOneCashOnCashFormula } from './assets/YearOneCashOnCash.svg';

export type TExplainerValue = {
  title: string;
  description: string;
  formula: React.ReactNode | null;
  methodology: React.ReactNode | null;
  links: Array<{ title: string; link: string }> | null;
};

export const EXPLAINERS: Record<string, TExplainerValue> = {
  AIRBNB_FEE: {
    title: 'Airbnb Fee',
    description: "Airbnb charges 3% of a host's revenue for credit card processing.",
    formula: null,
    methodology: null,
    links: null,
  },
  INTERNET_CABLE: {
    title: 'Internet/Cable',
    description: `The cost of providing internet and entertainment services to customers, including cable or subscriptions.
    Awning Methodology: Typically, this expense comes in at around $100/month.`,
    formula: null,
    methodology: null,
    links: null,
  },
  LAWN_CARE: {
    title: 'Lawn Care',
    description: `The estimated monthly cost to maintain a home's lawn.
    Awning Methodology: For homes with lawns, this is generally around $200/month. For condo's or homes without grass, this cost is $0.`,
    formula: null,
    methodology: null,
    links: null,
  },
  SUPPLIES_COST: {
    title: 'Supplies Cost',
    description: `The estimated cost to stock the home with supplies monthly. Supplies include soap, toilet paper, paper towels, coffee, and other essentials.
    Awning Methodology: We estimate supplies generally cost about 2% of the revenue generated by the home, based on data from Airbnb owners around the United States.`,
    formula: null,
    methodology: null,
    links: null,
  },
  POOL_MAINTENANCE: {
    title: 'Pool & Hot Tub Maintenance',
    description: `The cost of maintaining a pool or hot tub on a property.
    Awning Methodology: Typically, pools cost about $250/month to clean and maintain and hot tubs cost about $200/month to clean and maintain. They're generally positive ROI investments as they increase revenue well beyond their expense in markets in which they are desirable amenities.`,
    formula: null,
    methodology: null,
    links: null,
  },
  APPRECIATION: {
    title: 'Appreciation',
    description: "The rate at which the property's value grows over time.",
    formula: null,
    methodology:
      'Awning estimates appreciation using a machine learning model trained on a number of inputs like population and income estimates and forecasts from the us government, schools, local amenities, and historical appreciation. No one can forecast appreciation with 100% certainty, so we recommend you chat with your advisor to figure out what markets might be best for your risk / reward profile.',
    links: null,
  },
  APPRECIATION_RATE: {
    title: 'Appreciation Rate',
    description: "This is the rate at which a property's value grows over time.",
    formula: null,
    methodology: null,
    links: null,
  },
  AVERAGE_MONTHLY_RENT: {
    title: 'Monthly Cash Flow',
    description:
      'The amount of cash you take home on average every month, after expenses, taxes, and mortgage payments, during your first year. This includes one-off expenses like repairs and recurring expenses like property management fees and taxes.',
    formula: <Box as={AverageMonthlyRentFormula} width="325" height="65" />,
    methodology: null,
    links: null,
  },
  AWNING_MARKET_ESTIMATE: {
    title: 'Awning Market Estimate',
    description:
      "Awning's algorithms use real rent data to estimate the rent on a property. Experts on our team review these estimates to ensure their accuracy.",
    formula: null,
    methodology: null,
    links: null,
  },
  CAP_RATE: {
    title: 'Entry Capitalization Rate',
    description:
      'A rate of return that compares the net operating income (NOI) to the purchase price plus any renovation and furnishing costs.',
    formula: <Box as={CapRateFormula} />,
    methodology: null,
    links: null,
  },
  CASH_AVAILABLE: {
    title: 'Cash Available',
    description: 'This would be your down payment if using a mortgage.',
    formula: null,
    methodology: null,
    links: null,
  },
  CASH_FLOW: {
    title: 'Cash Flow',
    description: 'The amount of cash profit or loss you expect to make up-to that year.',
    formula: null,
    methodology: null,
    links: null,
  },
  CASH_ON_CASH_RETURN: {
    title: 'Cash-on-Cash Return',
    description:
      'The Cash-on-Cash Return is a ratio of the net cash flow the property generates and the total amount of money you initially invested in the property, out-of-pocket.',
    formula: <Box as={CashOnCashReturn} />,
    methodology: null,
    links: null,
  },
  CONTRACT_SERVICES: {
    title: 'Contract Services',
    description:
      'A placeholder for any contracted services you use. Generally this is $0, but some investors like to pay for advice, special accountants, or other services for their properties outside of property management.',
    formula: null,
    methodology: null,
    links: null,
  },
  DEBT_SERVICE: {
    title: 'Debt Service',
    description: 'The total amount of money owed for your mortgage, during that year.',
    formula: null,
    methodology: null,
    links: null,
  },
  DEPRECIATION: {
    title: 'Depreciation Deduction',
    description:
      'You can usually deduct the depreciation of a building for the rental property. The standard approach to depreciation is depreciating the value of the building (not the land) evenly over the course of 27.5 years. There are cases in which you can depreciate parts of a property faster to receive a greater upfront benefit. Consult your tax advisor to verify the numbers on this deduction for you.',
    formula: <Box as={DepreciationDeduction} />,
    methodology: null,
    links: null,
  },
  DOWN_PAYMENT: {
    title: 'Down Payment',
    description:
      "This is the total amount you're paying upfront for the property. The number is 100% if you're paying all cash, and usually 20% or more if you're paying with a mortgage.",
    formula: null,
    methodology: null,
    links: null,
  },
  EQUITY: {
    title: 'Equity',
    description:
      "The amount in dollars you've paid up of your mortgage by renting your property, up-to that year. The amount of equity earned, including the down payment, up-to that year.",
    formula: null,
    methodology: null,
    links: null,
  },
  EXPENSE_GROWTH: {
    title: 'Expense Growth',
    description:
      'The rate at which you expect expenses to grow, annually. This is usually around the lesser of the inflation rate and local rent growth.',
    formula: null,
    methodology: null,
    links: null,
  },
  EXPENSE_RATIO: {
    title: 'Expense Ratio',
    description: 'A ratio between the operating expenses and operating income of a property.',
    formula: <Box as={ExpenseRatio} />,
    methodology: null,
    links: null,
  },
  EXPENSES: {
    title: 'Expenses',
    description: 'The total amount of expenses during that year.',
    formula: null,
    methodology: null,
    links: null,
  },
  FURNISHING_COST: {
    title: 'Furnishing Cost',
    description: `The estimated cost to furnish the home, including beds, sofas, TVs, amenities like board games, linens, kitchenware, smart lock, and more, with design, install, shipping, and assembly included. Awning Methodology: We do a rough estimate based on the size and features of the home of what it would cost to design, install, ship and assemble the home through our furnishing service. This estimate is generally within 20% of the actual costs, usually closer.`,
    formula: null,
    methodology: null,
    links: null,
  },
  GROSS_REVENUE: {
    title: 'Gross Revenue',
    description:
      'The annual revenue and other income collected, assuming no vacancy. This number grows annually by the Annual Revenue Growth rate.',
    formula: null,
    methodology: null,
    links: null,
  },
  GROSS_RENT: {
    title: 'Gross Rent',
    description:
      'The annual rent and other income collected, assuming no vacancy. This number grows annually by the Rent Growth rate.',
    formula: null,
    methodology: null,
    links: null,
  },
  HOA: {
    title: "Home Owner's Association Dues",
    description:
      "The monthly dues for the home owner's association the property is part of. This is $0 when the property is not part of an HOA.",
    formula: null,
    methodology: 'We get the HOA numbers directly from the listing.',
    links: null,
  },
  INCOME: {
    title: 'Income',
    description: 'The total amount of money coming in, before expenses, during that year.',
    formula: null,
    methodology: null,
    links: null,
  },
  INSURANCE: {
    title: 'Home Insurance',
    description: 'The estimated cost of home insurance.',
    formula: null,
    methodology:
      'Awning estimates the cost of home insurance for you as a rental property investor. We gather estimates from a multitude of sources, including third party rental property insurance sites.',
    links: null,
  },
  INTEREST_RATE: {
    title: 'Interest Rate',
    description: 'The rate of interest on your mortgage.',
    formula: null,
    methodology: 'Awning defaults to a market interest rate.',
    links: null,
  },
  LOAN_BALANCE: {
    title: 'Loan Balance',
    description: 'The amount of equity to be paid off on the loan, up-to that year.',
    formula: null,
    methodology: null,
    links: null,
  },
  LTV_RATIO: {
    title: 'Loan-to-value Ratio',
    description:
      'The loan-to-value ratio is a ratio between the total equity you own in the home (inclusive of appreciation) and the total value of the home (inclusive of appreciation). The loan-to-value ratio increases as a property appreciates and as your rent pays off your mortgage. A typical real estate investment strategy is to let rent and appreciation do their work in decreasing your LTV ratio, enabling you to refinance the property and pull out cash to acquire one or more additional homes.',
    formula: <Box as={LTVRatio} />,
    methodology: null,
    links: null,
  },
  MONTHLY_RENT: {
    title: 'Monthly Rent',
    description:
      'The amount of rental income you expect to generate from the property when occupied during the first year.',
    formula: null,
    methodology:
      "Awning's algorithms use real rent data to estimate the rent on a property. Experts on our team review these estimates to ensure their accuracy.",
    links: null,
  },
  MORTGAGE_BALANCE: {
    title: 'Mortgage Balance',
    description:
      "The amount of equity your lender owns in your home. This number doesn't include the remaining interest on your mortgage. Typically, you can pay off a loan in full or refinance it to pull cash out, without penalty.",
    formula: null,
    methodology: null,
    links: null,
  },
  MORTGAGE_INTEREST: {
    title: 'Mortgage Interest Deduction',
    description:
      "You can usually deduct the amount of money you've paid in interest on your mortgage on a rental property. Consult your tax advisor to verify the eligibility of this deduction for you.",
    formula: null,
    methodology: null,
    links: null,
  },
  MORTGAGE_PAYMENTS: {
    title: 'Mortgage Payments',
    description: 'The amount you pay for your mortgage, if any.',
    formula: null,
    methodology: null,
    links: null,
  },
  NET_CASH_FLOW: {
    title: 'Net Cash Flow',
    description:
      "The net profit or loss after income, debt, and expenses, during that year. This does not consider the equity you've earned or any appreciation.",
    formula: null,
    methodology: null,
    links: null,
  },
  NET_OPERATING_INCOME: {
    title: 'Net Operating Income',
    description: 'The profit you expect to receive on a rental profit, before mortgage payments.',
    formula: <Box as={NetOperatingIncome} />,
    methodology: null,
    links: null,
  },
  OPERATING_EXPENSES: {
    title: 'Operating Expense Deduction',
    description:
      'You can usually deduct from your total income any operating expenses you have in managing a rental property. Consult your tax advisor to verify the eligibility of this deduction for you.',
    formula: null,
    methodology: null,
    links: null,
  },
  OTHER_EXPENSES: {
    title: 'Other Expenses',
    description: 'An area for you to include any non-standard additional annual expenses.',
    formula: null,
    methodology: null,
    links: null,
  },
  PRINCIPAL: {
    title: 'Principal',
    description:
      "The amount in dollars you've paid up of your mortgage by renting your property, up-to that year.",
    formula: null,
    methodology: null,
    links: null,
  },
  PROPERTY_MANAGEMENT_FEE: {
    title: 'Property Management Fee',
    description:
      'The cost of property management. Property managers take care of marketing the property, finding a tenant, and day-to-day maintenance requests & repair coordination. Self management costs about 2% of rent. Traditional property management is typically between 4% to 10% of rent plus leasing fees (50%-100% of rent every 2 to 3 years), which averages to 8% / year. Paying for a technology to help you self-manage and lease usually costs between 4% to 6%.',
    formula: null,
    methodology:
      'We use a standard assumption of 8% which is the cost of most of our property management partners.',
    links: [
      {
        title: "Awning's Guide to Property Management",
        link: 'https://medium.com/@awning/awning-a-guide-to-property-management-362b27f1fb7',
      },
    ],
  },
  PROPERTY_TAX: {
    title: 'Property Tax',
    description: 'The combined local, county, and state property taxes.',
    formula: null,
    methodology:
      'Grow by 2% in CA, due to Prop 13. Grows by the appreciation rate in other markets.',
    links: null,
  },
  PROPERTY_TAX_RATE: {
    title: 'Property Tax Rate',
    description: 'The combined local, city, county, and state property taxes for the listing.',
    formula: null,
    methodology:
      'We manually verify property tax rates for any listing in your feed. We do a double check before placing an offer on your behalf as well, to ensure this key variable is accurate due to how much it affects returns.',
    links: null,
  },
  PROPERTY_VALUE: {
    title: 'Property Value',
    description: 'The total value of the property that year.',
    formula: null,
    methodology: null,
    links: null,
  },
  PURCHASE_PRICE: {
    title: 'Purchase Price',
    description: "The total amount you're purchasing the property for.",
    formula: null,
    methodology: (
      <>
        <BaseLink newTab href={`${process.env.NEXT_PUBLIC_BASE_PATH}/messages`}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          Send a message
        </BaseLink>{' '}
        to your Awning Agent if you'd like help determining a good offer price for the property.
      </>
    ),
    links: null,
  },
  RENOVATION_COST: {
    title: 'Renovation Cost',
    description: 'The amount of money needed to renovate the property before its rented.',
    formula: null,
    methodology:
      "Awning's team manually estimates the renovation costs based on the pictures and age of the property.",
    links: null,
  },
  RENOVATION_VALUE_INCREASE: {
    title: 'After Repair Value',
    description: "The home's value after initial renovations are made.",
    formula: <Box as={RenovationValueIncreaseFormula} width="325" height="11" />,
    methodology:
      "Awning's team manually estimates the value of the home after repairs based on comparable sales nearby.",
    links: null,
  },
  RENT_GROWTH: {
    title: 'Rent Growth',
    description: "The rate at which the property's rent grows, annually.",
    formula: null,
    methodology:
      'Awning estimates rent growth using a machine learning model trained on a number of inputs like population and income estimates and forecasts from the us government, schools, local amenities, and historical rent growth. No one can forecast rent growth with 100% certainty, so we recommend you chat with your advisor to figure out what markets might be best for your risk / reward profile.',
    links: null,
  },
  REPAIRS_AND_MAINTENANCE: {
    title: 'Repairs & Maintenance',
    description: "An estimate of how much you'll spend on repairs & maintenance per year",
    formula: null,
    methodology:
      'We use a standard assumption of 5% of annual rent to be used for repairs & maintenance.',
    links: null,
  },
  TEN_YEAR_RETURN: {
    title: '10 Year Return',
    description:
      'The pre-tax rate at which you expect to profit on an equity annually, calculated using the IRR (Internal Rate of Return) methodology. The rate is inclusive of returns coming from appreciation, equity, and cash flow. We treat the total appreciation and equity as cash flow in the final year when calculating IRR and your initial investment (down payment + renovation + furnishing costs) as a negative cash flow in year 0.',
    formula: <Box as={TenYearReturnFormula} />,
    methodology: null,
    links: null,
  },
  TOTAL_CASH_INVESTED: {
    title: 'Total Cash Invested',
    description:
      'The total amount of cash you invested to purchase the home and get it rent ready.',
    formula: <Box as={TotalCashInvested} />,
    methodology: null,
    links: null,
  },
  TOTAL_DEDUCTIONS: {
    title: 'Annual Deductions',
    description:
      'The total amount of deductions you can take while filing your taxes for the rental property. Consult your tax advisor to verify the eligibility of these deductions for you.',
    formula: <Box as={TotalDeductions} />,
    methodology: null,
    links: null,
  },
  TOTAL_RETURNS: {
    title: 'Total Returns',
    description: "The total amount of profit you've made up-to that year.",
    formula: null,
    methodology: null,
    links: null,
  },
  YEAR_ONE_CASH_ON_CASH: {
    title: 'Year 1 Cash-on-Cash',
    description:
      'The amount of cash flow you earn your first year divided by your initial investment.',
    formula: <Box as={YearOneCashOnCashFormula} width="325" height="48" />,
    methodology: null,
    links: null,
  },
  OTHER_MONTHLY_INCOME: {
    title: 'Other Monthly Income',
    description:
      'Any non-rental income you expect to generate from the property, usually this is $0. Examples of other monthly income include laundry fees or solar leases.',
    formula: null,
    methodology: null,
    links: null,
  },
  UTILITIES: {
    title: 'Utilities',
    description: 'The amount of money you need to spend on water, electricity or other utilities.',
    formula: null,
    methodology: `We use the averages for the market. In many markets, the tenant pays all utilities, so this is $0. For vacation rentals, owners pay all utility bills.`,
    links: null,
  },
  VACANCY_RATE: {
    title: 'Vacancy Rate',
    description: 'The % of the time a property is vacant.',
    formula: <Box as={VacancyRateFormula} />,
    methodology:
      'Awning has different vacancy rates for different areas based on industry standards informed by public REITs and property managers.',
    links: null,
  },
};

export const ExplanationTooltip: React.FC<React.PropsWithChildren<TExplainerValue>> = ({
  title,
  description,
  formula,
  methodology,
  links,
}) => {
  return (
    <Box
      sx={{
        color: 'gray.900',
        fontWeight: 'normal',
        maxWidth: { base: '350px', sm: '450px' },
        overflow: 'hidden',
        padding: { base: 2, sm: 4 },
        textAlign: 'left',
      }}
    >
      <Text sx={{ display: 'block', fontWeight: 'bold' }}>{title.toUpperCase()}</Text>
      <Text sx={{ display: 'block', marginTop: { base: 4, sm: 6 } }}>{description}</Text>
      {formula && <Box sx={{ marginTop: { base: 4, sm: 6 } }}>{formula}</Box>}
      {methodology && (
        <Flex sx={{ flexDirection: 'column', marginTop: { base: 4, sm: 6 } }}>
          <Flex sx={{ alignItems: 'center' }}>
            <AwningLogo width="69px" height="21px" />
            <Text sx={{ marginLeft: 2 }}>METHODOLOGY</Text>
          </Flex>
          <Text sx={{ marginTop: { base: 2, sm: 3 } }}>{methodology}</Text>
        </Flex>
      )}
      {links && (
        <Flex sx={{ marginTop: { base: 4, sm: 6 } }}>
          <Text sx={{ flexShrink: 0, marginRight: 1 }}>Read more:</Text>
          <Flex sx={{ flexDirection: 'column' }}>
            {links.map((l, idx) => (
              <a href={l.link} key={idx} target="_blank" rel="noopener noreferrer">
                {l.title}
              </a>
            ))}
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
