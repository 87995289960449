const Analytics = () => {};

Analytics.track = (event: any, properties: Object = {}, debug: boolean = false) => {
  if (debug) return console.log(`DEBUG - event: ${event}, properties:, ${properties}`);

  if (!window?.analytics?.track) return;

  if (process.env.APP_ENV === 'production' && process.env.NODE_ENV === 'production') {
    window.analytics.track(event, properties);
  }
};

Analytics.identify = (
  id: string,
  properties: { firstName: string; lastName: string; email: string },
  debug: boolean = false
) => {
  if (debug) return console.log(`DEBUG - id: ${id}, properties:, ${properties}`);

  if (!window?.analytics?.identify) return;

  if (process.env.APP_ENV === 'production' && process.env.NODE_ENV === 'production') {
    window.analytics.identify(id, {
      name: `${properties.firstName} ${properties.lastName}`,
      email: properties.email,
    });
  }
};

Analytics.page = (page: string, debug: boolean = false) => {
  if (debug) return console.log(`DEBUG - page: ${page}`);

  if (!window?.analytics?.page) return;

  if (process.env.APP_ENV === 'production' && process.env.NODE_ENV === 'production') {
    window.analytics.page(page);
  }
};

export const EVENTS = {
  CLICKED_DRAFT_OFFER: 'Clicked Draft an Offer',
  CLICKED_HIDE: 'Clicked Hide',
  CLICKED_LIKE: 'Clicked Like',
  CLICKED_PROPERTY_CARD: 'Clicked Property Card',
  CLICKED_SCHEDULE_INTRO_CALL: 'Clicked Schedule Intro Call',
  ESTIMATOR_ANALYZED_PROPERTY: 'Analyzed a property using the Estimator',
  // ESTIMATOR_TOGGLED_COMPARABLE: 'User toggled comparables',
  // ESTIMATOR_VIEWED_LANDING_PAGE: 'Viewed Estimator Landing Page',
  // LOADED_FEED: 'Feed Loaded',
  SIGNED_UP: 'Signed Up',
  UPLOADED_PRE_APPROVAL: 'Uploaded Pre-approval',
  UPLOADED_PROOF_OF_FUNDS: 'Uploaded Proof of Funds',
  VIEWED_PROPERTY_PAGE: 'Viewed Property Page',
  VIEWED_PROPERTY_PAGE_120000: 'Viewed Property Page - 2 minutes',
  VIEWED_PROPERTY_PAGE_30000: 'Viewed Property Page - 30 seconds',
  VIEWED_PROPERTY_PAGE_60000: 'Viewed Property Page - 1 minute',
};

export default Analytics;
