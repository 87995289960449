import * as React from 'react';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { Flex, Text } from '@awning/components';
import { ReactComponent as SearchIcon } from '@awning/components/assets/icons/search.svg';
import { ReactComponent as LocationMarkerIcon } from '@awning/components/assets/icons/location-marker.svg';
import { useHover } from '@/src/useHover';
import type { TAddressResult, TItem, TLocationResult } from '@/src/SearchBox/types';

export type TItemToStringFn<T extends TItem> = (item: T) => string;

const getSubtitle = <T extends TItem>(item: T) => {
  if (!item) {
    return null;
  } else if (item.hasOwnProperty('fieldMap')) {
    return startCase((item as TLocationResult).fieldMap);
  } else if (item.hasOwnProperty('query')) {
    return null;
  } else if ('listing' in item) {
    return `${startCase(toLower((item as TAddressResult).listing.address.city))}, ${
      (item as TAddressResult).listing.address.state
    }, USA`;
  }
  return null;
};

export type TSearchBoxItemProps<T extends TItem> = {
  item: T;
  itemToString: TItemToStringFn<T>;
  itemIndex: number;
  isHighlighted: boolean;
  onHightlight: () => void;
  onSelect: (index: number, item: T) => void;
};
export function SearchBoxItem<T extends TItem>({
  item,
  itemIndex,
  onSelect,
  isHighlighted,
  onHightlight,
  itemToString,
}: TSearchBoxItemProps<T>) {
  const [ref, isHovered] = useHover();

  // do not add the onHightlight dependency
  React.useEffect(() => {
    if (isHovered) onHightlight();
  }, [isHovered]); // eslint-disable-line

  return (
    <Flex
      ref={ref}
      sx={{
        backgroundColor: isHighlighted ? 'blue.100' : 'rgba(255, 255, 255, 0)', // Transparent
        width: '100%',
        color: 'gray.900',
        padding: 3,
        cursor: 'pointer',
        alignItems: 'center',
      }}
      onClick={(e: any) => {
        e.stopPropagation();
        onSelect(itemIndex, item);
      }}
    >
      <Flex
        as={item.hasOwnProperty('query') ? SearchIcon : LocationMarkerIcon}
        sx={{
          marginRight: 3,
          stroke: 'gray.600',
          width: 12,
          height: 12,
          padding: 4,
          backgroundColor: 'gray.100',
          borderRadius: 'lg',
        }}
      />
      <Flex sx={{ flexDirection: 'column' }}>
        <Text sx={{ text: 'sm' }}>{itemToString(item)}</Text>
        {getSubtitle(item) && (
          <Text sx={{ color: 'gray.500', text: 'xs' }}>{getSubtitle(item)}</Text>
        )}
      </Flex>
    </Flex>
  );
}
