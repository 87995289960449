import { Box, Divider, Text } from '@awning/components';
import { toPercentString } from '../utils';
import { Breakdown } from './Breakdown';
import { CONTAINER_MARGIN_LEFT } from './common';
import { Estimates } from './Estimates';
import { NivoLineChart, buildGraphData, buildTableData } from './Charts';
import type { TOccupancy } from './Charts';
import { useMarketInsightsStore } from './store';
import { OccupancyTabSummary } from './OccupancyTabSummary';
import { NotEnoughDataBanner } from './NotEnoughDataBanner';
import { MaskedData } from '../MaskedData/MaskedData';
import { MaskedChart } from '../MaskedChart/MaskedChart';

export const OccupancyTab = ({ data }: { data: TOccupancy }) => {
  const q = useMarketInsightsStore(state => state.q);
  const { entireHome, privateRoom, sharedRoom, tableBreakdown, graphBreakdown } = data;

  const graphData = buildGraphData(graphBreakdown);
  const tableData = buildTableData(tableBreakdown, (v: number) => (
    <MaskedData>{toPercentString(v, 0)}</MaskedData>
  ));

  const location = q.replace(/--/g, ', ');
  return (
    <>
      <Box sx={{ marginY: 6, marginLeft: CONTAINER_MARGIN_LEFT }}>
        <OccupancyTabSummary />
        <Estimates
          title={`Average Airbnb Occupancy in ${location}`}
          groupValues={[
            [
              {
                name: { short: 'Entire Home', long: 'Entire Home' },
                value: toPercentString(entireHome, 0),
              },
              {
                name: { short: 'Private Room', long: 'Private Room' },
                value: toPercentString(privateRoom, 0),
              },
              {
                name: { short: 'Shared Room', long: 'Shared Room' },
                value: toPercentString(sharedRoom, 0),
              },
            ],
          ]}
        />
      </Box>
      <Divider sx={{ height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box
        sx={{
          marginLeft: CONTAINER_MARGIN_LEFT,
          marginY: 6,
          overflowY: 'hidden',
        }}
      >
        <NotEnoughDataBanner />
        <Text sx={{ text: 'sm', fontWeight: 'bold' }}>Monthly Occupancy Breakdown by Bedroom</Text>
        <MaskedChart type="line">
          <NivoLineChart
            data={graphData}
            minGraphHeight={250}
            yFormat={value => toPercentString(value as number, 0)}
          />
        </MaskedChart>
      </Box>
      <Divider sx={{ height: 2, width: '100%', backgroundColor: 'gray.100' }} />
      <Box
        sx={{
          marginY: 6,
          marginLeft: CONTAINER_MARGIN_LEFT,
          marginRight: { base: 0, lg: 4 },
        }}
      >
        <Breakdown
          title={{
            mainTitle: 'Occupancy Breakdown by Bedroom',
            subTitle: '(last 12 months)',
          }}
          data={tableData}
        />
      </Box>
    </>
  );
};
