import * as React from 'react';
import { Box, Container, Flex, theme } from '@awning/components';
import { useMapViewStore } from '@/src/MapView';
import { ShowHideMapButton } from './ShowHideMapButton';
import { ReactComponent as InsightsIcon } from './insights-icon.svg';

export const MapAndListingsContainerView = ({
  paddingLeft = 0,
  maxWidth,
  renderListings,
  renderMapView,
}: any) => {
  const isFullView = useMapViewStore(state => state.isFullView);
  const _renderListings = React.useCallback(() => renderListings(), []); // eslint-disable-line react-hooks/exhaustive-deps
  const _renderMapView = React.useCallback(() => renderMapView(), []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Container
      sx={{
        paddingX: 4,
        [`@media (min-width: ${theme.breakpoints[2]})`]: {
          width: '100%',
          maxWidth: '100%',
          px: 0,
          py: 0,
        },
      }}
    >
      <Flex>
        <Box
          sx={{
            [`@media (min-width: ${theme.breakpoints[2]})`]: {
              transition:
                'opacity 1000ms cubic-bezier(0.25, 1, 0.5, 1) 0s, visibility 100ms cubic-bezier(0.25, 1, 0.5, 1) 0s, width 500ms cubic-bezier(0.25, 1, 0.5, 1) 0s',
              width: isFullView ? '0px' : '100%',
              visibility: isFullView ? 'hidden' : 'visible',
              opacity: isFullView ? 0 : 1,
              pl: isFullView ? 0 : paddingLeft,
              contain: 'layout paint',
              maxWidth,
            },
            height: '100%',
            width: '100%',
            overflowX: 'hidden',
          }}
        >
          <RenderedListingsResult renderListings={_renderListings} />
          <Flex
            sx={{
              position: 'absolute',
              bottom: 5,
              left: '50%',
              transform: 'translate(-50%)',
            }}
          >
            <ShowHideMapButton
              showMapLabel="Map"
              hideMapLabel="Insights"
              HideMapIcon={InsightsIcon}
            />
          </Flex>
        </Box>

        <Box
          sx={{
            transition: 'top 500ms cubic-bezier(0.25, 1, 0.5, 1) 0s',
            contain: 'layout paint',
            position: 'fixed',
            top: isFullView ? 0 : '100%',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
            height: '100vh',
            [`@media (min-width: ${theme.breakpoints[2]})`]: {
              transition: 'width 500ms cubic-bezier(0.25, 1, 0.5, 1) 0s',
              contain: 'layout paint',
              width: '100%',
              position: 'relative',
              zIndex: 0,
              height: 'auto',
            },
          }}
        >
          {_renderMapView()}
          <Flex
            sx={{
              position: 'absolute',
              bottom: { base: 24, lg: 5 },
              left: '50%',
              transform: 'translate(-50%)',
            }}
          >
            <ShowHideMapButton
              showMapLabel="Map"
              hideMapLabel="Insights"
              HideMapIcon={InsightsIcon}
            />
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};

const RenderedListingsResult = React.memo(({ renderListings }: any) => {
  // const listings = useMarketInsightsStore(state => state.listings);
  // const hasListings = listings.length > 0;

  // if (!hasListings) {
  //   return (
  //     <Flex sx={{ flexDirection: 'column' }}>
  //       <Box
  //         sx={{
  //           fontWeight: 'bold',
  //           text: 'lg',
  //           mt: 4,
  //           mb: 2,
  //           gap: 2,
  //           alignItems: 'center',
  //         }}
  //       >
  //         No matching results
  //       </Box>
  //       <Box sx={{ text: 'xs', color: 'gray.500', marginTop: 1 }}>
  //         Try searching another address.
  //       </Box>
  //     </Flex>
  //   );
  // }
  return <>{renderListings()}</>;
});
