import * as React from 'react';
import { Box, Flex, Radio, RadioGroup } from '@awning/components';
import { useEstimatorFilterStore } from './store';
import type { TEstimatorFilterState, TFilterName } from './types';

export const AmenitiesFilters: React.FC<{
  title: string;
  labels: { [key: string]: string };
}> = ({ title, labels }) => {
  const { filterState, update } = useEstimatorFilterStore(state => ({
    filterState: state.filterState,
    update: state.updateFilter,
  }));

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Flex sx={{ justifyContent: 'space-between', marginBottom: 3 }}>
        <Box sx={{ text: 'lg', fontWeight: 'bold' }}>{title}</Box>
        <Box
          sx={{
            minWidth: '50%',
            display: 'grid',
            textAlign: 'center',
            gridTemplateColumns: '1fr 1fr 1fr',
            text: 'xs',
            color: 'gray.500',
            alignItems: 'end',
          }}
        >
          <Box>Either</Box>
          <Box>With</Box>
          <Box>Without</Box>
        </Box>
      </Flex>
      {Object.entries(labels).map(([label, text]) => {
        // @ts-expect-error
        const value = (filterState as unknown as TEstimatorFilterState)[label]?.value;

        return (
          <Flex key={label} sx={{ marginBottom: 2, justifyContent: 'space-between' }}>
            <Box>{text}</Box>
            <RadioGroup
              onChange={value => update(label as TFilterName, 'value', value)}
              value={value}
            >
              <Box
                sx={{
                  display: 'grid',
                  minWidth: '50%',
                  textAlign: 'center',
                  gridTemplateColumns: '1fr 1fr 1fr',
                }}
              >
                <Box>
                  <Radio name={label} value="either" sxIcon={{ mr: 0 }} />
                </Box>
                <Box>
                  <Radio name={label} value="with" sxIcon={{ mr: 0 }} />
                </Box>
                <Box>
                  <Radio name={label} value="without" sxIcon={{ mr: 0 }} />
                </Box>
              </Box>
            </RadioGroup>
          </Flex>
        );
      })}
    </Flex>
  );
};
