import * as React from 'react';
import { SelectRangeFilter } from '../SelectRangeFilter';
import range from 'lodash/range';
import { useEstimatorFilterStore } from '../store';
import { toLocaleUS } from '@/src/utils';

const ratingStarsRange = range(1, 5.5, 0.5).map(value => ({ value }));

export const RatingFilter = () => {
  const { ratingStars, update } = useEstimatorFilterStore(state => ({
    ratingStars: state.filterState.ratingStars,
    update: state.updateFilter,
  }));

  return (
    <SelectRangeFilter
      name="Rating"
      minValues={ratingStarsRange}
      maxValues={ratingStarsRange}
      minValue={ratingStars.min}
      maxValue={ratingStars.max}
      labelFn={toLocaleUS as any}
      emptyLabelFn={() => 'Any'}
      onChange={(t, value) => update('ratingStars', `${t}`, value)}
      // helperText="From 0.0 to 5.0 stars"
    />
  );
};
