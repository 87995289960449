import { memo } from 'react';
import { EMapViewPage, EstimatorMarker, MapView } from '@/src/MapView';
import { MapAndListingsContainerView } from './MapAndListingsContainer';
import { ListingMarketInsightsCardOverlayMarker } from '@/src/MapView/Markers/ListingMarketInsightsCardOverlayMarker';
import { maxListingCardWidth } from './MarketListings/Card';
import { ComparableModal } from './MarketListings/ComparableModal';
import { MoreFilters } from '@/src/Filters/Estimator/MoreFilters';
import { MarketPhoto } from './MarketPhoto';
import { Heading } from './Heading';
import { TabGroup } from './TabGroup';

export const MarketInsights = memo(() => {
  return (
    <>
      <MapAndListingsContainerView
        maxWidth={maxListingCardWidth}
        renderListings={() => {
          return (
            <>
              <MarketPhoto />
              <Heading />
              <TabGroup />
              <ComparableModal isOpen={false} />
            </>
          );
        }}
        renderMapView={() => {
          return (
            <MapView page={EMapViewPage.MARKET_INSIGHTS} activeControls={['SELECT_VIEW_OPTION']}>
              <EstimatorMarker />
              <ListingMarketInsightsCardOverlayMarker />
            </MapView>
          );
        }}
      />
      <MoreFilters />
    </>
  );
});
