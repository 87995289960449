import * as React from 'react';
import { InputNumber } from '@awning/components';
import { InputRangeFilter } from '../InputRangeFilter';
import { useEstimatorFilterStore } from '../store';

export const RadiusFilter = () => {
  const { radiusMiles, update } = useEstimatorFilterStore(state => ({
    radiusMiles: state.filterState.radiusMiles,
    update: state.updateFilter,
  }));

  return (
    <InputRangeFilter
      control={InputNumber}
      name="RadiusMiles"
      right="miles"
      showMin={false}
      hideLabel={true}
      maxValue={radiusMiles.max}
      limits={{
        min: 0.0,
        max: 20.0,
      }}
      onChange={(t, v) => {
        update('radiusMiles', `${t}`, v);
      }}
      helperText="Maximum radius in miles. From 0.1 to 20 miles."
    />
  );
};
