import * as React from 'react';
import { InputRangeFilter } from '../InputRangeFilter';
import { InputCurrency } from '@awning/components';
import { useFilterStore } from '../store';
import { TTopMarketFilterState } from '../types';

export const AirbnbPriceFilter = () => {
  const { avgAirbnbPrice, update } = useFilterStore<TTopMarketFilterState>(state => ({
    avgAirbnbPrice: state.filterState.avgAirbnbPrice,
    update: state.updateFilter,
  }));

  return (
    <InputRangeFilter
      name="Price"
      control={InputCurrency}
      right=""
      minValue={avgAirbnbPrice?.min}
      maxValue={avgAirbnbPrice?.max}
      limits={{
        min: 0,
      }}
      onChange={(t, value) => {
        update('avgAirbnbPrice', `${t}`, value);
      }}
    />
  );
};
