import { useState } from 'react';
import { Box, Flex } from '@awning/components';
import { ReactComponent as CheckMark } from '@awning/components/assets/icons/check.svg';

export type LineLegendsProps = {
  onClick: (id: string) => void;
  hiddenIds: { [key: string]: boolean };
  data: any;
};

export const LineLegends = ({ onClick, hiddenIds, data }: LineLegendsProps) => {
  const [hoveredId, setIsHovered] = useState<null | boolean>();

  return (
    <Box
      sx={{
        display: { base: 'grid', lg: 'flex' },
        gridTemplateColumns: 'repeat(3, auto)',
        gap: 2,
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {data.map((d: any) => {
        return (
          <Flex
            key={d.id}
            sx={{
              alignItems: 'center',
              gap: 1.5,
              padding: 1,
              cursor: 'pointer',
              backgroundColor: hoveredId === d.id ? 'gray.200' : 'white',
            }}
            onMouseEnter={() => setIsHovered(d.id)}
            onMouseLeave={() => setIsHovered(null)}
            onClick={() => onClick(d.id)}
          >
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                background: d.color,
                height: '16px',
                width: '16px',
                color: 'white',
              }}
            >
              {!hiddenIds[d.id] && <Box as={CheckMark} height="12px" width="12px" />}
            </Flex>
            <Box sx={{ text: 'xs' }}>{d.id}</Box>
          </Flex>
        );
      })}
    </Box>
  );
};
