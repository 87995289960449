import * as Calculator from '@awning/calculator';
import { createArchieStoreCreator, createHook } from '@awning/archie';
import type { TListing } from '@/src/typeUserListing';

export type CalculatorTRAFormDataType = ReturnType<typeof getFormCalculatorValues>;

export type CalculatorStoreState = {
  inputs: {
    tra: Calculator.CalculatorParametersType;
  };
  result: {
    tra: Calculator.CalculatorResultType;
  };
  calculateTRA: (formData: Required<CalculatorTRAFormDataType>, listing: TListing) => void;
  getActiveTabInputValues: () => Calculator.CalculatorParametersType;
  getActiveTabResultValues: () => Calculator.CalculatorResultType;
};

export const calculatorStoreCreator = createArchieStoreCreator<CalculatorStoreState>({
  name: 'calculatorStore',
  stateInitializer: (set, get) => ({
    inputs: { tra: {} } as CalculatorStoreState['inputs'],
    result: { tra: {} } as CalculatorStoreState['result'],
    getActiveTabInputValues: () => get().inputs['tra'],
    getActiveTabResultValues: () => get().result['tra'],

    calculateTRA: (formData, listing) => {
      const { financials } = listing;

      const data: Calculator.CalculatorParametersType = {
        appreciation: formData.common.appreciation / 100,
        contractServices: formData.tra.contractServices!,
        downPayment: (formData.tra.downPayment! / 100) * formData.common.purchasePrice,
        expenseGrowth: formData.tra.expenseGrowth! / 100,
        hoa: formData.common.hoa,
        insurance: formData.tra.insurance!,
        interestRate: formData.tra.interestRate!,
        isCalifornia: false, //listing.address.state === 'CA',
        numberOfUnits: financials.unitCount,
        otherExpenses: formData.tra.otherExpenses!,
        propertyManagementRateFee: formData.tra.propertyManagementRateFee! / 100,
        propertyTaxRate: formData.common.propertyTaxRate / 100,
        purchasePrice: formData.common.purchasePrice,
        renovationCost: formData.common.renovationCost,
        renovationValueIncrease: formData.common.renovationValueIncrease,
        rentGrowth: formData.tra.rentGrowth! / 100,
        repairsAndMaintenance: formData.tra.repairsAndMaintenance!,
        utilities: formData.tra.utilities!,

        term: financials.term,
        marginalTaxRate: financials.marginalTaxRate,
        buildingPercentagePropertyValue: financials.improvementsPct,

        airbnbFee: 0,
        furnishingCosts: 0,
        internetCableFeeYearly: 0,
        lawnCareFeeYearly: 0,
        poolMaintenanceYearly: 0,
        suppliesFeeYearly: 0,

        vacancyRate: formData.tra.vacancyRate! / 100,
        yearOneMonthlyRevenue: formData.tra.yearOneMonthlyRent!,
        yearOneOtherMonthlyRevenue: formData.tra.yearOneOtherMonthlyIncome!,
      };

      try {
        const values = Calculator.calculate(data);

        set({
          result: { tra: values } as CalculatorStoreState['result'],
          inputs: { tra: data } as CalculatorStoreState['inputs'],
        });
      } catch (e) {
        console.log(e);
        set({
          result: { tra: {} } as CalculatorStoreState['result'],
          inputs: { tra: data } as CalculatorStoreState['inputs'],
        });
      }
    },
  }),
});

export const useCalculatorStore = createHook(calculatorStoreCreator);

export const getFormTRAValues = (financials: TListing['financials']) => {
  if (!financials) return {};
  const {
    contractServices,
    downPayment,
    expenseGrowth,
    improvementsPct,
    insurancePerUnit,
    interestRate,
    marginalTaxRate,
    monthlyTotalRent,
    otherExpenses,
    propertyManagement,
    rentGrowth,
    repairsMaintenancePerUnit,
    utilitiesPerUnit,
    vacancyRate,
  } = financials;

  return {
    buildingPercentagePropertyValue: improvementsPct * 100,
    contractServices,
    downPayment: downPayment * 100,
    expenseGrowth: expenseGrowth * 100,
    insurance: insurancePerUnit,
    interestRate: interestRate * 100,
    marginalTaxRate: marginalTaxRate * 100,
    otherExpenses,
    propertyManagementRateFee: propertyManagement * 100,
    rentGrowth: rentGrowth * 100,
    repairsAndMaintenance: repairsMaintenancePerUnit,
    utilities: utilitiesPerUnit,
    vacancyRate: vacancyRate * 100,
    yearOneMonthlyRent: monthlyTotalRent,
    yearOneOtherMonthlyIncome: 0,
  };
};

export const getFormCalculatorValues = (
  financials: TListing['financials'],
  listingPrice: TListing['listingPrice']
) => {
  const tra = getFormTRAValues(financials);
  const common = {
    appreciation: financials.appreciation * 100,
    hoa: financials.hoaFee,
    purchasePrice: listingPrice,
    propertyTaxRate: financials.propertyTaxRate * 100,
    renovationCost: financials.renovationCost,
    renovationValueIncrease: financials.renovationValueIncrease,
  };
  return { common, tra };
};
