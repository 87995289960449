import {
  EFilterPages,
  TEstimatorFilterState,
  TFilterName,
  TMarketInsightsFilterState,
  TTopMarketFilterState,
} from './types';
import { format, sub } from 'date-fns';

export const defaultTopMarketFilters: TTopMarketFilterState = {
  states: { values: [] },
  marketSize: { values: [] },
  avgAirbnbPrice: { min: null, max: null },
  sortOrder: { name: 'roi', asc: false },
};

export const defaultEstimatorFilters: TEstimatorFilterState = {
  averageDailyRate: { min: null, max: null },
  petsAllowed: { value: 'either' },
  bedrooms: { min: null, max: null },
  maxGuests: { min: null, max: null },
  hasBackyard: { value: 'either' },
  hasBbq: { value: 'either' },
  hasBeachfront: { value: 'either' },
  hasBikes: { value: 'either' },
  hasDryer: { value: 'either' },
  hasFreeParking: { value: 'either' },
  hasGym: { value: 'either' },
  hasHotTub: { value: 'either' },
  hasKitchen: { value: 'either' },
  hasPingPongTable: { value: 'either' },
  hasPool: { value: 'either' },
  hasPoolTable: { value: 'either' },
  hasWasher: { value: 'either' },
  hasWasherfront: { value: 'either' },
  homeTypes: { values: [] } as TEstimatorFilterState['homeTypes'],
  radiusMiles: { min: null, max: null },
  ratingStars: { min: null, max: null },
  sortOrder: {},
  numberOfReviews: { min: null, max: null },
};

export const defaultMarketInsightFilters: TMarketInsightsFilterState = {
  ...defaultEstimatorFilters,
  sortOrder: {},
};

export const INACTIVE_FILTERS: Record<EFilterPages, TFilterName[]> = {
  [EFilterPages.ESTIMATOR]: ['homeTypes', 'keyword'],
  [EFilterPages.MARKET_INSIGHTS]: ['homeTypes', 'keyword'],
  [EFilterPages.TOP_MARKETS]: ['homeTypes', 'keyword'],
};

export const BASE_MAP_KEY_TRANSFORMS: Partial<
  Record<TFilterName, { name: string; fn?: Function }>
> = {
  appreciation: { name: 'appreciation', fn: (v: any) => parseFloat(v) / 100 },
  bathroomCount: { name: 'bathroomCount', fn: (v: any) => parseFloat(v) },
  bedroomCount: { name: 'bedroomCount', fn: (v: any) => parseFloat(v) },
  capRate: { name: 'capRate', fn: (v: any) => parseFloat(v) / 100 },
  cashOnCash: { name: 'year1Return', fn: (v: any) => parseFloat(v) / 100 },
  daysOnMarket: {
    name: 'listingDate',
    fn: (v: any, _: 'min' | 'max') => {
      if (isNaN(v) || !v || v === 0) return null;
      const today = new Date();
      return format(sub(today, { days: v }), 'yyyy-MM-dd');
    },
  },
  expertReviewed: {
    name: 'expertReviewed',
    fn: (v: any) => (typeof v === 'boolean' && v === true ? v : null),
  },
  // favorites: {
  //   name: 'favorites',
  //   fn: (v: any) => (typeof v === 'boolean' && v === true ? v : null),
  // },
  featured: { name: 'featured', fn: (v: any) => (typeof v === 'boolean' && v === true ? v : null) },
  hoaFee: { name: 'hoaFee', fn: (v: any) => parseFloat(v) },
  homeTypes: { name: 'homeTypes', fn: (v: string[]) => (v.length === 0 ? null : v) },
  lotSqFt: { name: 'lotSqFt', fn: (v: any) => parseFloat(v) },
  monthlyCashFlow: { name: 'monthlyCashFlow', fn: () => null }, // (v: any) => parseFloat(v) },
  monthlyRent: { name: 'monthlyTotalRent', fn: (v: any) => parseFloat(v) },
  monthlyRevenue: { name: 'monthlyRevenue', fn: (v: any) => parseFloat(v) },
  neighborhood: {
    name: 'neighborhoodGrade',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  price: { name: 'listingPrice', fn: (v: any) => parseFloat(v) },
  propertySqFt: { name: 'propertySqFt', fn: (v: any) => parseFloat(v) },
  renovation: { name: 'renovation', fn: (v: any) => parseFloat(v) },
  schools: { name: 'schoolGrade', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  sortOrder: { name: 'sortOrder', fn: (v: any) => v },
  yearBuilt: { name: 'yearBuilt', fn: (v: string) => parseInt(v, 10) },
};

export const ESTIMATOR_MAP_KEY_TRANSFORMS: Partial<
  Record<TFilterName, { name: string; fn?: Function }>
> = {
  averageDailyRate: { name: 'averageDailyRate', fn: (v: any) => parseFloat(v) },
  petsAllowed: {
    name: 'petsAllowed',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  bedrooms: { name: 'bedrooms', fn: (v: any) => parseFloat(v) },
  maxGuests: { name: 'maxGuests', fn: (v: any) => parseFloat(v) },
  hasBackyard: {
    name: 'hasBackyard',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasBbq: { name: 'hasBBq', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasBeachfront: {
    name: 'hasBeachfront',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasBikes: { name: 'hasBikes', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasDryer: { name: 'hasDryer', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasFreeParking: {
    name: 'hasFreeParking',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasGym: { name: 'hasGym', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasHotTub: { name: 'hasHotTub', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasKitchen: { name: 'hasKitchen', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasPingPongTable: {
    name: 'hasPingPongTable',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasPool: { name: 'hasPool', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasPoolTable: {
    name: 'hasPoolTable',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasWasher: { name: 'hasWasher', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasWasherfront: {
    name: 'hasWasherfront',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  homeTypes: { name: 'homeTypes', fn: (v: string[]) => (v.length === 0 ? null : v) },
  radiusMiles: { name: 'radiusMiles', fn: (v: any) => parseFloat(v) },
  ratingStars: { name: 'ratingStars', fn: (v: any) => parseFloat(v) },
  sortOrder: { name: 'sortOrder', fn: (v: any) => v },
  numberOfReviews: { name: 'numberOfReviews', fn: (v: any) => parseFloat(v) },
};

export const MARKET_INSIGHTS_MAP_KEY_TRANSFORMS: Partial<
  Record<TFilterName, { name: string; fn?: Function }>
> = {
  averageDailyRate: { name: 'averageDailyRate', fn: (v: any) => parseFloat(v) },
  petsAllowed: {
    name: 'petsAllowed',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  bedrooms: { name: 'bedrooms', fn: (v: any) => parseFloat(v) },
  maxGuests: { name: 'maxGuests', fn: (v: any) => parseFloat(v) },
  hasBackyard: {
    name: 'hasBackyard',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasBbq: { name: 'hasBBq', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasBeachfront: {
    name: 'hasBeachfront',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasBikes: { name: 'hasBikes', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasDryer: { name: 'hasDryer', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasFreeParking: {
    name: 'hasFreeParking',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasGym: { name: 'hasGym', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasHotTub: { name: 'hasHotTub', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasKitchen: { name: 'hasKitchen', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasPingPongTable: {
    name: 'hasPingPongTable',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasPool: { name: 'hasPool', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasPoolTable: {
    name: 'hasPoolTable',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  hasWasher: { name: 'hasWasher', fn: (v: any) => (!v || v === '' || v === 'either' ? null : v) },
  hasWasherfront: {
    name: 'hasWasherfront',
    fn: (v: any) => (!v || v === '' || v === 'either' ? null : v),
  },
  homeTypes: { name: 'homeTypes', fn: (v: string[]) => (v.length === 0 ? null : v) },
  radiusMiles: { name: 'radiusMiles', fn: (v: any) => parseFloat(v) },
  ratingStars: { name: 'ratingStars', fn: (v: any) => parseFloat(v) },
  sortOrder: { name: 'sortOrder', fn: (v: any) => v },
  numberOfReviews: { name: 'numberOfReviews', fn: (v: any) => parseFloat(v) },
};

export const TOP_MARKET_MAP_KEY_TRANSFORMS: Partial<
  Record<TFilterName, { name: string; fn?: Function }>
> = {
  states: { name: 'state', fn: (v: any) => (v.filter(Boolean).length > 0 ? v : null) },
  avgAirbnbPrice: { name: 'avgAirbnbPrice', fn: (v: any) => parseFloat(v) },
  marketSize: { name: 'marketSize', fn: (v: any) => (v.filter(Boolean).length > 0 ? v : null) },
  sortOrder: { name: 'sortOrder', fn: (v: any) => v },
};

export const FILTER_DEFAULTS = {
  [EFilterPages.TOP_MARKETS]: {
    FILTERS: defaultTopMarketFilters,
    INACTIVE_FILTERS: INACTIVE_FILTERS[EFilterPages.TOP_MARKETS],
    TRANSFORMS: TOP_MARKET_MAP_KEY_TRANSFORMS,
    SORT_VALUES: [
      { value: { name: 'roi', asc: false }, text: 'Highest ROI' },
      { value: { name: 'yearlyRevenue', asc: false }, text: 'Highest Revenue' },
      { value: { name: 'homeValuation', asc: false }, text: 'Highest price' },
      { value: { name: 'homeValuation', asc: true }, text: 'Lowest price' },
      { value: { name: 'numberofAirbnbs', asc: false }, text: 'Highest number of Airbnbs' },
      { value: { name: 'numberofAirbnbs', asc: true }, text: 'Lowest number of Airbnbs' },
    ],
  },
  [EFilterPages.MARKET_INSIGHTS]: {
    FILTERS: defaultMarketInsightFilters,
    INACTIVE_FILTERS: INACTIVE_FILTERS[EFilterPages.MARKET_INSIGHTS],
    TRANSFORMS: MARKET_INSIGHTS_MAP_KEY_TRANSFORMS,
    SORT_VALUES: [
      { value: {}, text: 'Top Airbnbs' }, // { name: 'strScore', asc: false }
      { value: { name: 'occupancy', asc: false }, text: 'Occupancy: High-to-low' },
      { value: { name: 'occupancy', asc: true }, text: 'Occupancy: Low-to-high' },
      { value: { name: 'revenue', asc: false }, text: 'Revenue/mo: High-to-low' },
      { value: { name: 'revenue', asc: true }, text: 'Revenue/mo: Low-to-high' },
      { value: { name: 'adr', asc: false }, text: 'Avg. daily rate: High-to-low' },
      { value: { name: 'adr', asc: true }, text: 'Avg. daily rate: Low-to-high' },
    ],
  },
  [EFilterPages.ESTIMATOR]: {
    FILTERS: defaultEstimatorFilters,
    INACTIVE_FILTERS: INACTIVE_FILTERS[EFilterPages.ESTIMATOR],
    TRANSFORMS: ESTIMATOR_MAP_KEY_TRANSFORMS,
    SORT_VALUES: [
      { value: {}, text: 'Most Similar' }, // { name: 'similarityScore', asc: false }
      { value: { name: 'distance', asc: false }, text: 'Nearest' },
      { value: { name: 'occupancy', asc: false }, text: 'Occupancy: High-to-low' },
      { value: { name: 'occupancy', asc: true }, text: 'Occupancy: Low-to-high' },
      { value: { name: 'revenue', asc: false }, text: 'Revenue/mo: High-to-low' },
      { value: { name: 'revenue', asc: true }, text: 'Revenue/mo: Low-to-high' },
      { value: { name: 'adr', asc: false }, text: 'Avg. daily rate: High-to-low' },
      { value: { name: 'adr', asc: true }, text: 'Avg. daily rate: Low-to-high' },
    ],
  },
};
