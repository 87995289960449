import { TStateAbbreviation } from '@/src/types';
import { FILTER_DEFAULTS } from './FilterConstants';

export enum EFilterPages {
  ESTIMATOR = 'Estimator',
  MARKET_INSIGHTS = 'Market Data',
  TOP_MARKETS = 'Top Markets',
}

// export enum EInvestmentType {
//   LTR = 0,
//   STR = 1,
// }
export type TFilterStore = {
  activePage?: EFilterPages;
  // investmentType: EInvestmentType;
  defaults: () => typeof FILTER_DEFAULTS[EFilterPages];
  /* All markets available to user when selecting which ones to filter by*/
  availableMarkets: TAvailableMarkets[];
  /* Used to unset/reset a filter to the defaultFilter state   */
  clearFilter: (filterName: TFilterStore['filterShown']) => void;
  /* Executes the filters i.e. makes a request to the server and updates the url with the filter state */
  executeSearch: (bounds?: any) => void;
  /* Simply returns the JSON.stringify'd version of the filterState */
  getFilterQueryParams: () => { filterState: string } | {};

  filterShown: null | TFilterName | 'moreFilters';
  filterState: TFilterState;
  changeSet: Partial<TFilterState>;
  applyChangeSet: (changeSet?: TFilterStore['changeSet']) => void;
  hasFilterApplied: () => boolean;
  hasFilterChanged: (filterName: TFilterStore['filterShown']) => boolean;
  hideFilter: (filterName: TFilterStore['filterShown']) => void;
  resetFilterState: (filterState?: TFilterState) => void;
  showFilter: (filterName: TFilterStore['filterShown']) => void;
  snapshot: null | TFilterState;
  updateFilter: (filterName: TFilterName, path: null | string, value: any) => void;
  updateSortAndExecuteSearch: (sortOrder: TFilterStore['filterState']['sortOrder']) => void;
};

export type TFilterUSStates = TStateAbbreviation[];
export type TAvailableMarkets = { id: string; name: string; stateId: TStateAbbreviation };
export type TPagination = {
  listingsPerPage?: number;
  pageNumber?: number;
  totalPages?: number;
  totalResultCount?: number;
};
export type TFilterFlag = { value: boolean };
export type TFilterFeaturedFlag = TFilterFlag;
export type TFilterFavoritesFlag = TFilterFlag;
export type TFilterExpertReviewedFlag = TFilterFlag;
export type TFilterHomeTypes =
  | 'condominium'
  | 'mobile_manufactured_home'
  | 'single_family_home'
  | 'townhouse';

export type TFilterEstimatorHomeTypes =
  | 'homes' //
  | 'private_rooms'
  | 'hotel_rooms'
  | 'shared_rooms';

export type TFilterNameTopMarkets = 'states' | 'marketSize' | 'avgAirbnbPrice';
export type TFilterName =
  | TFilterNameTopMarkets
  | 'appreciation'
  | 'bathroomCount'
  | 'bedroomCount'
  | 'capRate'
  | 'cashOnCash'
  | 'daysOnMarket'
  | 'expertReviewed'
  | 'favorites'
  | 'featured'
  | 'hoaFee'
  | 'homeTypes'
  | 'keyword'
  | 'lotSqFt'
  | 'monthlyCashFlow'
  | 'monthlyRent'
  | 'neighborhood'
  | 'price'
  | 'propertySqFt'
  | 'renovation'
  | 'schools'
  | 'sortOrder'
  | 'yearBuilt'
  // STR only
  | 'averageDailyRate'
  | 'bedrooms'
  | 'hasBackyard'
  | 'hasBbq'
  | 'hasBeachfront'
  | 'hasBikes'
  | 'hasDryer'
  | 'hasFreeParking'
  | 'hasGym'
  | 'hasHotTub'
  | 'hasKitchen'
  | 'hasPingPongTable'
  | 'hasPool'
  | 'hasPoolTable'
  | 'hasWasher'
  | 'hasWasherfront'
  | 'homeTypes'
  | 'maxGuests'
  | 'monthlyRevenue'
  | 'numberOfReviews'
  | 'petsAllowed'
  | 'radiusMiles'
  | 'ratingStars';

type Never<T> = Omit<T, keyof T>;
type TMinMax = { min: null | number | string; max: null | number | string };
type TEitherWithWithout = 'either' | 'with' | 'without ';
type TFilterEitherWithWithout = { value: TEitherWithWithout };

export type TBaseFilterState = {
  appreciation: TMinMax;
  bathroomCount: TMinMax;
  bedroomCount: TMinMax;
  capRate: TMinMax;
  cashOnCash: TMinMax;
  daysOnMarket: TMinMax;
  expertReviewed?: TFilterExpertReviewedFlag;
  favorites: TFilterFavoritesFlag;
  featured: TFilterFeaturedFlag;
  hasPool: TFilterEitherWithWithout;
  hoaFee: TMinMax;
  homeTypes: { values: TFilterHomeTypes[] };
  keyword: { value: string };
  lotSqFt: TMinMax;
  monthlyCashFlow: TMinMax;
  monthlyRent?: TMinMax; // LTR only
  monthlyRevenue?: TMinMax; // STR only
  neighborhood: TMinMax;
  price: TMinMax;
  propertySqFt: TMinMax;
  renovation: TMinMax;
  schools: TMinMax;
  yearBuilt: TMinMax;
};

export type TEstimatorFilterState = {
  bedrooms: TMinMax;
  radiusMiles: TMinMax;
  ratingStars: TMinMax;
  numberOfReviews: TMinMax;
  averageDailyRate: TMinMax;
  maxGuests: TMinMax;
  homeTypes: { values: TFilterEstimatorHomeTypes[] };
  hasBackyard: TFilterEitherWithWithout;
  hasBbq: TFilterEitherWithWithout;
  hasBeachfront: TFilterEitherWithWithout;
  hasBikes: TFilterEitherWithWithout;
  hasDryer: TFilterEitherWithWithout;
  hasFreeParking: TFilterEitherWithWithout;
  hasGym: TFilterEitherWithWithout;
  hasHotTub: TFilterEitherWithWithout;
  hasKitchen: TFilterEitherWithWithout;
  hasPingPongTable: TFilterEitherWithWithout;
  hasPool: TFilterEitherWithWithout;
  hasPoolTable: TFilterEitherWithWithout;
  hasWasher: TFilterEitherWithWithout;
  hasWasherfront: TFilterEitherWithWithout;
  petsAllowed: TFilterEitherWithWithout;
  sortOrder:
    | { name?: never; asc?: never }
    | {
        name:
          | 'similarityScore'
          | 'occupancyRateLtm'
          | 'radiusMiles'
          | 'annualBookingRevenueLtm'
          | 'averageDailyRate';
        asc: boolean;
      };
} & Never<TBaseFilterState>;

export type TTopMarketFilterState = {
  states: { values: TFilterUSStates };
  avgAirbnbPrice: TMinMax;
  marketSize: { values: ('small' | 'medium' | 'large')[] };
  sortOrder: {
    name: 'roi' | 'yearlyRevenue' | 'homeValuation' | 'numberofAirbnbs';
    asc: boolean;
  };
} & Never<TBaseFilterState>;

export type TMarketInsightsFilterState = {
  sortOrder:
    | { name?: never; asc?: never }
    | {
        name:
          | 'strScore'
          | 'occupancyRateLtm'
          | 'radiusMiles'
          | 'monthlyBookingRevenueLtm'
          | 'averageDailyRate';
        asc: boolean;
      };
} & Never<TBaseFilterState>;

export type TFilterState =
  | TEstimatorFilterState
  | TTopMarketFilterState
  | TMarketInsightsFilterState;
