import { useState } from 'react';
import { useGoogleMapsApi } from '@/src/GoogleMap';
import { useEffect, useRef } from 'react';

export const useAutoCompleteAndGeocoder = () => {
  const googleApi = useGoogleMapsApi();

  const [{ autocomplete, geocoder }, setState] = useState<{
    autocomplete: google.maps.places.AutocompleteService | null;
    geocoder: google.maps.Geocoder | null;
  }>({ autocomplete: null, geocoder: null });

  useEffect(() => {
    if (!googleApi) return;

    let autocomplete = null;
    let geocoder = null;
    if (googleApi.maps.places.PlacesServiceStatus.OK === 'OK') {
      autocomplete = new googleApi.maps.places.AutocompleteService();
    }

    geocoder = new googleApi.maps.Geocoder();
    setState({ autocomplete, geocoder });
  }, [googleApi]);

  return { autocomplete, geocoder, googleApi };
};

// Supply a function that returns true if it runs and false if it doesn't
export const usePreloadItems = (fireFn: (p?: any) => Boolean, deps: any[]) => {
  const runOnce = useRef(false);
  return useEffect(() => {
    if (!runOnce.current && deps.filter(Boolean).length > 0) {
      if (fireFn()) {
        runOnce.current = true;
      }
    }
  }, [deps, fireFn]);
};
