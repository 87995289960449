import * as React from 'react';
import { Box, Flex, Text, toLocaleUS, toUSD } from '@awning/components';
import { useMapViewStore } from '@/src/MapView';
import { shallow } from '@awning/archie';
import { ReactShimmerSVGComponent } from '@/src/generateShimmerSVG';
import { ListingRentEstimatorCardMarkerOverlay } from '@/src/MapView/Overlays/ListingRentEstimatorCardMarkerOverlay';
import { pluralize, toMiles, toPercentString } from '@/src/utils';
import { useComparableModal } from '@/src/estimator/EstimatorListings/ComparableModal';
import { SignUpToViewCardOverlayMarker } from './SignUpToViewCardOverlayMarker';
import { TRentEstimatorListing } from '@/src/rent-estimator';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

export const ListingRentEstimatorCardOverlayMarker = () => {
  const listingCardElemRef = React.useRef();
  const googleListingCardOverlay = React.useRef<ListingRentEstimatorCardMarkerOverlay>();
  const showDetails = useComparableModal(state => state.show);

  const { map, selectedMarker, markerCollection } = useMapViewStore(
    state => ({
      map: state.map,
      selectedMarker: state.selectedMarker,
      markerCollection: state.markerCollection,
    }),
    shallow
  );

  const fontFamily = `"Benton Sans", "Helvetica Neue", "Arial", sans-serif`;

  React.useEffect(() => {
    if (googleListingCardOverlay.current) {
      // reset so the carousel empties out
      googleListingCardOverlay.current.overlay.setMap(null);
      googleListingCardOverlay.current = undefined;
    }

    if (selectedMarker === -1 || !map || markerCollection.overlays.length === 0) return;

    const overlay = markerCollection.getOverlayAt(selectedMarker);
    const coordinates = overlay?.getPosition()!;

    const listingCard = new ListingRentEstimatorCardMarkerOverlay(
      coordinates,
      listingCardElemRef.current!
    );
    listingCard.overlay.setMap(map);
    googleListingCardOverlay.current = listingCard;
  }, [selectedMarker]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      ref={listingCardElemRef}
      className="map-listing-card"
      sx={{
        fontFamily,
        maxHeight: '200px',
        width: { base: 'auto', md: '350px' },
        minWidth: { base: 'auto', md: '350px' },
        maxWidth: { base: '300px', md: '350px' },
        background: 'white',
        overflow: 'hidden',
        boxShadow: 'lg',
        borderRadius: 'md',
      }}
    >
      {selectedMarker !== -1 && (
        <SignUpToViewCardOverlayMarker>
          <SmallListingCard
            onClick={showDetails}
            listing={markerCollection.getOverlayAt(selectedMarker).data!}
          />
        </SignUpToViewCardOverlayMarker>
      )}
    </Box>
  );
};

const SmallListingCard = ({
  onClick,
  listing,
}: {
  onClick: any;
  listing: TRentEstimatorListing;
}) => {
  return (
    <Flex
      sx={{
        padding: 4,
        gap: 2,
        textDecoration: 'none !important',
        fontWeight: 'normal',
        cursor: 'pointer',
        text: 'xs',
      }}
      onClick={onClick}
    >
      <Flex
        sx={{
          gap: 1,
          flexDirection: { base: 'column', md: 'row' },
          overflow: 'hidden',
          text: 'xs',
        }}
      >
        <Box
          sx={{
            borderRightWidth: { base: 0, md: '1px' },
            borderRightStyle: 'solid',
            borderColor: 'gray.200',
            height: 'fit-content',
            paddingRight: { base: 0, md: 2 },
            fontSize: { base: 'lg', md: 'xs' },
          }}
        >
          <Text sx={{ fontWeight: 'bold' }}>{toUSD(listing.askingRent)}</Text>
          <Text sx={{ color: 'gray.400' }}>/yr</Text>
        </Box>
        <Box sx={{ paddingLeft: { base: 0, md: 2 } }}>
          <Flex sx={{ text: 'sm' }}>
            <Box>
              {listing.bedroomCount} {pluralize(listing.bedroomCount, 'Bed')}
            </Box>
            <Text sx={{ color: 'gray.400', paddingX: 1 }}>•</Text>
            <Box>
              {listing.bathroomCount} {pluralize(listing.bathroomCount, 'Bath')}
            </Box>
            <Text sx={{ color: 'gray.400', paddingX: 1 }}>•</Text>
            <Box>{toLocaleUS(listing.unitSqFt)} sq ft</Box>
          </Flex>
          <Flex sx={{ text: 'sm' }}>
            <Box>{listing.propertyType ?? '-'}</Box>
            <Text sx={{ color: 'gray.400', paddingX: 1 }}>•</Text>
            <Box>Listed {format(new Date(listing.dateListed), 'MM/dd/yy')}</Box>
          </Flex>
          <Flex sx={{ alignItems: 'center', text: 'xs' }}>
            <Box>{listing.street.split(' ').map(capitalize).join(' ')}</Box>
          </Flex>
          <Box>{toMiles(listing.radiusMiles, 1)} mi away</Box>
        </Box>
      </Flex>
    </Flex>
  );
};
