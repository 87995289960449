import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import Tippy from '@tippyjs/react/headless';
import { Box, Flex, Text } from '@awning/components';
import { ReactComponent as CheckIcon } from '@awning/components/assets/icons/check.svg';
import { ReactComponent as ChevronDown } from '@awning/components/assets/icons/chevron-down.svg';
import { TEstimatorFilterState, useFilterStore } from '@/src/Filters';

type TSortOrder = TEstimatorFilterState['sortOrder'];

export const SortListings: React.FC = () => {
  const { SORT_VALUES, sortOrder, updateSortAndExecuteSearch } =
    useFilterStore<TEstimatorFilterState>(state => ({
      SORT_VALUES: state.defaults().SORT_VALUES,
      sortOrder: state.filterState.sortOrder,
      updateSortAndExecuteSearch: state.updateSortAndExecuteSearch,
    }));

  const handleChange = async (value: TEstimatorFilterState['sortOrder']) => {
    updateSortAndExecuteSearch(value);
  };

  if (SORT_VALUES.length === 0) return null;

  return (
    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ width: 'auto', alignItems: 'center' }}>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <SortSelect
            handleChange={handleChange}
            selected={sortOrder}
            values={SORT_VALUES as any}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

const getSelectedItem = (values: { value: TSortOrder; text: any }[], selected: TSortOrder) =>
  values.find(v => {
    if (isEmpty(selected) && isEmpty(v)) return true;

    return v.value && selected
      ? v.value.name === selected.name && v.value.asc === selected.asc
      : v.value === selected;
  });

const SortSelect: React.FC<
  React.PropsWithChildren<{
    values: {
      value: TSortOrder;
      text: React.ReactNode | string;
    }[];
    handleChange: (value: TSortOrder) => void;
    selected: TSortOrder;
  }>
> = ({ handleChange, values, selected }) => {
  const [selectedItem, setSelectedItem] = React.useState(getSelectedItem(values, selected));

  React.useEffect(() => {
    setSelectedItem(getSelectedItem(values, selected));
  }, [selected]); // eslint-disable-line

  return (
    <Tippy
      interactive={true}
      placement="bottom-start"
      appendTo={() => document.body}
      arrow={true}
      trigger="click"
      render={(_, __, instance) => {
        return (
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              background: 'white',
              border: '1px solid',
              borderColor: 'gray.200',
              borderRadius: 'xl',
              boxShadow: 'lg',
              color: 'gray.700',
              cursor: 'pointer',
              fontWeight: 'normal',
              text: 'xs',
              overflow: 'hidden',
            }}
            onClick={() => instance?.hide()}
          >
            {values.map(({ value, text }, index) => (
              <SortItem
                key={index}
                style={
                  index === values.length - 1
                    ? {}
                    : { borderBottom: '1px solid', borderColor: 'gray.200' }
                }
                value={value}
                onClick={handleChange}
                isSelected={value?.name === selected?.name && value?.asc === selected?.asc}
              >
                {text}
              </SortItem>
            ))}
          </Flex>
        );
      }}
    >
      <Flex sx={{ alignItems: 'center', gap: 2, cursor: 'pointer' }}>
        <Box sx={{ text: 'sm', cursor: 'pointer' }}>
          Sort: <Text sx={{ color: 'green.700' }}>{selectedItem?.text}</Text>
        </Box>
        <Box
          as={ChevronDown}
          sx={{
            width: '20px',
            height: '20px',
          }}
        />
      </Flex>
    </Tippy>
  );
};

const SortItem: React.FC<
  React.PropsWithChildren<{
    style: object;
    value: TSortOrder;
    isSelected: boolean;
    onClick: (value: TSortOrder) => void;
  }>
> = ({ style, value, isSelected, onClick, children }) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <Flex
      sx={{
        px: 4,
        py: 1.5,
        cursor: 'pointer',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: hovered ? '#000' : '#fff',
        text: 'sm',
        color: hovered ? '#fff' : 'inherit',

        ...style,
      }}
      value={value}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        onClick(value);
        setHovered(false);
      }}
    >
      <Box sx={{ paddingRight: 10 }}>{children}</Box>
      {isSelected && <Box sx={{ position: 'absolute', right: 4, height: '20px' }} as={CheckIcon} />}
    </Flex>
  );
};
