import { memo } from 'react';
import Image from 'next/image';
import { Box, Flex } from '@awning/components';
import { useHover } from '@/src/useHover';
import { useMarketInsightsStore } from './store';

export const MarketPhoto = memo(() => {
  const marketInsights = useMarketInsightsStore(state => state.marketInsights);
  const { imageUrl, imageCitation } = marketInsights?.overview ?? {};
  const pictureUrl = imageUrl
    ? imageUrl
    : `${process.env.NEXT_PUBLIC_BASE_PATH}/no-image-fallback.png`;

  const [imageCitationRef, showCitation] = useHover();
  return (
    <Box ref={imageCitationRef} sx={{ position: 'relative' }}>
      <Flex
        sx={{
          overflow: 'hidden',
          width: { base: '100%', lg: '600px' },
          height: '146px',
          order: { base: 0, lg: 1 },
          position: 'relative',
          marginBottom: { base: 3, lg: 0 },
        }}
      >
        <Box
          as={Image}
          className="photo"
          sx={{
            width: { base: '100%', lg: '600px' },
            height: '146px',
            objectFit: 'cover',
            alignSelf: 'flex-end',
            transition: 'all 100ms',
          }}
          src={pictureUrl}
          layout="fill"
          quality={60}
          sizes="(max-width: 768px) 50vw"
        />
        {imageCitation && (
          <Box
            className="citation"
            sx={{
              backgroundColor: '#2E2D2D',
              color: '#fff',
              zIndex: 9,
              whiteSpace: 'nowrap',
              alignSelf: 'flex-end',
              opacity: '0.5',
              fontSize: '10px',
              paddingX: 2,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              cursor: 'default',
            }}
          >
            {imageCitation}
          </Box>
        )}
      </Flex>
      {imageCitation && showCitation && (
        <Box
          className="citation-box"
          sx={{
            position: 'absolute',
            zIndex: 9,
            bottom: 0,
            right: 0,
          }}
        >
          <Flex
            sx={{
              fontSize: '10px',
              backgroundColor: '#fff',
              flexDirection: 'column',
              boxShadow: 'lg',
              padding: 4,
            }}
          >
            <Box sx={{ text: 'xs', fontWeight: 'bold' }}>Image Source</Box>
            <Box sx={{ minWidth: '220px' }}>{imageCitation}</Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
});
