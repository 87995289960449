import { memo } from 'react';
import { Box } from '@awning/components';
import { EMapViewPage, MapView } from '@/src/MapView';
import { MapAndListingsContainerView } from './MapAndListingsContainer';
import { ListingTopMarketCardOverlayMarker } from '@/src/MapView/Markers/ListingTopMarketCardOverlayMarker';
import { maxListingCardWidth } from './TopMarketListings/Card';
import { CONTAINER_MARGIN_LEFT } from './common';
import { TopMarketListings } from './TopMarketListings';
import { TopMarketMarker } from '@/src/MapView/Markers/TopMarketMarker';
import { TopMarketFilters } from '@/src/Filters/TopMarkets/TopMarketFilters';
import { TopMarketsPagination } from '../Pagination/TopMarketsPagination';
import { Heading } from './Heading';

export const TopMarkets = memo(() => {
  return (
    <>
      <MapAndListingsContainerView
        maxWidth={maxListingCardWidth}
        renderListings={() => {
          return (
            <Box sx={{ marginLeft: CONTAINER_MARGIN_LEFT }}>
              <Heading />
              <TopMarketFilters />
              <TopMarketListings />
              <TopMarketsPagination />
            </Box>
          );
        }}
        renderMapView={() => {
          return (
            <MapView page={EMapViewPage.TOP_MARKETS} activeControls={['SELECT_VIEW_OPTION']}>
              <TopMarketMarker />
              <ListingTopMarketCardOverlayMarker />
            </MapView>
          );
        }}
      />
    </>
  );
});
