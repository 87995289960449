import { Box, Flex, Table, Text, toLocaleUS } from '@awning/components';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useMapViewStore } from '../MapView';
import { capitalize, pluralize, toMiles, toUSD } from '../utils';
import { useRentEstimatorStore } from './store';

const columns = [
  { Header: '', accessor: 'position' },
  { Header: 'Rent/mo', accessor: 'askingRent' },
  { Header: 'Distance', accessor: 'radiusMiles' },
  { Header: 'Beds', accessor: 'bedroomCount' },
  { Header: 'Baths', accessor: 'bathroomCount' },
  { Header: 'Sq Ft', accessor: 'unitSqFt' },
  { Header: 'Home type', accessor: 'propertyType' },
  { Header: 'Date listed', accessor: 'dateListed' },
  { Header: 'Street', accessor: 'street' },
];

export const ListingsTable = () => {
  const { markerCollection, selectedMarker } = useMapViewStore(state => ({
    markerCollection: state.markerCollection,
    selectedMarker: state.selectedMarker,
  }));

  const overlay = markerCollection.getOverlayAt(selectedMarker);

  const onHover = useCallback(
    (id: string) => {
      const overlay = markerCollection.getOverlayById(id);
      const currentlyHighlighted =
        markerCollection?.getCurrentSelected() || markerCollection?.getCurrentlyHighlighted();

      if (overlay && currentlyHighlighted !== overlay) {
        markerCollection.deselect();
        markerCollection?.select(overlay);
      }
    },
    [markerCollection]
  );
  const listings = useRentEstimatorStore(state => state.listings);

  const data = useMemo(() => {
    return listings.map((l, index) => ({
      position:
        index < 5 ? (
          <Flex
            sx={{
              background: '#15803D',
              borderRadius: 'full',
              alignItems: 'center',
              justifyContent: 'center',
              height: '19px',
              width: '19px',
              text: 'xs',
              color: 'white',
            }}
          >
            {index + 1}
          </Flex>
        ) : undefined,
      askingRent: toUSD(l.askingRent),
      radiusMiles: `${toMiles(l.radiusMiles, 1)} mi`,
      bedroomCount: `${l.bedroomCount} ${pluralize(l.bedroomCount, 'Bed')}`,
      bathroomCount: `${l.bathroomCount} ${pluralize(l.bathroomCount, 'Bath')}`,
      unitSqFt: `${toLocaleUS(l.unitSqFt)} sq ft`,
      propertyType: l.propertyType ?? '-',
      dateListed: format(new Date(l.dateListed), 'MM/dd/yy'),
      street: l.street.split(',')?.[0].split(' ').map(capitalize).join(' '),
      id: l.sourceUnitId,
    }));
  }, [listings]);

  return (
    <Box sx={{ paddingBottom: '350px', paddingRight: 4 }}>
      <Flex sx={{ flexDirection: 'column', paddingY: 4 }}>
        <Text sx={{ text: 'sm', fontWeight: 'bold' }}>Comparables</Text>
        <Text sx={{ text: 'xs', color: 'gray.500' }}>
          Results are ranked based on home similarity.
        </Text>
      </Flex>
      <Table
        enablePagination={true}
        data={data}
        columns={columns}
        getCellProps={(cell: any) => {
          return {
            style: {
              fontWeight: cell.column.id === 'askingRent' ? 'bold' : 'normal',
              text: cell.column.id === 'askingRent' ? 'normal' : 'sm',
              paddingY: 5,
              borderColor: 'gray.200',
              borderBottomWidth: cell.column.id === 'position' ? 0 : '1px',
              borderBottomStyle: 'solid',
            },
          };
        }}
        getRowProps={(row: any) => ({
          style: {
            cursor: 'pointer',
            backgroundColor: row.original?.id === overlay?.id ? 'gray.100' : 'unset',
          },
          onMouseEnter: () => onHover?.(row.original?.id),
        })}
        getHeaderProps={(header: any) => ({
          style: {
            backgroundColor: 'gray.100',
            paddingY: 2,
            text: 'xs',
            fontWeight: 'bold',
            color: 'gray.700',
            minWidth: header.id === 'position' ? 10 : 'auto',
            width: header.id === 'position' ? 10 : 'auto',
            maxWidth: header.id === 'position' ? 10 : 'auto',
            border: 0,
          },
        })}
      />
    </Box>
  );
};
