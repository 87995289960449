import * as React from 'react';
import { InputCurrency } from '@awning/components';
import { InputRangeFilter } from '../InputRangeFilter';
import { useEstimatorFilterStore } from '../store';

export const ADRFilter = () => {
  const { averageDailyRate, update } = useEstimatorFilterStore(state => ({
    averageDailyRate: state.filterState.averageDailyRate,
    update: state.updateFilter,
  }));

  return (
    <InputRangeFilter
      name="MonthlyCashFlow"
      control={InputCurrency}
      right=""
      minValue={averageDailyRate.min}
      maxValue={averageDailyRate.max}
      limits={{
        min: 0,
      }}
      onChange={(t, value) => {
        update('averageDailyRate', `${t}`, value);
      }}
    />
  );
};
