import * as React from 'react';
import { Box, Flex, LinkButton, Text } from '@awning/components';
import { ReactComponent as ChevronRight } from '@awning/components/assets/icons/chevron-right.svg';
import { useCalendly } from '@/src/useCalendly';
import { publicUrl } from '../publicUrl';

const BuyWithAwning = React.memo(() => {
  const url = 'https://host.redawning.com/meetings/redawning/meeting-with-awning';
  const openCalendly = useCalendly({
    calendlyParams: { url },
  });

  return (
    <Flex
      sx={{
        position: 'relative',
        marginY: 6,
        backgroundColor: 'gray.100',
        borderRadius: 'md',
        marginRight: { base: 0, lg: 4 },
        flexDirection: { base: 'column', lg: 'row' },
      }}
    >
      <Box
        as="img"
        sx={{ display: { base: 'block', lg: 'none' }, height: '56px' }}
        src={publicUrl('buying-with-awning-jacob-mobile.jpg')}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          text: 'sm',
          paddingTop: 5,
          paddingLeft: 5,
          paddingBottom: 5,
          paddingRight: { base: 5, lg: 0 },
        }}
      >
        <Text sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Work with one of Awning’s licensed agents to buy an Airbnb with confidence
        </Text>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto 10px auto',
            width: 'fit-content',
            gap: 1,
            marginY: 4,
            text: 'xs',
            color: 'gray.500',
          }}
        >
          <Text>100s of transactions</Text>
          <Text sx={{ color: 'gray.300' }}>/</Text>
          <Text>Vacation rental experts</Text>

          <Text>Access to top deals</Text>
          <Text sx={{ color: 'gray.300' }}>/</Text>
          <Box>
            <Text>Text or call </Text>
            <Box sx={{ color: 'blue.700', textDecoration: 'none' }} as="a" href="tel:415-941-3401">
              415-941-3401
            </Box>
          </Box>
        </Box>

        <Flex
          sx={{
            flexDirection: {
              base: 'column',
              lg: 'row',
            },
            marginTop: 2,
            alignItems: 'center',
            gap: { base: 3, lg: 8 },
          }}
        >
          <Box sx={{ width: { base: '100%', lg: 'auto' } }}>
            <LinkButton
              size="sm"
              variant="secondary"
              href={url}
              target="_blank"
              rel="noreferrer"
              onClick={(e: any) => {
                e.preventDefault();
                openCalendly();
              }}
            >
              Schedule a call
            </LinkButton>
          </Box>
          <Flex
            as="a"
            href="https://awning.com/vacation-rental-buying"
            target="_blank"
            rel="noreferrer"
            sx={{ alignItems: 'center' }}
          >
            Learn More
            <ChevronRight height="16px" width="16px" />
          </Flex>
        </Flex>
      </Flex>
      <Box
        as="img"
        sx={{ display: { base: 'none', lg: 'block' }, maxWidth: '170px' }}
        src={publicUrl('buying-with-awning-jacob.png')}
      />
    </Flex>
  );
});

export default BuyWithAwning;
