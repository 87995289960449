import * as React from 'react';
import { Field, FieldProps } from '@awning/components';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

type Props = Omit<FieldProps, 'required'> & {
  name: string;
};

export const SynchedField: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  label,
  ...rest
}) => {
  const [untouched, setUntouched] = React.useState(true);
  const { control } = useFormContext();
  const value = useWatch({
    control,
    name,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={props => {
        const hasErrors = !!props.fieldState.error?.message;
        return (
          <Field
            label={label}
            {...props.field}
            hasErrors={hasErrors}
            // @ts-ignore
            errors={hasErrors ? [props.fieldState.error?.message] : []}
            onChange={(e: any) => {
              if (untouched) {
                setUntouched(false);
              }
              props.field.onChange(e);
            }}
            {...rest}
            value={value ?? 0}
          />
        );
      }}
    />
  );
};
