import { shallow } from '@awning/archie';
import { Box, Text, toUSD } from '@awning/components';
import { STATE_NAMES } from 'STATES';
import { toAbbrUSD } from '@/src/utils';
import { useMarketInsightsStore } from './store';
import { MaskedData } from '../MaskedData/MaskedData';

const getLongStateName = (stateId: string) =>
  ((STATE_NAMES as any)[stateId.toUpperCase()] ?? stateId) as string;

export const RevenueTabSummary = () => {
  const { q, revenue } = useMarketInsightsStore(
    state => ({
      revenue: state.marketInsights.annualRevenue,
      q: state.q,
    }),
    shallow
  );
  const location = q.split('--');
  const placeLongName = location?.[0];
  const shortStateName = location?.[1];

  const stateName = getLongStateName(shortStateName);
  let text =
    !revenue.privateRoom || !revenue.sharedRoom
      ? '.'
      : `, ${toAbbrUSD(revenue.privateRoom)} for private rooms, and ${toUSD(
          revenue.sharedRoom
        )} for shared rooms.`;

  // Example:The average airbnb revenue in San Francisco, CA is $14K for entire homes, $6,843 for private rooms, and $5,435 for shared rooms.
  return (
    <Box sx={{ marginTop: 4, marginBottom: 4 }}>
      <Text sx={{ display: 'block', fontWeight: 'bold', marginTop: 6, marginBottom: 4 }}>
        Revenue
      </Text>
      <Box sx={{ text: 'xs', color: 'gray.500' }}>
        <p>
          The average airbnb revenue in {placeLongName}, {stateName} is{' '}
          {toAbbrUSD(revenue.entireHome)} for entire homes
          <span>
            {revenue.privateRoom && revenue.sharedRoom ? (
              <span>
                , <MaskedData>{toAbbrUSD(revenue.privateRoom)}</MaskedData> for private rooms, and{' '}
                <MaskedData>{toAbbrUSD(revenue.sharedRoom)}</MaskedData> for shared rooms.
              </span>
            ) : (
              '.'
            )}
          </span>{' '}
          These revenue numbers exclude cleaning fees.
        </p>
      </Box>
    </Box>
  );
};
