import * as React from 'react';
import { Field, InputDual } from '@awning/components';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { EXPLAINERS, ExplanationTooltip } from '@/src/explainers';

export const SynchedDownPaymentField = React.memo(() => {
  const { control } = useFormContext();
  const name = `tra.downPayment`;
  const purchasePrice = useWatch({
    control,
    name: 'common.purchasePrice',
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={{ min: 0 }}
      render={({ field: { onChange, value, ...props } }) => {
        return (
          <Field
            label="Down Payment"
            as={InputDual}
            amount={value}
            baseValue={purchasePrice}
            onChange={({ percent }: { percent: number }) => onChange(percent)}
            tooltip={<ExplanationTooltip {...EXPLAINERS.DOWN_PAYMENT} />}
            {...props}
          />
        );
      }}
    />
  );
});
