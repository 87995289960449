import { Box, TStyleProps } from '@awning/components';
import { Fragment } from 'react';
import { useUserStore } from '../shared/userStore';

export const MaskedData: React.FC<
  React.PropsWithChildren<{
    sx?: TStyleProps['sx'];
    size?: number;
  }>
> = ({ sx = {}, size = 16, children }) => {
  const isLoggedIn = useUserStore(state => state.isLoggedIn?.());
  if (isLoggedIn) return <Fragment>{children}</Fragment>;

  return (
    <Box as="span" className="locked-icon" sx={sx}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00033 10.5V11.8333M4.00033 14.5H12.0003C12.3539 14.5 12.6931 14.3595 12.9431 14.1095C13.1932 13.8594 13.3337 13.5203 13.3337 13.1667V9.16667C13.3337 8.81304 13.1932 8.47391 12.9431 8.22386C12.6931 7.97381 12.3539 7.83333 12.0003 7.83333H4.00033C3.6467 7.83333 3.30756 7.97381 3.05752 8.22386C2.80747 8.47391 2.66699 8.81304 2.66699 9.16667V13.1667C2.66699 13.5203 2.80747 13.8594 3.05752 14.1095C3.30756 14.3595 3.6467 14.5 4.00033 14.5ZM10.667 7.83333V5.16667C10.667 4.45942 10.386 3.78115 9.88594 3.28105C9.38585 2.78095 8.70757 2.5 8.00033 2.5C7.29308 2.5 6.6148 2.78095 6.11471 3.28105C5.61461 3.78115 5.33366 4.45942 5.33366 5.16667V7.83333H10.667Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
