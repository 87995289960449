import { useCallback, useEffect } from 'react';
import { useStylesheet } from '@awning/components';
import { useScript } from '@/src/useScript';
import Analytics, { EVENTS } from '@/src/Analytics';

function isCalendlyEvent(e: any) {
  return e?.data?.event && e?.data?.event?.indexOf('calendly') === 0;
}

export const useCalendly = ({
  calendlyParams,
  onOpenCalendly = undefined,
  deps = [],
}: {
  calendlyParams: {
    prefill?: any;
    url: string;
  };
  onOpenCalendly?: Function;
  deps?: any[];
}) => {
  useStylesheet('https://assets.calendly.com/assets/external/widget.css');
  useScript('https://assets.calendly.com/assets/external/widget.js', { defer: true });

  // prefill {
  //   name: `${user.firstName} ${user.lastName}`,
  //   email: user.email,
  // }
  //
  // Calendly limits what we can send, so we are going to send these as UTM fields even though they're not
  // url: `${advisor.calendlyUrl}?salesforce_uuid=${user.id}`,
  const { url, prefill } = calendlyParams;

  useEffect(() => {
    const handler = (e: any) => {
      // @ts-ignore
      if (isCalendlyEvent(e) && typeof window !== 'undefined' && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({ 'event': 'custom_event.' + e.data.event });
      }
    };
    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  const openCalendly = useCallback(() => {
    Analytics.track(EVENTS.CLICKED_SCHEDULE_INTRO_CALL);

    if (!url) return;

    //@ts-expect-error
    if (typeof window !== 'undefined' && window.Calendly) {
      onOpenCalendly?.();
      //@ts-expect-error
      window.Calendly.initPopupWidget({ prefill, url });
    }
  }, [deps]); // eslint-disable-line

  return openCalendly;
};
