import * as React from 'react';
import { shallow } from '@awning/archie';
import { Box, Flex, Text } from '@awning/components';
import { ReactComponent as FilterIcon } from '@awning/components/assets/icons/filter.svg';
import { SortListings } from './SortListings';
import { useEstimatorStore } from '../store';
import { useMapViewStore } from '@/src/MapView';
import { useComparableModal } from './ComparableModal';
import { Card, CardProps } from './Card';
import { useEstimatorFilterStore } from '@/src/Filters';
import { BUCKETS } from '@/src/Pagination';
import { useUserStore } from '@/src/shared/userStore';
import { useSignupModal } from '@/src/SignupModal';

export const EstimatorListings = () => {
  const showMoreFilters = useEstimatorFilterStore(state => () => state.showFilter('moreFilters'));
  const isLoggedIn = useUserStore(state => state.isLoggedIn?.());

  const showComparableModal = useComparableModal(state => state.show);
  const showSignupModal = useSignupModal(state => state.show);

  const showModal = () => {
    if (isLoggedIn) {
      showComparableModal();
    } else {
      showSignupModal({ step: 1 });
    }
  };

  const { isFirstRange, filteredListings, allListings, address } = useEstimatorStore(
    state => ({
      isFirstRange: state.range?.[0] === BUCKETS?.[0][0],
      filteredListings: state.listings?.slice(state.range[0], state.range[1]),
      allListings: state.listings,
      address: state.address,
    }),
    shallow
  );

  const markerCollection = useMapViewStore(state => state.markerCollection);

  const handleHover = React.useCallback(
    (id: string) => {
      const overlay = markerCollection.getOverlayById(id);
      const currentlyHighlighted =
        markerCollection?.getCurrentSelected() || markerCollection?.getCurrentlyHighlighted();

      if (overlay && currentlyHighlighted !== overlay) {
        markerCollection.deselect();
        markerCollection?.select(overlay);
      }
    },
    [markerCollection]
  );

  return (
    <Box sx={{ lineHeight: 1.8, paddingBottom: '25%' }}>
      <Box sx={{ marginTop: 5 }}>
        <Box sx={{ fontSize: { base: 'sm', lg: 'base' }, fontWeight: 'bold' }}>
          Airbnbs near {address?.split(',')?.[0]}
        </Box>
        <Flex
          sx={{
            mt: 2,
            flexDirection: { base: 'column', lg: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Flex
            sx={{
              fontWeight: 'bold',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              fontSize: {
                base: 'xs',
                lg: 'sm',
              },
            }}
          >
            <Flex>
              <Flex>
                <Box>
                  {allListings?.length === filteredListings?.length
                    ? `${filteredListings?.length} homes`
                    : `${filteredListings?.length} of ${allListings?.length} homes`}
                </Box>
              </Flex>

              <Flex>
                <Box sx={{ color: 'gray.500', mx: 2 }}>&#8226;</Box>
                <SortListings />
              </Flex>
            </Flex>

            <Flex
              as="button"
              sx={{
                alignItems: 'center',
                borderRadius: 'full',
                border: '1px solid',
                borderColor: 'gray.300',
                paddingY: 1,
                paddingX: { base: 1.5, lg: 4 },
                marginRight: { base: 0, lg: 4 },
                gap: 1,
              }}
              onClick={showMoreFilters}
            >
              <Box
                as={FilterIcon}
                sx={{ height: '20px', width: '20px', marginRight: 1 }}
                height="20px"
                width="20px"
              />
              <Text sx={{ display: { base: 'none', sm: 'inline' }, text: 'sm' }}>Filters</Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <ListingsInner
        isFirstRange={isFirstRange}
        filteredListings={filteredListings}
        handleHover={handleHover}
        handleShowDetails={showModal}
        onViewAirbnb={isLoggedIn ? undefined : () => showSignupModal({ step: 1 })}
      />
    </Box>
  );
};

const ListingsInner = React.memo(
  ({
    isFirstRange,
    filteredListings,
    handleHover,
    handleShowDetails,
    onViewAirbnb,
  }: {
    isFirstRange: boolean;
    filteredListings: CardProps['listing'][];
    handleHover: CardProps['onHover'];
    handleShowDetails: CardProps['onShowDetails'];
    onViewAirbnb: CardProps['onViewAirbnb'];
  }) => {
    return (
      <Flex
        sx={{
          marginTop: { base: 2, lg: 4 },
          flexWrap: 'wrap',
          gap: 4,
          marginRight: { lg: 4 },
        }}
      >
        {filteredListings.map((listing, index: number) => {
          return (
            <Card
              key={listing.airbnbAwningId}
              position={isFirstRange && index + 1 < 6 ? index + 1 : undefined}
              listing={listing}
              onHover={handleHover}
              onShowDetails={handleShowDetails}
              onViewAirbnb={onViewAirbnb}
            />
          );
        })}
      </Flex>
    );
  }
);
