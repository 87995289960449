import * as React from 'react';
import { Box, Divider, Flex, toLocaleUS } from '@awning/components';
import { capitalize, toAbbrUSD, toPercentString, toUSD } from '@/src/utils';
import { TTopMarket } from '../types';
import { BaseLink } from '@/src/BaseLink';
import { dasherize } from '@/src/utils';
import { STATE_NAMES } from 'STATES';
import { MaskedData } from '@/src/MaskedData/MaskedData';

export type CardProps = {
  listing: TTopMarket;
  position?: number;
  // onShowDetails?: (id: string) => void;
  onHover?: (id: string) => void;
};

export const maxListingCardWidth = '600px';

const getLongStateName = (stateId: string) =>
  ((STATE_NAMES as any)[stateId.toUpperCase()] ?? stateId) as string;

export const Card = React.memo(({ listing, position = 1, onHover }: CardProps) => {
  const listingUrl = `airbnb-market-data/${dasherize(listing.city)}-${listing.state}`;

  const stateName = getLongStateName(listing.state);

  let occupancyText = '';
  if (listing.medianOccupancy) {
    occupancyText =
      listing.medianOccupancy! > 0.6
        ? 'high'
        : listing.medianOccupancy > 0.4 && listing.medianOccupancy < 0.6
        ? 'medium'
        : 'low';
  }

  let adrText = '';
  if (listing.medianAdr) {
    adrText =
      listing.medianAdr! > 250
        ? 'high'
        : listing.medianAdr > 150 && listing.medianAdr < 250
        ? 'medium'
        : 'low';
  }

  return (
    <Box
      sx={{
        marginBottom: 2,
        color: 'gray.600',
        width: '100%',
        maxWidth: { base: '100%', lg: maxListingCardWidth },
      }}
    >
      <Box
        onMouseEnter={() => {
          onHover?.(listing.geoId);
        }}
        sx={{
          fontWeight: 'normal',
          textDecoration: 'none',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            position: 'relative',
            border: '1px solid',
            borderRadius: 'xl',
            borderColor: 'gray.200',
            overflow: 'hidden',
            transition: 'box-shadow 300ms',
            width: '100%',
            boxShadow: 'lg',
            '&:hover': {
              boxShadow: '2xl',
              '.photo': {
                transform: 'scale(1.2)',
              },
            },
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                base: '1fr',
                lg: '1fr auto',
              },
              paddingX: { lg: 6 },
              paddingY: { lg: 4 },
            }}
          >
            <Flex sx={{ order: { base: 1, lg: 0 }, flexDirection: 'column' }}>
              <Flex
                sx={{
                  paddingX: { base: 4, lg: 0 },
                  marginY: { base: 3, lg: 0 },
                  alignItems: 'center',
                }}
              >
                <Flex
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    text: 'sm',
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: '#37764C',
                    borderRadius: 'full',
                    height: '24px',
                    width: '24px',
                    padding: 4,
                  }}
                >
                  {position}
                </Flex>
                <BaseLink
                  newTab={true}
                  sx={{ color: 'blue.700', fontWeight: 'bold', marginLeft: 4 }}
                  href={listingUrl}
                >
                  {listing.city}, {listing.state}
                </BaseLink>
              </Flex>

              <Box
                sx={{
                  text: 'xs',
                  marginTop: 2,
                  marginRight: 3,
                  paddingX: { base: 4, lg: 0 },
                  marginBottom: { base: 4, lg: 0 },
                  minHeight: '80px',
                  overflow: 'hidden',
                }}
              >
                {listing.caption ? (
                  <p>{listing.caption}</p>
                ) : (
                  <p>
                    {listing.city &&
                      listing.geoType === 'city' &&
                      stateName &&
                      listing.activeRentals && (
                        <span>
                          {listing.city} is a {listing.geoType} in {stateName} with{' '}
                          <MaskedData>{listing.activeRentals}</MaskedData> active airbnbs.{' '}
                        </span>
                      )}

                    {occupancyText && adrText && (
                      <span>
                        The average price of an Airbnb in {listing.city} is around
                        <MaskedData>{toAbbrUSD(listing.medianHomeValuation)}</MaskedData> and the
                        average annual revenue of an Airbnb in {listing.city} is
                        <MaskedData>{toAbbrUSD(listing.medianYearlyRevenue)}</MaskedData>.{' '}
                        {listing.city} has a <MaskedData>{occupancyText}</MaskedData> occupancy rate
                        of <MaskedData>{toPercentString(listing.medianOccupancy, 0)}</MaskedData>{' '}
                        and a <MaskedData>{adrText}</MaskedData> average daily rate of{' '}
                        <MaskedData>{toAbbrUSD(listing.medianAdr)}</MaskedData>.
                      </span>
                    )}
                  </p>
                )}
              </Box>
            </Flex>
          </Box>
          <Box
            sx={{
              borderTop: { base: '1px solid', lg: 0 },
              backgroundColor: 'gray.100',
              borderColor: 'gray.200',
              paddingY: { base: 5, lg: 7 },
              paddingX: { base: 4, lg: 6 },
              text: 'xs',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  base: '1fr',
                  lg: '1fr auto auto',
                },
                gap: { base: 1, lg: 3 },
              }}
            >
              <Box>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                  }}
                >
                  <Flex sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ color: 'gray.500' }}>ROI *</Box>
                    <Box
                      sx={{
                        text: 'sm',
                        background: 'linear-gradient(78.6deg, #4CE689 15.81%, #20954E 85.67%)',
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                      }}
                    >
                      +{toPercentString(listing.medianRoi, 0)}
                    </Box>
                  </Flex>
                  <Box sx={{ color: 'gray.400', fontSize: '10px' }}>
                    *Revenue divided by average Airbnb home price
                  </Box>
                  <Divider sx={{ display: { lg: 'none' }, marginY: 3 }} />
                </Flex>
              </Box>
              <Box>
                <Box
                  sx={{
                    borderLeft: { lg: '1px' },
                    borderRight: { lg: '1px' },
                    borderStyle: 'solid',
                    borderColor: 'gray.200',
                    paddingX: { lg: 4 },
                    display: 'grid',
                    gridTemplateColumns: {
                      base: '1fr 1fr',
                      lg: 'repeat(2, 1fr)',
                    },
                    gap: 1,
                  }}
                >
                  <Box>ADR</Box>
                  <MaskedData sx={{ textAlign: 'right' }}>
                    <Box sx={{ textAlign: 'right' }}>{toUSD(listing.medianAdr)}</Box>
                  </MaskedData>

                  <Box>Occupancy</Box>
                  <MaskedData sx={{ textAlign: 'right' }}>
                    <Box sx={{ textAlign: 'right' }}>
                      {toPercentString(listing.medianOccupancy)}
                    </Box>
                  </MaskedData>

                  <Box>Revenue</Box>
                  <MaskedData sx={{ textAlign: 'right' }}>
                    <Box sx={{ textAlign: 'right' }}>{toAbbrUSD(listing.medianYearlyRevenue)}</Box>
                  </MaskedData>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      base: '1fr 1fr',
                      lg: 'repeat(2, 1fr)',
                    },
                    gap: 1,
                  }}
                >
                  <Box>Market size</Box>
                  <MaskedData sx={{ textAlign: 'right' }}>
                    <Box
                      sx={{
                        textAlign: 'right',
                        textDecoration: 'underline',
                        textDecorationStyle: 'dotted',
                      }}
                    >
                      {capitalize(listing.marketSize)}
                    </Box>
                  </MaskedData>

                  <Box>No. of Airbnbs</Box>
                  <MaskedData sx={{ textAlign: 'right' }}>
                    <Box sx={{ textAlign: 'right' }}>{toLocaleUS(listing.activeRentals)}</Box>
                  </MaskedData>

                  <Box>Avg. Airbnb price</Box>
                  <MaskedData sx={{ textAlign: 'right' }}>
                    <Box sx={{ textAlign: 'right' }}>{toAbbrUSD(listing.medianHomeValuation)}</Box>
                  </MaskedData>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
