import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { compareDesc, format, parseISO } from 'date-fns';
import { Box, Divider, Flex, Tab, Table, Tabs, Tag, Text, getCSS } from '@awning/components';
import { createSingletonStore, shallow } from '@awning/archie';
import { PropertyOverview } from './PropertyOverview';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';
import { ReactComponent as ChevronRight } from '@awning/components/assets/icons/chevron-right.svg';
import { BaseLink } from '@/src/BaseLink';
import useModal from '@/src/useModal';
import { ReactComponent as StarIcon } from './star.svg';
import { TEstimatorListing } from '../types';
import { toPercentString, toUSD } from '@/src/utils';
import { useOnClickOutside } from '@/src/useOnClickOutside';
import { useEstimatorStore } from '../store';
import { useMapViewStore } from '@/src/MapView';

export const extractFormattedData = (listing: TEstimatorListing) => {
  return {
    col1: {
      id: listing.airbnbAwningId,
    },
    col2: {
      image: listing.pictureUrl,
      distance: listing.radiusMiles?.toFixed(1),
    },
    col3: {
      bedrooms: listing.bedrooms,
      bathrooms: listing.bathrooms,
      sleeps: listing.maxGuests,
      numberOfReviews: listing.numberOfReviews,
      ratingStars: listing.ratingStars?.toFixed(1),
    },
    col4: toPercentString(listing.occupancyRateLtm),
    col5: toUSD(listing.averageDailyRate, 0),
    col6: toUSD(listing.monthlyBookingRevenueLtm),
    col7: { id: listing.airbnbAwningId },
  };
};

export const useComparableModal = createSingletonStore<{
  isVisible: boolean;
  show: () => void;
  hide: () => void;
}>(set => ({
  isVisible: false,
  show: () => set({ isVisible: true }),
  hide: () => set({ isVisible: false }),
}));

export const ComparableModal: React.FC<
  React.PropsWithChildren<{
    isOpen?: boolean;
    disableClose?: boolean;
    onClose?: () => void;
  }>
> = ({ disableClose = false, isOpen = false, onClose }) => {
  const allListings = useEstimatorStore(state => state.listings);

  const { selectedMarker, markerCollection } = useMapViewStore(
    state => ({
      selectedMarker: state.selectedMarker,
      markerCollection: state.markerCollection,
    }),
    shallow
  );

  const listing: TEstimatorListing = React.useMemo(() => {
    const selectedListingId = markerCollection.getOverlayAt(selectedMarker)?.id;
    const selectedListing = allListings.find(l => l.airbnbAwningId === selectedListingId);
    if (selectedListing) {
      return selectedListing;
    }
    return {} as TEstimatorListing;
  }, [allListings, markerCollection, selectedMarker]);

  const { _isVisible, _hide } = useComparableModal(state => ({
    _isVisible: state.isVisible,
    _hide: state.hide,
  }));
  const visibility = React.useRef(_isVisible || isOpen);

  const { Modal, show, hide } = useModal('comparable-modal', {
    defaultShow: visibility.current,
    onHide: () => {
      onClose?.(); // this first
      _hide();
    },
  });

  const comparablesRef = React.useRef<undefined | HTMLDivElement>();
  useOnClickOutside([comparablesRef], hide, [], !!isOpen);

  React.useEffect(() => {
    if (visibility.current !== _isVisible && _isVisible) {
      show();
    }
    visibility.current = _isVisible;
  }, [_isVisible]); // eslint-disable-line

  const historicalPerformanceData = React.useMemo(() => {
    let monthlyFinancials = [...(listing?.monthlyFinancialData ?? [])];

    return monthlyFinancials
      .sort((a, b) => compareDesc(parseISO(a.reportingMonth), parseISO(b.reportingMonth)))
      .map(d => ({
        col1: format(parseISO(d.reportingMonth), 'MMM yyyy'),
        col2: toUSD(d.averageDailyRate),
        col3: toPercentString(d.occupancyRate, 1),
        col4: d.availableDays,
        col5: toUSD(d.bookingRevenue),
      }));
  }, [listing]);
  const historicalPerformanceColumns = [
    { Header: 'Month', accessor: 'col1' },
    { Header: 'ADR', accessor: 'col2' },
    { Header: 'Occupancy', accessor: 'col3' },
    { Header: 'Days Available', accessor: 'col4' },
    { Header: 'Revenue', accessor: 'col5' },
  ];

  const amenitiesData = React.useMemo(() => {
    if (!listing) return [];
    return [
      { col1: 'Pool', col2: listing.hasPool },
      { col1: 'Hot Tub', col2: listing.hasHotTub },
      { col1: 'Gym', col2: listing.hasGym },
      { col1: 'Kitchen', col2: listing.hasKitchen },
      { col1: 'Parking', col2: listing.hasFreeParking },
      { col1: 'Pets', col2: listing.allowsPets },
      { col1: 'Dryer', col2: listing.hasDryer },
      { col1: 'Washer', col2: listing.hasWasher },
    ];
  }, [listing]);
  const amenitiesColumns = [
    { accessor: 'col1' },
    { accessor: 'col2', Cell: ({ value }: { value: boolean }) => (value ? 'Yes' : 'No') },
  ];

  if (isEmpty(listing)) return null;

  return (
    <Modal>
      <Flex
        ref={comparablesRef}
        sx={{
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: { base: 'none', lg: 'xl' },
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
          'WebkitOverflowScrolling': 'touch',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          height: { base: '100%', lg: '550px' },
          width: { base: '100%', lg: '600px' },
          margin: 'auto',
        }}
      >
        {!disableClose && (
          <>
            <Box sx={{ display: { base: 'block', sm: 'none' } }}>
              <Box sx={{ position: 'relative' }}>
                <Box
                  as={CloseIcon}
                  height="12px"
                  width="12px"
                  sx={{
                    cursor: 'pointer',
                    marginLeft: 4,
                    marginY: 4,
                  }}
                  onClick={hide}
                />
              </Box>
            </Box>
            <Box sx={{ display: { base: 'none', sm: 'block' } }}>
              <Box sx={{ position: 'absolute', right: 4, top: 4, zIndex: 1 }}>
                <Box
                  as={CloseIcon}
                  height="12px"
                  width="12px"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={hide}
                />
              </Box>
            </Box>
          </>
        )}
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 4,
            borderBottomWidth: { base: 0, lg: '1px' },
            borderColor: 'gray.200',
            overflow: 'hidden',
            width: '100%',
            height: { base: 'unset', lg: imageHeight },
          }}
        >
          <Flex
            sx={{
              minHeight: { base: 'unset', lg: imageHeight },
              flexDirection: { base: 'column', lg: 'row' },
            }}
          >
            <Card listing={listing} />
          </Flex>
        </Flex>
        <Box sx={{ height: '100%', flex: 1, overflow: 'hidden' }}>
          <Tabs
            tabMenuItemSx={{
              borderRadius: 'full',
              border: 0,
              backgroundColor: 'white',
              color: 'black',
              paddingY: 1,
              paddingX: 4,
              text: 'xs',
            }}
            tabMenuItemSelectedSx={{
              backgroundColor: 'black',
              color: 'white',
            }}
            tabMenuSx={{
              gap: 2,
              padding: 2,
            }}
          >
            {listing?.monthlyFinancialData.length > 0 && (
              <Tab label="Historical Performance">
                <Box sx={{ paddingY: 0, paddingX: 5, height: '100%', overflow: 'auto' }}>
                  <Table
                    getHeaderProps={() => ({
                      style: getCSS({
                        sx: { paddingY: 2, border: 0, text: 'xs' },
                      }),
                    })}
                    getRowProps={(row: any) => {
                      const isLastRow = historicalPerformanceData.length === (row as any).index + 1;
                      return {
                        style: getCSS({
                          sx: {
                            borderBottom: !isLastRow ? '1px solid' : 0,
                            borderBottomColor: 'gray.200',
                            text: 'xs',
                          },
                        }),
                      };
                    }}
                    getCellProps={() => {
                      return {
                        style: getCSS({
                          sx: {
                            paddingY: 2,
                            text: 'xs',
                            color: 'gray.500',
                          },
                        }),
                      };
                    }}
                    columns={historicalPerformanceColumns}
                    data={historicalPerformanceData}
                  />
                </Box>
              </Tab>
            )}
            <Tab label="Amenities">
              <Box sx={{ paddingY: 3, paddingX: 5, height: '100%', overflow: 'auto' }}>
                <Table
                  getRowProps={(row: any) => {
                    const isLastRow = amenitiesData.length === (row as any).index + 1;
                    return isLastRow
                      ? {}
                      : {
                          style: getCSS({
                            sx: {
                              borderBottom: '1px solid',
                              borderBottomColor: 'gray.200',
                            },
                          }),
                        };
                  }}
                  getCellProps={() => ({
                    style: getCSS({
                      sx: {
                        paddingY: 2,
                        text: 'xs',
                        color: 'gray.500',
                      },
                    }),
                  })}
                  disableHeaderGroups={true}
                  // @ts-expect-error
                  columns={amenitiesColumns}
                  data={amenitiesData}
                />
              </Box>
            </Tab>
          </Tabs>
        </Box>

        <Flex
          sx={{
            paddingX: 5,
            paddingY: { base: 5, lg: 5 },
            boxShadow: '0 0 5px 2px rgba(1, 1, 1, 0.05)',
            justifyContent: {
              base: 'center',
              lg: 'flex-start',
            },
            alignItems: 'center',
            text: 'sm',
            width: '100%',
          }}
        >
          <BaseLink newTab href={listing.listingUrl}>
            View on Airbnb
            <Box
              as={ChevronRight}
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </BaseLink>
        </Flex>
      </Flex>
    </Modal>
  );
};

const imageHeight = '190px';

const Card = React.memo(({ listing }: { listing: TEstimatorListing }) => {
  const { pictureUrl, radiusMiles } = listing;

  return (
    <Box
      sx={{
        color: 'gray.600',
        width: '100%',
      }}
    >
      <Box sx={{ fontWeight: 'normal', textDecoration: 'none' }}>
        <Box
          sx={{
            display: { base: 'block', lg: 'grid' },
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
            backgroundColor: 'white',
            position: 'relative',
            boxShadow: 'none',
            cursor: 'pointer',
            overflow: 'hidden',
            transition: 'box-shadow 300ms',
            width: '100%',
            '&:hover': {
              boxShadow: 'lg',
            },
          }}
        >
          <Box
            sx={{
              height: imageHeight,
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 2,
                top: 2,
                zIndex: 3,
              }}
            >
              <Tag sx={{ fontSize: '10px !important' }}>{radiusMiles?.toFixed(2)} mi</Tag>
            </Box>

            <Box
              as="img"
              sx={{
                width: '100%',
                height: imageHeight,
                objectFit: 'cover',
              }}
              src={pictureUrl}
              height={imageHeight}
            />
          </Box>

          <Box
            sx={{
              borderTop: { base: '1px solid', lg: 0 },
              borderColor: 'gray.200',
              paddingX: 4,
              paddingY: 2,
            }}
          >
            <PropertyOverview listing={listing} />

            <Divider sx={{ backgroundColor: 'gray.200', marginY: 2 }} />

            <Flex sx={{ gap: 1, flexDirection: 'column' }}>
              <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', text: 'xs' }}>
                <Flex sx={{ alignItems: 'center' }}>ADR</Flex>
                <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Text>{toUSD(listing.averageDailyRate)}</Text>
                </Flex>
              </Flex>

              <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', text: 'xs' }}>
                <Flex sx={{ alignItems: 'center' }}>Occupancy</Flex>
                <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Text>{toPercentString(listing.occupancyRateLtm, 0)}</Text>
                </Flex>
              </Flex>

              <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', text: 'xs' }}>
                <Flex sx={{ alignItems: 'center' }}>Reviews</Flex>
                <Flex sx={{ alignItems: 'center', gap: '2px', justifyContent: 'flex-end' }}>
                  <Box as={StarIcon} sx={{ marginTop: '-2px' }} width="10px" height="10px" />
                  <Text>{listing.ratingStars}</Text>
                  <Text>({listing.numberOfReviews})</Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
