import { useEffect, useRef, useState } from 'react';
import { BarDatum, BarItemProps, ResponsiveBar } from '@nivo/bar';
import type { AxisProps, AxisTickProps } from '@nivo/axes';
import { BasicTooltip, useTooltip } from '@nivo/tooltip';
import { TBarData } from './types';

export const NivoBarChart = ({
  layout = 'vertical',
  tickValues,
  data,
  margin = { top: 20, right: 20, bottom: 80, left: 40 },
  padding = 0.3,
}: {
  tickValues?: AxisProps['tickValues'];
  layout: 'horizontal' | 'vertical';
  data: TBarData;
  margin?: { top?: number; right?: number; bottom?: number; left?: number };
  padding?: number;
}) => {
  const gridXValues = layout === 'horizontal' ? tickValues : undefined;
  const gridYValues = layout === 'vertical' ? tickValues : undefined;

  // make sure parent container have a defined height when using
  // responsive component, otherwise height will be 0 and no chart will be rendered.
  return (
    <ResponsiveBar
      layout={layout}
      data={data}
      barComponent={(p: any) => <CustomBarComponent {...p} layout={layout} />}
      margin={margin}
      padding={padding}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors="#A1E594"
      borderRadius={4}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickValues: gridXValues ? gridXValues : undefined,
        renderTick: p => CustomTick(p, 'bottom', value => (value === '0' ? 'Studio' : value)),
      }}
      axisLeft={{
        tickValues: gridYValues ? gridYValues : undefined,
        renderTick: p => CustomTick(p, 'left', value => (value === '0' ? 'Studio' : value)),
      }}
      enableLabel={false}
      enableGridX={layout === 'horizontal'}
      gridXValues={gridXValues as any}
      enableGridY={layout === 'vertical'}
      gridYValues={gridYValues as any}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={'#ECEDEE'}
      legends={[]}
      role="application"
      ariaLabel="Listings by Bedroom Count"
      isInteractive={true}
      tooltip={function (d: any) {
        return d.value;
      }}
      barAriaLabel={function (d) {
        return `${d.value} listings with ${d.indexValue} bedrooms.`;
      }}
    />
  );
};

const CustomTick = (
  tick: AxisTickProps<string>,
  leftOrBottom: 'left' | 'bottom',
  formatValue = (v: any) => v
) => {
  const [[width, height], setDimensions] = useState([0, 0]);
  const contentRef = useRef<HTMLElement>();
  const { x, y } =
    leftOrBottom === 'left'
      ? { x: tick.x - width - 5, y: tick.y - height / 2 }
      : { x: tick.x - width / 2, y: tick.y + 10 };
  const transform = `translate(${x},${y})`;

  // Auto adjust the width and height for the axis labels
  useEffect(() => {
    if (contentRef.current) {
      const { width, height } = contentRef.current.getBoundingClientRect();
      setDimensions([width, height]);
    }
  }, []);

  return (
    <g transform={transform}>
      <switch>
        <foreignObject width={width} height={height}>
          <div
            /* @ts-ignore */
            ref={contentRef}
            style={{
              color: '#6A6D77',
              width: 'fit-content',
              fontSize: '10px',
            }}
          >
            {formatValue(tick.value)}
          </div>
        </foreignObject>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: '#6A6D77',
            fontSize: 10,
            width: '50px',
          }}
        >
          {formatValue(tick.value)}
        </text>
      </switch>
    </g>
  );
};

const CustomBarComponent = (
  props: BarItemProps<BarDatum> & { layout: 'horizontal' | 'vertical' }
) => {
  const {
    bar: { x, y, height, width, color, data },
    ariaLabel,
    borderRadius,
    layout,
  } = props;

  let aLabel = '';
  if (typeof ariaLabel === 'undefined') {
    aLabel = `${data.formattedValue}`;
  } else if (typeof ariaLabel === 'string') {
    aLabel = ariaLabel;
  } else if (typeof ariaLabel === 'function') {
    aLabel = ariaLabel(data)!;
  }
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const total = data.formattedValue;

  const _width = layout === 'vertical' ? 8 : width;
  const _height = layout === 'vertical' ? height : 8;
  const xy = layout === 'vertical' ? { x: x + width / 2 - 4, y: y } : { x, y: y + height / 2 - 4 };

  return (
    <rect
      x={xy.x}
      y={xy.y}
      rx={borderRadius}
      ry={borderRadius}
      width={_width}
      height={_height}
      fill={color}
      aria-label={aLabel}
      onMouseEnter={event => {
        return showTooltipFromEvent(<BasicTooltip id="total" value={total} />, event);
      }}
      onMouseLeave={() => hideTooltip()}
      onMouseMove={event => showTooltipFromEvent(<BasicTooltip id="total" value={total} />, event)}
    />
  );
};
