import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import usePortal from 'react-cool-portal';
import { Box, Flex } from '@awning/components';

const useNoResultsModal = () => {
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
  });

  const Modal = React.useCallback(
    ({ isShow, children }: any) => {
      return (
        <Portal>
          <AnimatePresence>
            <motion.div
              key="searchbar-modal"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                visibility: isShow ? 'visible' : 'hidden',
                opacity: isShow ? 1 : 0,
                overflow: 'auto',
                zIndex: 9999,
                height: '100%',
                width: '100%',
                background: 'rgb(14 15 17 / 20%)',
                backgroundClip: 'padding-box',
                transition: 'opacity 0.5s ease-in-out',
              }}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e?.stopPropagation();
                hide();
              }}
            >
              <Flex
                sx={{
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: 'xl',
                  }}
                  onClick={(e: any) => {
                    e?.stopPropagation();
                  }}
                >
                  {children}
                </Box>
              </Flex>
            </motion.div>
          </AnimatePresence>
        </Portal>
      );
    },
    [Portal, hide]
  );

  return { Modal, isShow, show, hide };
};

export default useNoResultsModal;
