const shimmerSVG = (w = 400, h = 400) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#fff" offset="20%" />
      <stop stop-color="#eee" offset="50%" />
      <stop stop-color="#fff" offset="80%" />>
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#eee" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

export const getBase64ShimmerDataURL = (width = 400, height = 400) =>
  `data:image/svg+xml;base64,${toBase64(shimmerSVG(width, height))}`;

export const ReactShimmerSVGComponent = ({
  width = 400,
  height = 400,
}: {
  width: number;
  height: number;
}) => {
  const w = width;
  const h = height;
  return (
    <svg
      width={w}
      height={h}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient id="g">
          <stop stopColor="#fff" offset="20%" />
          <stop stopColor="#eaeaea" offset="50%" />
          <stop stopColor="#fff" offset="100%" />
        </linearGradient>
      </defs>
      <rect width={w} height={h} fill="#fff" />
      <rect id="r" width={w} height={h} fill="url(#g)" />
      <animate
        xlinkHref="#r"
        attributeName="x"
        from={-w}
        to={w}
        dur="1s"
        repeatCount="indefinite"
      />
    </svg>
  );
};
