import * as React from 'react';
import { Global } from '@emotion/react';
import { createSingletonStore } from '@awning/archie';
import { Box } from '@awning/components';
import usePortal from 'react-cool-portal';

export const useModalStore = createSingletonStore<{
  isShow: boolean;
  show: () => void;
  hide: () => void;
}>(set => ({
  isShow: false,
  show: () => set({ isShow: true }),
  hide: () => set({ isShow: false }),
}));

export const useModal = (options = {}) => {
  const store = useModalStore();
  const { Portal } = usePortal({
    ...options,
    defaultShow: false,
    internalShowHide: false,
  });

  React.useEffect(() => {
    const handleEscKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') store.hide();
    };
    document.addEventListener('keydown', handleEscKey);

    return () => document.removeEventListener('keydown', handleEscKey);
  });

  React.useEffect(() => {
    const elem = document.getElementById('__next');

    // do not inline this
    function onBodyClick(e: any) {
      e.stopPropagation();
      e.preventDefault();
      store.hide();
    }

    if (store.isShow) {
      if (elem) {
        elem.style.opacity = '0.5';
        elem.addEventListener('click', onBodyClick, true);
      }
    } else {
      if (elem) {
        elem.style.opacity = '1';
        elem.removeEventListener('click', onBodyClick, true);
      }
    }

    return () => elem?.removeEventListener('click', onBodyClick, true);
  }, [store]);

  const Modal = React.useCallback(
    ({ isShow, children }: any) => {
      return (
        <Portal>
          <Global
            styles={`
              /* this is necessary so we get a nice transition to the opacity of the body when the modal mounts */
              #__next {
                opacity: 1.0;
                transition: opacity 0.25s ease-in-out;
              }
          `}
          />
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              right: 0,
              visibility: isShow ? 'visible' : 'hidden',
              opacity: isShow ? 1.0 : 0,
              overflow: 'auto',
              zIndex: '99999',
              height: '100%',
              width: { base: '100%', lg: '500px' },
              background: 'white',
              backgroundClip: 'padding-box',
              transform: isShow ? 'translateX(0)' : 'translateX(+100%)',
              transition: 'transform 0.5s ease-in-out',
              boxShadow: 'lg',
            }}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
            }}
          >
            {children}
          </Box>
        </Portal>
      );
    },
    [Portal]
  );

  return { Modal, ...store };
};
