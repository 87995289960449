import Router from 'next/router';
import objectSet from 'lodash/set';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import produce from 'immer';
import {
  ArchieStateInitializer,
  ArchieStoreCreator,
  createArchieStoreCreator,
  shallow,
  useStore,
} from '@awning/archie';
import {
  EFilterPages,
  TAvailableMarkets,
  TEstimatorFilterState,
  TFilterState,
  TFilterStore,
} from './types';
import { FILTER_DEFAULTS } from './FilterConstants';
import { hasAppliedFilters, hasFilterChanged, skipDefaults } from './utils';

type ImmerSet<T extends TFilterStore> = (
  config: ArchieStateInitializer<T>
) => ArchieStateInitializer<T>;
const _set: ImmerSet<TFilterStore> = config => (set, get, api) =>
  config(
    //@ts-ignore
    (partial, replace) => {
      const nextState = typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

export const filterStoreCreator = createArchieStoreCreator<TFilterStore>({
  name: 'filterStore',

  // addons: {
  //   computed: {
  //     hasFiltersApplied: function () {
  //       return hasAppliedFilters(this.filterState, defaultFilters, ['pagination']);
  //     },
  //   },
  // },

  stateInitializer: _set((set, get) => ({
    activePage: undefined,
    // investmentType: EInvestmentType.LTR,
    availableMarkets: {} as TAvailableMarkets[],
    filterShown: null,
    filterState: {} as TFilterState,
    // filterState: FILTER_DEFAULTS.ESTIMATOR_PAGE.FILTERS,
    snapshot: null,
    defaults: () => FILTER_DEFAULTS[get().activePage! as keyof typeof FILTER_DEFAULTS] ?? {},

    getFilterQueryParams: () => {
      if (!isEqual(get().snapshot, get().filterState)) {
        const state = { ...get().filterState };
        const filterState = JSON.stringify(state);

        return {
          filterState,
        };
      }

      return {};
    },

    executeSearch: async () => {
      // IF THE STATE HAS NOT CHANGED SINCE LAST TIME THEN DONT DO ANYTHING!
      if (!isEqual(get().snapshot, get().filterState)) {
        // reset the pagination when a user changes a filter
        const isEstimatorPage = get().activePage === EFilterPages.ESTIMATOR;
        const isTopMarketsPage = get().activePage === EFilterPages.TOP_MARKETS;
        const isMarketInsightsPage = get().activePage === EFilterPages.MARKET_INSIGHTS;

        // @ts-expect-error
        const url = new URL(window.location);
        const searchParams = url.searchParams;

        let changeSet = skipDefaults(get().changeSet as TFilterState, get().defaults().FILTERS);

        // TODO: using skip defaults probably easier to maintain in the long run since applying skipDefaults and then removeEmptyKeys does what we want
        // and we can reuse that on toServerQueryState as well
        // better idea would be to just serialized the changed filterState i.e. compare the defaults instead
        // if anything is different then write it. At this point we are doing all the work anyway
        // console.log('new', skipDefaults(get().filterState, [], get().defaults().FILTERS));

        set({ filterShown: null, snapshot: null });

        const filterState = JSON.stringify(changeSet);
        searchParams.delete('qTitle');
        searchParams.delete('pagination');
        searchParams.delete('page');
        searchParams.delete('filterState');

        let urlPath = '';
        if (isMarketInsightsPage) {
          searchParams.delete('filterState');

          // for the time being skip grabbing the /rates or /best-airbnb.
          // Given we are going to be on the airbnb tab remove the rest from the url
          const query = Router.query;
          Router.push(
            {
              query: {
                ...Array.from(searchParams.entries()).reduce(
                  (acc, [k, v]) => ({ ...acc, [k]: v }),
                  {}
                ),
                filterState,
              },
            },
            `/airbnb-market-data/${
              (query?.place as string[])?.[0]
            }?filterState=${encodeURIComponent(filterState)}`
          );
        } else if (isTopMarketsPage) {
          if (!isEmpty(changeSet)) searchParams.set('filterState', filterState);

          urlPath = `/top-airbnb-markets?${searchParams.toString()}`;
          Router.push(urlPath);
        }
        // fallback for estimator and every other page/SEO link
        else {
          if (!isEmpty(changeSet)) searchParams.set('filterState', filterState);

          if (isEstimatorPage) {
            Router.push(`/estimator?${searchParams.toString()}`);
          }
        }
      }
    },

    changeSet: {} as TFilterStore['changeSet'],

    applyChangeSet: changeSet => {
      if (!changeSet) return;

      const defaultFilterState = get().defaults().FILTERS;
      // @ts-expect-error
      set(state => {
        let filterName: keyof TFilterState;
        for (filterName in changeSet) {
          state.filterState[filterName] = {
            ...defaultFilterState[filterName],
            ...changeSet?.[filterName],
          };
        }
        state.changeSet = changeSet;
      });
    },

    hasFilterApplied: () => hasAppliedFilters(get().filterState, get().defaults().FILTERS),

    hasFilterChanged: filterName =>
      hasFilterChanged(
        get().filterState,
        get().defaults().FILTERS,
        get().defaults().TRANSFORMS,
        filterName
      ),

    showFilter: filterName => {
      // @ts-expect-error
      set(state => {
        state.snapshot = state.filterState;
        state.filterShown = filterName;
      });
    },

    hideFilter: () => {
      // @ts-expect-error
      set(state => {
        state.filterShown = null;
      });
    },

    clearFilter: filterName => {
      if (!filterName) return;

      if (filterName === 'moreFilters') {
        // @ts-expect-error
        set(state => {
          state.filterState = get().defaults().FILTERS;
          state.changeSet = {};
        });
      } else {
        // @ts-expect-error
        set(state => {
          //@ts-expect-error
          state.filterState[filterName] = get().defaults().FILTERS[filterName];
          //@ts-expect-error
          delete state.changeSet[filterName];
        });
      }
    },

    resetFilterState: filterState =>
      // @ts-expect-error
      set(state => {
        (state.filterState as unknown) = {
          ...get().defaults().FILTERS,
          ...(filterState ?? {}),
        };
      }),

    /** IF no path is given then we overwrite the filterName with the value directly */
    updateFilter: (filterName, path, value = null) => {
      // @ts-expect-error
      set(state => {
        const obj = { ...state.filterState[filterName as keyof typeof state.filterState] };
        if (path) {
          //@ts-expect-error
          state.filterState[filterName] = objectSet(obj, path, value);
          //@ts-expect-error
          state.changeSet[filterName] = { ...state.changeSet[filterName], [path]: value };
        } else {
          state.filterState[filterName as keyof typeof state.filterState] = value;
          //@ts-expect-error
          state.changeSet[filterName] = value;
        }
      });
    },

    updateSortAndExecuteSearch: (sortOrder: TFilterState['sortOrder']) => {
      get().updateFilter('sortOrder', '', sortOrder);
      get().executeSearch();
    },
  })),
});

export declare type StateSelector<T extends object, U> = (state: T) => U;
declare type _TEstimatorStore = TFilterStore & { filterState: TEstimatorFilterState };
export const useEstimatorFilterStore = <U>(selector: StateSelector<_TEstimatorStore, U>) => {
  return useStore(
    filterStoreCreator as ArchieStoreCreator<_TEstimatorStore>,
    selector,
    shallow
  ) as U;
};

type _Base<P> = Omit<TFilterStore, 'filterState'> & { filterState: P };
export const useFilterStore = <T, U extends _Base<T> = any>(
  selector: StateSelector<_Base<T>, U>
) => {
  return useStore(
    filterStoreCreator as unknown as ArchieStoreCreator<_Base<T>>,
    selector,
    shallow
  ) as any;
};
